// ================================================================================
//
// ▼ _template.scss
//
//================================================================================

// ================================================================================
//
// ▼ トップページ
//
//================================================================================

.m_hero_visual {
background: #f1f1f1;
overflow: hidden;
.m_hero_visual_inner {
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
}
.m_hero_visual_copy {

}


.m_hero_visual_object_01 {
  width: 200px;
  height: 200px;
  border-width: 10px;
  border-style: solid ;
  border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAvUlEQVRIie2T3QqFIBCEx82SIqr3f8VuIrQfrDyM4HmBvVVYVvxcltllzLqu6TxPDMOAeZ6RUsL3fbDW5vu2bdBwSziOY/7AB562bRFjhPceWi4EjNLRGIPneRBCwHEc0HITY0wEjKZp8L4v9n3Hfd9wzmFZFmi4FFkikiFlXdeFruv+M9Vw4ULYmZmdKavve0zTlIu0XDi7spAii0vjojhLNadEwtI5yxLJRcxaXn1QfVB9UH1QfVB9ICL4Ab0IpD4OPD29AAAAAElFTkSuQmCC) 8px round;
  // border-image-source: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAvUlEQVRIie2T3QqFIBCEx82SIqr3f8VuIrQfrDyM4HmBvVVYVvxcltllzLqu6TxPDMOAeZ6RUsL3fbDW5vu2bdBwSziOY/7AB562bRFjhPceWi4EjNLRGIPneRBCwHEc0HITY0wEjKZp8L4v9n3Hfd9wzmFZFmi4FFkikiFlXdeFruv+M9Vw4ULYmZmdKavve0zTlIu0XDi7spAii0vjojhLNadEwtI5yxLJRcxaXn1QfVB9UH1QfVB9ICL4Ab0IpD4OPD29AAAAAElFTkSuQmCC);
  // border-image-width: 8px;
  // border-image-repeat: round;
  background: pink;
}
}


// ------------------------------------------------------------
// - ▼ 社員紹介
// ------------------------------------------------------------
.m_index_employee {
  margin-top: 170px;
  background: url(data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAABQAAD/7gAOQWRvYmUAZMAAAAAB/9sAhAACAgICAgICAgICAwICAgMEAwICAwQFBAQEBAQFBgUFBQUFBQYGBwcIBwcGCQkKCgkJDAwMDAwMDAwMDAwMDAwMAQMDAwUEBQkGBgkNCwkLDQ8ODg4ODw8MDAwMDA8PDAwMDAwMDwwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAIAAgDAREAAhEBAxEB/8QAVwABAAAAAAAAAAAAAAAAAAAACQEBAAAAAAAAAAAAAAAAAAAAABAAAAILAQAAAAAAAAAAAAAAMRMAAREhQQIyIwQ0BSURAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AHOp8zmWy9vLEtovjOtA/9k=);
  @media (max-width: 640px) {
    margin-top: 80px;
    background-size: 4px 4px;
  }
}


// ------------------------------------------------------------
// - ▼ トピックスのバナー
// ------------------------------------------------------------
.m_topics_banner {
  display: flex;
  justify-content: space-between;
  @media (max-width: 640px) {
    display: block;
  }
  .m_topics_banner_item {
    width: calc(50% - 15px);
    @media (max-width: 768px) {
      width: calc(50% - 10px);
    }
    @media (max-width: 640px) {
      width: auto;
      &:nth-child(2) {
        margin-top: 5px;
      }
    }
  }
}



.m_topics_banner_link {
  position: relative;
  display: block;
  transition: opacity 0.3s ease;
  .m_topics_banner_link_title {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 100%;
    text-align: center;
    z-index: 1;
    color: #fff;
    font-size: 20px;
    letter-spacing: 0.1em;
    padding-left: 0.1em;
  }

  .m_topics_banner_link_thumbnail {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &::before {
      content: '';
      display: block;
      padding-top: my_floor(130/380 *100) * 1%;
    }
  }

  &:hover {
    @media (min-width: 769px) {
      opacity: 0.4;
    }
  }
}




// ------------------------------------------------------------
// - ▼ トピックスのリスト
// ------------------------------------------------------------
.m_topics_list {
  .m_topics_list_item {
    + .m_topics_list_item {
      margin-top: 10px;
      padding-top: 10px;
      border-top: dashed 1px #a3a3a3;
    }
  }
}

// ------------------------------------------------------------
// - ▼ トピックスのリンク
// ------------------------------------------------------------
.m_topics_list_link {
  display: flex;
  align-items: flex-start;
  transition: opacity 0.3s ease;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
  > ._date {
    display: inline-block;
    font-size: 14px;
    margin-right: 10px;
    margin-top: 1.5px;
  }

  > ._label {
    display: inline-block;
    background: #bbb;
    color: #fff;
    font-size: 13px;
    border-radius: 3px;
    margin-right: 10px;
    font-size: 11px;
    line-height: 1;
    width: 100px;
    margin-top: 2.5px;
    padding: 3px 10px;
    text-align: center;
    font-family: $font_family_open_sans;
  }

  > ._title {
    display: inline-block;
    @include flex();
    font-size: 15px;
    @media (max-width: 640px) {
      @include flex(0,0,auto);
      width: 100%;
      margin-top: 5px;
      font-size: 13px;
    }
  }
  &:hover {
    &:not(.is_title_only) {
      @media (min-width: 769px) {
        opacity: 0.6;
      }
    }
  }
}






.m_employee_slider {
  .m_employee_slider_item {
    .m_employee_link {
      position: relative;

      transform: scale(0.8);
      transition: all 0.3s ease;
    }
  }
  .slick-list {
    // overflow-x: hidden;
    // overflow-y: -webkit-pagedY;
    padding-bottom: 90px !important;
  }
  .slick-slide {
    // margin: 0 -20px;
    &.slick-center {
      .m_employee_link {
        z-index: 1;
        // width: 300px;
        transform: scale(1);
        box-shadow: 0 50px  80px -30px rgba(#000, 0.4);

      }
      .m_employee_link_detail {
        opacity: 1;
      }
    }
  }
}


.m_employee_link {
  display: block;
  // padding-bottom: 20px;

  .m_employee_link_thumbnail {
    width: 370px;
    background-size: cover;
    @media (max-width: 1024px) {
      width: vw(370px, 1024);
    }
    @media (max-width: 768px) {
      width: vw(370px, 768);
    }
    @media (max-width: 640px) {
      width: vw(370px, 640);
    }
    &::after {
      content: '';
      display: block;
      padding-top: 129.73%;
    }

  }


  .m_employee_link_detail {
    background: rgba(#000, 0.8);
    position: absolute;
    bottom: -20px;
    right: -20px;
    width: 175px;
    padding: 20px;
    opacity: 0;
    transition: opacity 0.3s;
    @media (max-width: 640px) {
      bottom: -10px;
      right: -10px;
      padding: 15px;

    }
  }

  .m_employee_link_label {
    font-size: 15px;
    font-weight: bold;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &.is_recruit {
      color: #ff9600;
      background-image: -webkit-linear-gradient(0deg, #ff9600, #ff6600);
    }
    &.is_career {
      color: #00aeff;
      background-image: -webkit-linear-gradient(0deg, #00aeff, #003cff);
    }
  }

  .m_employee_link_position {
    margin-top: 5px;
    color: #fff;
    font-size: 22px;
    @media (max-width: 640px) {
      font-size: 18px;
    }
  }

  .m_employee_link_name {
    font-size: 14px;
    color: #d9d9d9;
    line-height: 1;
    margin-top: 10px;
  }

  .m_employee_link_arrow {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 30px;
  }
}






// ------------------------------------------------------------
// - ▼ トップページ できることコンテンツ
// ------------------------------------------------------------
.m_challange_wrapper {
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: content-box !important;
  @media (max-width: 640px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.m_challange {
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: block;
  }
  .m_challange_item {
    width: calc(50% - 20px);
    @media (max-width: 880px) {
      width: calc(50% - 15px);
    }
    @media (max-width: 768px) {
      max-width: 640px;
      width: auto;
    }
    + .m_challange_item {
      @media (max-width: 768px) {
        margin-left: auto;
        margin-top: 10px;
      }
    }
  }
}


.m_challange_link {
  display: block;
  position: relative;
  overflow: hidden;
  .m_challange_link_textbox {
    position: absolute;
    left: 0;
    bottom: 40px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    z-index: 1;
    @media (max-width: 1024px) {
      bottom: vw(40, 1024);
    }
    @media (max-width: 768px) {
      bottom: 40px;
    }
    @media (max-width: 480px) {
      bottom: vw(40, 480);
    }
  }

  .m_challange_link_number {
    background: #fff;
    color: #383838;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 20px;
    font-weight: bold;
    @media (max-width: 1024px) {
      font-size: vw(20px, 1024);
      padding-left: vw(40px, 1024);
      padding-right: vw(40px, 1024);
    }
    @media (max-width: 768px) {
      padding-left: 40px;
      padding-right: 40px;
      font-size: 20px;
    }
    @media (max-width: 480px) {
      font-size: vw(20px, 480);
      padding-left: vw(40px, 480);
      padding-right: vw(40px, 480);
    }
  }

  .m_challange_link_title {
    background: #383838;
    color: #fff;
    margin-top: 10px;
    padding-left: 40px;
    padding-right: 50px;
    font-size: 28px;
    font-weight: bold;
    @media (max-width: 1024px) {
      font-size: vw(28px, 1024);
      margin-top: vw(10px, 1024);
      padding-left: vw(40px, 1024);
      padding-right: vw(50px, 1024);
    }
    @media (max-width: 768px) {
      margin-top: 10px;
      padding-left: 40px;
      padding-right: 50px;
      font-size: 28px;
    }
    @media (max-width: 480px) {
      font-size: vw(28px, 480);
      margin-top: vw(10px, 480);
      padding-left: vw(40px, 480);
      padding-right: vw(50px, 480);
    }
  }

  .m_challange_link_thumbnail {
    transition: transform 0.3s ease, opacity 0.3s ease;
    z-index: 0;
    max-height: 310px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    &::before {
      content: '';
      display: block;
      padding-top: 310/580*100%;
    }
    img {
      width: 100%;
    }
  }

  &:hover {
    @media (min-width: 769px) {
      .m_challange_link_number {
        color: $color_orange;
        background: lighten($color_orange, 40%);
      }
      .m_challange_link_title {
        color:  lighten($color_orange, 40%);
        background: $color_orange;
      }
      .m_challange_link_thumbnail {
        transform: scale(1.1);
        opacity: 0.6;
      }
    }
  }
}


// ------------------------------------------------------------
// - ▼ トップページ：エイティングレポート
// ------------------------------------------------------------
.m_8ing_report_header {
  padding-top: 60px;
  padding-bottom: 135px;
  background: url(/_materials/images/top/8ing_report_background.jpg);
  background-size: cover;
  @media (max-width: 640px) {
    padding-top: 35px;
    padding-bottom: 115px;
  }
}

.m_8ing_report_more {
  width: 90%;
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
}


// ------------------------------------------------------------
// - ▼ フロントページのみ出す
// ------------------------------------------------------------
.m_footer_front_only {
  display: none;
}

// ------------------------------------------------------------
// - ▼ トップページSNSタイムライン
// ------------------------------------------------------------
.m_timeline {
  background: #191919;
  padding-top: 70px;
  padding-bottom: 70px;
  .m_timeline_inner {

    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      display: block;
    }
  }

  .m_timeline_item {
    overflow: hidden;
    width: calc(50% - 20px;);
    @media (max-width: 768px) {
      width: auto;
      max-width: 460px;
      margin-right: auto;
      margin-left: auto;

      + .m_timeline_item {
        margin-top: 40px;

      }
    }
  }
}


// ------------------------------------------------------------
// - ▼ 8ingレポートの記事一覧
// ------------------------------------------------------------
.m_8ing_report {
  background: #f2f2f2;
  padding-bottom: 120px;
}
.m_8ing_report_contnts {
  max-width: 1040px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  margin-top: -80px;
  overflow: hidden;

  .m_8ing_report_contnts_inner {
    // margin-top: -30px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -25px;
    margin-right: -25px;
    padding-left: 20px;
    padding-right: 20px;
    // align-items: flex-start;
    @media (max-width: 768px) {
      display: block;
      margin-left: 0;
      margin-right: 0;
    }
    @media (max-width: 640px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .m_8ing_report_contnts_column {
    width: calc(50% - 50px);
    margin-top: 30px;
    margin-left: 25px;
    margin-right: 25px;
    display: inline-block;
    background: #fff;
    padding-top: 35px;
    padding-bottom: 40px;
    @media (max-width: 768px) {
      width: auto;
      display: block;
      margin-left: 0;
      margin-right: 0;
    }
    @media (max-width: 480px) {
      margin-top: 5px;
    }
  }
}


// ▽ 黒帯の少し左にずれているタイトル
// ----------------------------------------
.m_8ing_report_contnts_title {
  background: #333;
  color: #fff;
  margin-right: 20px;
  font-size: 24px;
  padding-left: 20px;
  padding-top: 10px;
  margin-left: -20px;
  padding-right: 20px;
  padding-bottom: 10px;
  font-weight: normal;
  @media (max-width: 640px) {
    font-size: 20px;
    padding-left: 15px;
    margin-left: -15px;
    padding-right: 15px;
  }
  @media (max-width: 480px) {
    font-size: 18px;
  }
}


// ------------------------------------------------------------
// - ▼ 記事のリスト
// ------------------------------------------------------------
.m_8ing_report_list {
  margin-top: 30px;
  .m_8ing_report_list_item {
    padding-right: 30px;
    padding-left: 30px;
    @media (max-width: 480px) {
      padding-right: 15px;
      padding-left: 15px;
    }
    + .m_8ing_report_list_item {
      margin-top: 30px;
      padding-top: 30px;
      border-top: solid 1px #f2f2f2;
      @media (max-width: 640px) {
        margin-top: 20px;
        padding-top: 20px;
      }
    }
  }
}


// ------------------------------------------------------------
// - ▼ コンテンツのリンク
// ------------------------------------------------------------
.m_8ing_report_link {
  display: flex;
  align-items: flex-start;

  // transition:
  //   background 0.3s ease,
  //   box-shadow 0.3s ease;
  .m_8ing_report_link_thumbnail {
    width: 120px;
    margin-right: 25px;
    background-size: cover;
    background-position: center;
    @media (max-width: 480px) {
      margin-right: 15px;
      width: vw(120px, 480);
    }
    &::before {
      content: '';
      display: block;
      padding-top: 83.33%;
    }
  }
  .m_8ing_report_link_detail {
    @include flex();
  }

  .m_8ing_report_link_title {
    font-size: 16px;
    letter-spacing: 0.1em;
    margin-right: -0.1em;
    @media (max-width: 480px) {
      font-size: 14px;
    }
  }

  .m_8ing_report_link_text {
    margin-top: 5px;
    font-size: 14px;
    letter-spacing: 0.1em;
    margin-right: -0.1em;
    // max-height: 14 * 1.4 * 2px;
    overflow: hidden;
    position: relative;
    @media (max-width: 480px) {
      font-size: 13px;
      // max-height: 13 * 1.4 * 2px;
    }
    // &::before {
    //   content: '…';
    //   position: absolute;
    //   bottom: 0;
    //   right: 0.1em;
    //   z-index: 0;
    //   padding-left: 20px;
    //   	background: linear-gradient(to right, transparent, #fff 15px, #fff);
    // }
    // &::after {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   background: #fff;
    //   width: 100%;
    //   height: 100%;
    //   z-index: 1;
    // }
  }

  .m_8ing_report_link_footer {
    margin-top: 25px;
    @media (max-width: 640px) {
      margin-top: 10px;
    }
  }

  .m_8ing_report_link_post_date {
    letter-spacing: 0.1em;
    font-size: 12px;
    color: #999;
  }


  &:hover {
    $hover_color: lighten($color_orange, 20%);
    @media (min-width: 769px) {
      background: $hover_color;
      box-shadow: 0 0 0 10px $hover_color;
      // .m_8ing_report_link_text {
      //   &::before {
      //     background: linear-gradient(to right, transparent, $hover_color 20px, $hover_color);
      //   }
      // }
    }
  }
}

// ------------------------------------------------------------
// - ▼ もっと見るボタン
// ------------------------------------------------------------
.m_8ing_report_contnts_more {
  margin-top: 40px;
  max-width: 270px;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  line-height: 1;

  .m_btn_border_arrow {
    height: 55px;

padding-left: 32px;

    > ._icon {
      width: 32px;
    }
  }
}


// ------------------------------------------------------------
// - ▼ 下層ページのタイトル
// ------------------------------------------------------------
.m_maivisual_underlayer {
  display: flex;align-items: center;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &::before {
    content: '';
    display: block;
    width: 0%;
    height: 240px;
    @media (max-width: 640px) {
      height: 160px;
    }
  }
  .m_maivisual_underlayer_inner {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
  .m_maivisual_underlayer_title {
    font-size: 52px;
    font-family: $font_family_en;
    word-break: break-all;
    line-height: 1.2;
    @media (max-width: 640px) {
      font-size: 46px;
    }
    @media (max-width: 480px) {
      font-size: 40px;
    }
    @media (max-width: 375px) {
      font-size: 32px;
    }
  }
  .m_maivisual_underlayer_sub {
    margin-top: 5px;
    font-size: 20px;
    @media (max-width: 640px) {
      font-size: 18px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }

  &.is_career {
    background-image: url(/_materials/images/common/visual/career.jpg);
  }
  &.is_company {
    background-image: url(/_materials/images/common/visual/company.jpg);
  }
  &.is_employee {
    background-image: url(/_materials/images/common/visual/employee.jpg);
  }
  &.is_faq {
    background-image: url(/_materials/images/common/visual/faq.jpg);
  }
  &.is_project {
    background-image: url(/_materials/images/common/visual/project.jpg);
  }
  &.is_recruit {
    background-image: url(/_materials/images/common/visual/recruit.jpg);
  }
  &.is_report {
    background-image: url(/_materials/images/common/visual/report.jpg);
  }
}




// ------------------------------------------------------------
// - ▼ 8ingレポート記事一覧ページ
// ------------------------------------------------------------
.m_report_category {
  $margin_side: 20px;
  $margin_top: 20px;
  $margin_side_sp: 10px;
  $margin_top_sp: 10px;
  overflow: hidden;
  .m_report_category_inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: $margin_side / 2 * -1;
    margin-right: $margin_side / 2 * -1;
    margin-top: $margin_top * -1;
    @media (max-width: 640px) {
      margin-left: $margin_side_sp / 2 * -1;
      margin-right: $margin_side_sp / 2 * -1;
      margin-top: $margin_top_sp * -1;
    }
  }
  .m_report_category_btn {
    width: calc(50% - #{$margin_side});
    margin-left: $margin_side / 2;
    margin-right: $margin_side / 2;
    margin-top: $margin_top;
    display: flex;
    align-items: center;
    background: #eee;
    color: #999;
    @media (max-width: 640px) {
      width: calc(50% - #{$margin_side_sp});
      margin-left: $margin_side_sp / 2;
      margin-right: $margin_side_sp / 2;
      margin-top: $margin_top_sp;
    }
    &::before {
      content: '';
      display: block;
      width: 0%;
      height: 55px;
      @media (max-width: 480px) {
        height: 45px;
      }
    }
    ._text {
      width: 100%;
      padding: 10px;
      font-size: 22px;
      text-align: center;
      @media (max-width: 640px) {
        font-size: 20px;
      }
      @media (max-width: 480px) {
        font-size: 18px;
      }
      @media (max-width: 375px) {
        font-size: vw(16px, 375);
      }
    }
    &:not(.is_current):hover {
      @media (min-width: 769px) {
        background: lighten($color_orange, 20%);
      }
    }

    &.is_current {
      background: #333;
      color: #fff;
    }
  }
}







// ------------------------------------------------------------
// - ▼ 8ingレポート記事一覧
// ------------------------------------------------------------
.m_report_archive {
  border-top: solid 4px #333;
  padding-top: 60px;
  .m_report_archive_item {
    + .m_report_archive_item {
      border-top: solid 2px #f5f6f7;
      margin-top: 40px;
      padding-top: 40px;
    }
  }
}



// ------------------------------------------------------------
// - ▼ 8ingレポート記事一覧ページのリンク
// ------------------------------------------------------------
.m_report_archive_link {
  display: flex;
  align-items: flex-start;
  .m_report_archive_link_thumbnail {
    width: 25%;
    margin-right: 20px;
    background-size: cover;
    background-position: center;
    &::before {
      content: '';
      display: block;
      padding-top: 160 / 260 * 100%;

    }
  }

  .m_report_archive_link_detail {
    @include flex();
  }

  .m_report_archive_link_title {
    font-weight: bold;
    @include text();
    font-size: 18px;
  }

  .m_report_archive_link_content {
    @include text();

  }

  .m_report_archive_link_date {
    @include text();
    margin-top: 20px;
    display: block;
  }
}


// ------------------------------------------------------------
// - ▼ 8ing REPORT 詳細ページ
// ------------------------------------------------------------
.m_report_article_header {
  display: flex;
}
.m_report_article_date {
  font-size: 18px;
  color: #999;
  @media (max-width: 640px) {
    font-size: 16px;
  }
}
.m_report_article_date_category {
  font-size: 18px;
  margin-left: 10px;
  @media (max-width: 640px) {
    font-size: 16px;
  }
}

.m_report_article_title {
  margin-top: 20px;
  font-size: 30px;
  border-bottom: solid 3px #666;
  padding-bottom: 20px;
  @media (max-width: 640px) {
    font-size: 26px;
  }
  @media (max-width: 480px) {
    font-size: 22px;
  }
}

.m_article_btn {
  width: 85%;
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
}


.m_report_around_link {
  margin-top: 150px;
  @media (max-width: 640px) {
    margin-top: 80px;
  }
  &::after {
    content: '';
    display: block;
    clear: both;
  }
  .m_report_around_link_prev {
    float: left;
  }

  .m_report_around_link_next {
    float: right;
  }
}

.m_prev_link,
.m_next_link {
  display: block;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-top: solid 1px #333 ;
    position: absolute;
    top: 50%;
  }
  &:hover {
    color: $color_orange;
    &::before {
      border-color: $color_orange !important;
    }
  }
}
.m_prev_link {
  padding-left: 16px;
  &::before {
  border-left: solid 1px #333 ;
  transform-origin: left top;
  transform: rotate(-45deg);
  left: 0;
}
}
.m_next_link {
  padding-right: 16px;
  &::before {
  border-right: solid 1px #333 ;
  transform-origin: right top;
  transform: rotate(45deg);
  right: 0;
}
}




// ================================================================================
//
// ▼ 社員紹介
//
//================================================================================

// ▽ アーカイブページ
// ----------------------------------------
.m_staff_list {
  $margin_side: 20px;
  $margin_top: 60px;
  $margin_side_sp: 20px;
  $margin_top_sp: 40px;
  .m_staff_list_inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: $margin_side / 2 * -1;
    margin-right: $margin_side / 2 * -1;
    margin-top: $margin_top * -1;
    @media (max-width: 640px) {
      margin-left: $margin_side_sp / 2 * -1;
      margin-right: $margin_side_sp / 2 * -1;
      margin-top: $margin_top_sp * -1;
    }
  }
  .m_staff_list_item {
    width: calc(33.33% - #{$margin_side});
    margin-left: $margin_side / 2;
    margin-right: $margin_side / 2;
    margin-top: $margin_top;
    animation: fadeUp 0.3s ease both;
    @media (max-width: 640px) {
      width: calc(50% - #{$margin_side_sp});
      margin-left: $margin_side_sp / 2;
      margin-right: $margin_side_sp / 2;
      margin-top: $margin_top_sp;
      &:nth-child(even) {
        padding-top: 60px;
      }
    }

    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        animation-delay: 0.2 + 0.2s * $i - 0.2s;
      }
    }
  }
}

.m_staff_link {
  overflow: hidden;
  display: block;
  position: relative;
  .m_staff_link_thumbnail {
    img {
      transition: transform 0.3s ease, opacity 0.3s ease;
    }
  }

  .m_staff_link_detail {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px;
    z-index: 1;
    background: rgba(#000, 0.8);
    transition: background 0.3s ease;
  }

  .m_staff_link_job {
    color: #fff;
    font-size: 20px;
  }

  .m_staff_name {
    font-size: 14px;
    .recruit,
    .career {
      font-weight: bold;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .recruit {
      color: #ff9600;
      background-image: -webkit-linear-gradient(0deg, #ff9600, #ff6600);
    }
    .career {
      color: #00aeff;
      background-image: -webkit-linear-gradient(0deg, #00aeff, #003cff);
    }
    ._name {
      margin-left: 10px;
      color: #d9d9d9;
    }
  }
  .m_staff_link_arrow {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    bottom: 18px;
    right: 15px;
  }



  &:hover {
    @media (min-width: 769px) {
      .m_staff_link_thumbnail {
        img {
          transform: scale(1.1);
          opacity: 0.8;
        }
        }
      .m_staff_link_detail {
        background-color: rgba(lighten($color_orange, 50%), 0.8);
        }
      .m_staff_link_arrow {
       .m_svg {
         polygon {
           fill: $color_orange;
         }
       }
      }
    }
  }
}






// ▽ 詳細ページ
// ----------------------------------------
.m_staff_header {
  margin-top: 100px;
  @media (max-width: 640px) {
    margin-top: 60px;
  }
}
.m_staff_recruit_type {
  > ._inner {
    display: block;
    width: calc(((100% - 1000px) / 2) + 290px);
    padding-left: calc(((100% - 1000px) / 2));
    background: #383838;
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    padding-top: 5px;
    padding-bottom: 5px;
    letter-spacing: 0.15em;
    @media (max-width: 1040px) {
      width: 290px;
      padding-left: 20px;
    }
    @media (max-width: 640px) {
      width: calc(50% - 100px);
    }
    @media (max-width: 480px) {
      width: 130px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
    }
  }
}

.m_staff_title {
  width: 100%;
  margin-top: 10px;
  @media (max-width: 375px) {
    margin-top: 5px;
  }
  ._inner {
    width: calc(((100% - 1000px) / 2) + 900px);
    padding-left: calc(((100% - 1000px) / 2));
    background: #383838;
    color: #fff;
    font-size: 36px;
    padding-top: 5px;
    padding-bottom: 5px;
    letter-spacing: 0.15em;
    @media (max-width: 1040px) {
      padding-left: 20px;
    }
    @media (max-width: 880px) {
      width: calc(100% - 40px);
    }
    @media (max-width: 640px) {
      padding-left: 15px;
      font-size: 32px;
    }
    @media (max-width: 480px) {
      font-size: 28px;
    }
    @media (max-width: 375px) {
      font-size: 26px;
    }
    // @extend %inner_setting;
    // max-width: 1000px;
    // box-sizing: content-box;
    // margin-right: auto;
    // margin-left: auto;

  }
  &::first-letter {
    color: $color_orange;
  }
  .m_staff_title_sub {
    font-weight: normal;
    font-size: 50%;
    color: #d9d9d9;
    letter-spacing: 0.05em;
    @media (max-width: 640px) {
      display: none;
    }
  }
}


.m_staff_name_sp {
  display: none;
  @media (max-width: 640px) {
    margin-top: 10px;
    display: inline-block;
    min-width: calc(50% - 10px);
    padding-right: 40px;
    padding-left: 15px;
    color: #d9d9d9;
    background: #383838;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  @media (max-width: 375px) {
    margin-top: 5px;
    font-size: 14px;
  }
}

.m_color_orange {

}





// END 社員紹介







// ================================================================================
//
// ▼ FAQ
//
//=============================================== =================================
.m_qa_list {
  border-bottom: dotted 2px #a3a3a3;
  .m_qa_list_item {
    border-top: dotted 2px #a3a3a3;
  }
}


.m_qa_layout {
  transition: background 0.3s ease;
  .m_qa_layout_inner {
    display: flex;
    padding: 20px 30px 20px 20px;
    align-items: baseline;
    @media (max-width: 640px) {
      padding: 15px 30px 15px 15px;
    }
  }

  .m_qa_layout_icon {
    font-weight: bold;
    white-space: nowrap;
    font-size: 120%;
    width: 2em;
    font-family: $font_family_en;
  }
  .m_qa_layout_text {
    font-weight: normal;
    @include flex();
    @include text();
  }
  &.is_head {
    $x: 14px;
    $y: 2px;
    $offset: 5px;
    cursor: pointer;
    position: relative;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      background: #666;
      top: 50%;
    }
    &::before { // 横
      width: $x;
      height: $y;
      right: $offset;
      transform: translateY(-50%);
      transition: opacity 0.3s ease;
    }
    &::after { // 縦
      width: $y;
      height: $x;
      right: $offset + ($x / 2);
      transform: translate(50%, -50%);
      transition: transform 0.3s ease;
    }

    &.is_active {
      &::before {
        opacity: 0;
      }
      &::after {
        transform: translate(50%, -50%) rotate(90deg);
      }
    }

    &:hover {
      @media (min-width: 769px) {
        background: lighten($color_orange, 20%);
      }
    }
  }
  &.is_body {
    display: none;
    background: #f3f3f3;
    .m_qa_layout_icon {
      color: #ff9600;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: -webkit-linear-gradient(0deg, #ff9600, #ff6600);
    }
  }
}


// ================================================================================
//
// ▼ 8ingとは
//
//================================================================================
// ------------------------------------------------------------
// - ▼ キーワードリスト
// ------------------------------------------------------------
.m_keywords_label {
  .m_keywords_label_inner {
    font-size: 0;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -15px;
    @media (max-width: 640px) {
      margin-left: -3px;
      margin-right: -3px;
      margin-top: -8px;
    }
  }
  .m_keywords_label_item {
    padding: 8px 25px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 15px;
    background: #333;
    color: #fff;
    font-size: 20px;
    display: inline-block;
    font-family: $font_family_open_sans;
    @media (max-width: 640px) {
      padding: 3px 10px;
      margin-left: 3px;
      margin-right: 3px;
      margin-top: 8px;
      font-size: 16px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
    }
  }
}



// ================================================================================
//
// ▼ 福利厚生
//
//================================================================================
// ▽ アンカーリンク
// ----------------------------------------
.m_welfare_anchor_list {
  $margin_side: 20px;
  $margin_top: 16px;
  $margin_side_sp: 10px;
  $margin_top_sp: 8px;
  overflow: hidden;
  .m_welfare_anchor_list_inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: $margin_side / 2 * -1;
    margin-right: $margin_side / 2 * -1;
    margin-top: $margin_top * -1;
    @media (max-width: 640px) {
      margin-left: $margin_side_sp / 2 * -1;
      margin-right: $margin_side_sp / 2 * -1;
      margin-top: $margin_top_sp * -1;
    }
  }

  .m_welfare_anchor_list_item {
    width: calc( 25% - #{$margin_side});
    margin-left: $margin_side / 2;
    margin-right: $margin_side / 2;
    margin-top: $margin_top;
    @media (max-width: 640px) {
      width: calc( 33.33% - #{$margin_side});
      margin-left: $margin_side_sp / 2;
      margin-right: $margin_side_sp / 2;
      margin-top: $margin_top_sp;
    }
    @media (max-width: 375px) {
      width: calc( 50% - #{$margin_side});
    }
  }
}

// ▽ アンカーボタン
// ----------------------------------------
.m_anchor_link {
  display: flex;
  height: 55px;
  background-color: #ededed;
  padding-left: 20px;
  padding-right: 30px;
  align-items: center;
  position: relative;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.1em;
  transition: background 0.3s ease;
  @media (max-width: 640px) {
    font-size: 16px;
    height: 45px;
    padding-right: 30px;
  }
  &::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 7px 0 7px;
    border-color: #333 transparent transparent transparent;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    @media (max-width: 640px) {
      border-width: 8px 5px 0 5px;
      right: 15px;
    }
  }
  &:hover {
    @media (min-width: 769px) {
      background: lighten($color_orange, 15%);
    }
  }

  &.is_recruit_font {
    @media (max-width: 880px) {
      font-size: 16px;
    }
    @media (max-width: 640px) {
      font-size: 14px;
    }
  }
}



.m_welfare_article {
  + .m_welfare_article {
    margin-top: 150px;
  }
  .m_welfare_section {

    + .m_welfare_section {
      margin-top: 95px;
    }
  }
  .m_welfare_title {
    font-size: 34px;
    letter-spacing: 0.2em;
    @media (max-width: 640px) {
      font-size: 28px;
      letter-spacing: 0.1em;
    }
    @media (max-width: 480px) {
      font-size: 26px;
    }
    @media (max-width: 375px) {
      font-size: 24px;
    }
  }
  .m_welfare_layout {
    display: flex;
    @media (max-width: 640px) {
      display: block;
    }
  }
  .m_welfare_detail {
    @include flex();
  }
  .m_welfare_thumbnail {
    width: 34%;
    margin-left: 40px;
    @media (max-width: 640px) {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
      text-align: center;
    }
  }

}






// FAQ





// ================================================================================
//
// ▼ 8ingだから出来ること
//
//================================================================================
.m_project_article {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  background: #fff;
  padding-bottom: 85px;
  margin-top: 115px;
  @media (max-width: 640px) {
    margin-top: 60px;
    padding-bottom: 45px;
  }
  + .m_project_article {
    margin-top: 120px;
    @media (max-width: 640px) {
      margin-top: 70px;
    }
  }

  .m_project_title {
    font-size: 36px;
    position: relative;
    top: -30px;
    margin-bottom: -30px;
    letter-spacing: 0.05em;
    @media (max-width: 640px) {
      font-size: 32px;
      top: -26px;
      margin-bottom: -26px;
    }
    @media (max-width: 480px) {
      font-size: 28px;
      top: -22px;
      margin-bottom: -22px;
    }
    @media (max-width: 375px) {
      font-size: 24px;
      top: -18px;
      margin-bottom: -18px;
    }
    &::after {
      margin-top: 10px;
      content: '';
      display: block;
      width: 180px;
      height: 3px;
      background: linear-gradient(to right, #efac29 50px, #333 50px);
      @media (max-width: 375px) {
        width: 140px;
      }
    }
  }

  .m_project_name {
    margin-top: 15px;
    letter-spacing: 0.1em;
  }

  .m_project_photo {
      margin-top: 20px;
      max-width: 650px;
      margin-right: auto;
      margin-left: auto;
      @media (max-width: 640px) {
        margin-top: 15px;

      }
  }

  .m_project_text {
      margin-top: 35px;
      @media (max-width: 640px) {
        margin-top: 25px;

      }
  }

}




// ================================================================================
//
// ▼ 採用情報
//
//================================================================================

// ------------------------------------------------------------
// - ▼ 募集一覧
// ------------------------------------------------------------
.m_recruit_list {
  .m_recruit_list_item {
    + .m_recruit_list_item {
      margin-top: 100px;
      padding-top: 100px;
      border-top: solid 1px #ccc;
      @media (max-width: 640px) {
        margin-top: 40px;
        padding-top: 40px;
      }
    }
  }
}


.m_recruit_card {
  display: flex;
  @media (max-width: 640px) {
    display: block;
  }
  .m_recruit_card_icon {
    width: 204px;
    margin-right: 40px;
    @media (max-width: 768px) {
      width: 160px;
    }
    @media (max-width: 640px) {
      margin-right: auto;
      margin-left: auto;
    }
    @media (max-width: 480px) {
      width: 120px;
    }
  }
  .m_recruit_card_detail {
    @include flex();
    @media (max-width: 640px) {
      margin-top: 20px;
    }
  }
  .m_recruit_card_title {
    font-size: 34px;
    letter-spacing: 0.1em;
    @media (max-width: 640px) {
      font-size: 30px;
    }
    @media (max-width: 480px) {
      font-size: 26px;
    }
    @media (max-width: 375px) {
      font-size: 22px;
    }
  }
  .m_recruit_card_sub_title {
    color: $color_orange;
    font-size: 18px;
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
  .m_recruit_card_btn {
    max-width: 310px;
    margin-top: 35px;
    @media (max-width: 640px) {
      width: 80%;
      margin-right: auto;
      margin-left: auto;
    }
    @media (max-width: 480px) {
      margin-top: 20px;
    }
  }
}






// ------------------------------------------------------------
// - ▼ 新卒
// ------------------------------------------------------------
.m_recruit_anchor {
  $margin_side: 20px;
  $margin_top: 16px;
  $margin_side_sp: 10px;
  $margin_top_sp: 10px;
  overflow: hidden;
  .m_recruit_anchor_inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: $margin_side / 2 * -1;
    margin-right: $margin_side / 2 * -1;
    margin-top: $margin_top * -1;
    @media (max-width: 640px) {
      margin-left: $margin_side_sp / 2 * -1;
      margin-right: $margin_side_sp / 2 * -1;
      margin-top: $margin_top_sp * -1;
    }
    @media (max-width: 375px) {
      margin-top: -5px;
      display: block;
    }
  }
  .m_recruit_anchor_item {
    width: calc(33.33% - #{$margin_side});
    margin-left: $margin_side / 2;
    margin-right: $margin_side / 2;
    margin-top: $margin_top;
    @media (max-width: 768px) {
      width: calc(50% - #{$margin_side});
      margin-left: 0;
      margin-right: 0;
    }
    @media (max-width: 640px) {
      margin-left: $margin_side_sp / 2;
      margin-right: $margin_side_sp / 2;
      margin-top: $margin_top_sp;
    }
    @media (max-width: 375px) {
      margin-top: 5px;
      width: auto;
      margin-left: 0;
      margin-right: 0;
    }
  }
}


.m_select_flow {
  .m_select_flow_item {
    + .m_select_flow_item {
      margin-top: 40px;
      position: relative;
      @media (max-width: 640px) {
        margin-top: 30px;
      }
      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 17px 0 17px;
        border-color: $color_orange transparent transparent transparent;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 640px) {
          top: -15px;
          border-width: 12px 10px 0 10px;
        }
      }
    }
  }
}


.m_select_flow_box_sub {
  background: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
  display: none;
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 640px) {
    display: block;
  }
  @media (max-width: 375px) {
    font-size: 15px;
  }
}

.m_select_flow_box {
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 640px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .m_select_flow_box_inner {
    max-width: 590px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    display: flex;
    align-items: center;
    height: 80px;
    font-size: 24px;
    letter-spacing: 0.05em;
    padding-left: 0.05em;
    font-weight: bold;
    @media (max-width: 640px) {
      height: 70px;
      font-size: 20px;
    }
    @media (max-width: 375px) {
      font-size: 16px;
      height: 55px;
    }
    > ._icon {
      position: absolute;
      left: 0;
      width: 64px;
      display: block;
      text-align: center;
      @media (max-width: 640px) {
        width: 58px;
      }
      @media (max-width: 440px) {
        width: 52px;
      }
      @media (max-width: 375px) {
        width: 38px;
      }

      &.is_icon_01 {
        img {
          width: (52 / 64 * 100) * 1%;
        }
      }
      &.is_icon_02 {
        img {
          width: (42 / 64 * 100) * 1%;
        }
      }
      &.is_icon_03 {
        img {
          width: (42 / 64 * 100) * 1%;
        }
      }
      &.is_icon_04 {
      }
      &.is_icon_05 {
        img {
          width: (44 / 64 * 100) * 1%;
        }
      }
    }
    > ._text {
      width: 100%;
      display: block;
      text-align: center;
      > ._sub {
        margin-left: 15px;
        font-size: 85%;
        @media (max-width: 640px) {
          display: none;
        }
      }
    }
    .m_select_note {
      font-size: 14px;
      @media (max-width: 640px) {
        font-size: 12px;
      }
      @media (max-width: 375px) {
        font-size: 10px;
      }
    }
  }
}



.m_band_tilte_up {
  position: relative;
  top: -28px;
  margin-bottom: -28px;
  @media (max-width: 640px) {
    top: -26px;
    margin-bottom: -26px;
  }
  @media (max-width: 480px) {
    top: -23px;
    margin-bottom: -23px;
  }
  @media (max-width: 375px) {
    top: vw(-20, 375);
    margin-bottom: vw(-20, 375);
  }
}


// ------------------------------------------------------------
// - ▼ リクルートサイトへの導線
// ------------------------------------------------------------
.m_recruit_banner {
  display: flex;
  justify-content: center;
  @media (max-width: 640px) {
    display: block;
    width: 90%;
    max-width: 320px;
    margin-right: auto;
    margin-left: auto;
  }
  .m_recruit_banner_item {
    width: calc(50% - 10px);
    @media (max-width: 640px) {
      width: auto;
    }
    + .m_recruit_banner_item {
      margin-left: 20px;
      @media (max-width: 640px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
  img {
    width: 100%;
  }
}



// ------------------------------------------------------------
// - ▼ 2つのアンカー
// ------------------------------------------------------------
.m_anchor_colum2 {
  $margin_side: 20px;
  $margin_top: 10px ;
  .m_anchor_colum2_inner {

    display: flex;
    flex-wrap: wrap;
    margin-left: $margin_side / 2 * -1;
    margin-right: $margin_side / 2 * -1;
    margin-top: $margin_top * -1;
    @media (max-width: 480px) {
      display: block;
      margin-left: 0;
      margin-right: 0;
      margin-top: -5px;
    }
  }

  .m_anchor_colum2_item {
    width: calc( 50% - #{$margin_side});
    margin-left: $margin_side / 2;
    margin-right: $margin_side / 2;
    margin-top: $margin_top;
    @media (max-width: 480px) {
      width: auto;
      margin-top: 5px;
      margin-left: 0;
      margin-right: 0;
    }

  }

}


// ------------------------------------------------------------
// - ▼ 募集要項詳細ページのテーブル
// ------------------------------------------------------------
.requirements_table {
  border-bottom: solid 1px #ccc;
  .requirements_table_row {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    border-top: solid 1px #ccc;
    @media (max-width: 640px) {
      display: block;
    }
  }

  .requirements_table_title {
    width: 140px;
    margin-right: 20px;
    font-size: 18px;
    color: $color_orange;
    @media (max-width: 640px) {
      width: auto;
      font-size: 16px;
    }
  }
  .requirements_table_detail {
    @include flex();
    @media (max-width: 640px) {
      margin-top: 10px;
    }
  }



}




// ================================================================================
//
// ▼ サイトマップ
//
//================================================================================
// ------------------------------------------------------------
// - ▼ サイトマップページの3カラム
// ------------------------------------------------------------
.m_sitemap_layout {
  display: flex;
  justify-content: space-between;
  @media (max-width: 640px) {
    display: block;
  }
  .m_sitemap_layout_column {
    // flex: 1;
    + .m_sitemap_layout_column  {
      margin-left: 40px;
      @media (max-width: 640px) {
        margin-left: 0;
        margin-top: 30px;
      }
    }
  }
}


.m_sitemap_sub {
  .m_sitemap_sub_inner {
    margin-top: -15px;
    @media (max-width: 640px) {
      font-size: 0;
      margin-left: -5px;
      margin-right: -5px;
      margin-top: -10px;
    }
  }
  .m_sitemap_sub_item {
    margin-top: 15px;
    @media (max-width: 640px) {
      display: inline-block;
      margin-top: 10px;
      font-size: 16px;
      width: calc(33.33% - 10px);
      margin-left: 5px;
      margin-right: 5px;
    }
    @media (max-width: 480px) {
      width: calc(50% - 10px);
    }
    &.is_wide {
      @media (max-width: 480px) {
        width: calc(100% - 10px);
      }
    }
  }
}



// ------------------------------------------------------------
// - ▼ インラインブロックの横並びのリスト
// ------------------------------------------------------------
.m_inline_list {
  overflow: hidden;
  .m_inline_list_inner {
    margin-right:  -80px;
    margin-top: -15px;
    font-size: 0;
    @media (max-width: 640px) {
      margin-right: 0;
    }
  }
  .m_inline_list_item {
    display: inline-block;
    margin-right: 80px;
    font-size: 16px;
    margin-top: 15px;
    @media (max-width: 640px) {
      margin-right: 0;
      width: 33.33%;
    }
    @media (max-width: 480px) {
      margin-right: 0;
      width: 50%;
    }
  }
}

// EOT
