// ================================================================================
//
// ▼ _button.scss
//
//================================================================================

// ------------------------------------------------------------
// - ▼ ボタンのラッパー
// ------------------------------------------------------------
.m_btn_wrapper {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  &.is_max_400 {
    max-width: 400px;
  }
  &.is_max_350 {
    max-width: 350px;
  }
}



// ------------------------------------------------------------
// - ▼ ヘッダーのグラデーションのコンバージョンボタン
// ------------------------------------------------------------
.m_btn_gradation_header {
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: center;
  .m_btn_gradation_header_text {
    letter-spacing: 4px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: bold;
    color: #fff;
    @media (max-width: 768px) {
      letter-spacing: 2px;
      font-size: 15px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
    }
    > ._large {
      font-size: 125%;
    }
  }


  &.is_color_orange {


    	background: linear-gradient(to right, #ff9600, #ff6600);

  }
  &.is_color_blue {
    background: linear-gradient(to right, #00aeff, #003cff);


  }
}






// ------------------------------------------------------------
// - ▼ ボーダーのボタン（）
// ------------------------------------------------------------
.m_btn_border_blank {
  display: flex;
  height: 65px;
  border: solid 1px #929292;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  @media (max-width: 480px) {
    height: 55px;
  }
  > ._text {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
  > ._icon {
    width: 16px;
    margin-left: 10px;
    .m_svg {
      polygon {
        fill: #fff;
      }
    }
  }

  &:hover {
    @media (min-width: 769px) {
      background: lighten($color_orange, 5%);
    }
  }
}








// ------------------------------------------------------------
// - ▼ ボーダーのボタン（）
// ------------------------------------------------------------
.m_btn_border_arrow {
  display: flex;
  height: 65px;
  border: solid 1px #fff;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  @media (max-width: 480px) {
    height: 55px;
  }
  > ._text {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
  > ._icon {
    width: 20px;
    margin-left: 10px;
    .m_svg {
      polygon {
        fill: #fff;
      }
    }
  }

  &.is_color_black {
    border-color: #929292;
    > ._text {
      color: #333;
    }
    > ._icon {
      .m_svg {
        polygon {
          fill: #333;
        }
      }
    }
  }

  &:hover {
    @media (min-width: 769px) {
      background: lighten($color_orange, 5%);
    }
  }
}




// ------------------------------------------------------------
// - ▼ 会社概要のボタン
// ------------------------------------------------------------
.m_about_link {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85px;
  background-repeat: no-repeat;
  position: relative;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  &::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 14px 14px;
    border-color: transparent transparent #999 transparent;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  ._text {
    text-align: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
  }

  &.is_icon_8ing {
    background-image: url(/_materials/images/common/parts/icon_8ing.png);
    background-size: 40px auto;
    background-position: left 43px center ;
  }

  &.is_icon_building {
    background-image: url(/_materials/images/common/parts/icon_building.png);
    background-size: 46px auto;
    background-position: left 43px center ;


  }

  &.is_icon_heart {
    background-image: url(/_materials/images/common/parts/icon_heart.png);
    background-size: 56px auto;
    background-position: left 43px center ;

  }

  &:hover {
    @media (min-width: 769px) {
      opacity: 0.6;
      background-color: #eee;
    }
  }
}


// [EOT]
