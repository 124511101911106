// ================================================================================
//
// ▼ CMSによって出力されるエディターの調整等
//
//================================================================================
.m_editor_container {
  > *:first-child {
    margin-top: 0 !important;
  }
  > *:last-child {
    margin-bottom: 0 !important;
  }

  h1 {
    @include h1;
    margin-top: 100px;
    margin-bottom: 35px;
    @media (max-width: 640px) {
      margin-top: 60px;
      margin-bottom: 20px;
    }
  }

  h2 {
    font-size: 34px;
    margin-top: 80px;
    margin-bottom: 20px;
    @media (max-width: 640px) {
      font-size: 28px;
      margin-top: 50px;
      margin-bottom: 15px;
    }
    @media (max-width: 375px) {
      font-size: 24px;
    }
  }

  h3, h4, h5, h6 {
    font-size: 16px;
    margin-top: 40px;
    margin-bottom: 15px;
    line-height: 1.8;
    @media (max-width: 640px) {
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }

  h2 + h3 {
    margin-top: 20px;
    @media (max-width: 640px) {
      margin-top: 15px;
    }
  }

  p {
    margin-bottom: 10px;
    @include text();
    line-height: 1.8;
    @media (max-width: 640px) {
      margin-bottom: 5px;
    }
  }

  ul {
    list-style: disc;
    // list-style-position: inside;
    margin-left: 2em;
    margin-bottom: 20px;
    @include text();
    @media (max-width: 640px) {
      margin-bottom: 15px;
    }
  }
  ol {
    // list-style-position: inside;
    margin-bottom: 20px;
    margin-left: 2em;
    @include text();
    @media (max-width: 640px) {
      margin-bottom: 15px;
    }
  }


  img.aligncenter,
  img.alignleft,
  img.alignright {
    display: block;
  }
  img.aligncenter {
    margin-right: auto;
    margin-left: auto;
  }
  img.alignleft {
    margin-left: 0;
    margin-right: auto;
  }
  img.alignright {
    margin-right: 0;
    margin-left: auto;
  }

  blockquote {
    margin: 0 0 28px;
    padding: 30px 25px 35px;
    border: 1px #e5e5e5 solid;
    background: #fafafa;

    *:last-child {
      margin-bottom: 0 !important;
    }
  }

  a {
    color: saturate($color_orange, 5%);
    border-bottom: solid 1px saturate($color_orange, 5%);
  }

  a[target="_blank"] {
    transition: opacity 0.3s ease;
    &::after {
      content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAALCAYAAACgR9dcAAAAUklEQVQokWP8//8/AwMDA8OvizUQBing6zrJ/////2f4eaH6/////xmIxT8vVP9nItk2JDCqmUTAgswhOa5h8UwOZmFgYGD4tl6K5NTFFfiMEQBJTGdN5mEBfwAAAABJRU5ErkJggg==);
      margin-left: 2px;
      margin-right: 2px;
    }
    &:hover {
      @media (min-width: 769px) {
        opacity: 0.7;
      }
    }
  }


  .movie-wrap {
    display: block;
    position: relative;
    &::before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }
    > iframe {
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}


// ================================================================================
//
// ▼ シンプルなタイプ
//
//================================================================================
.m_editor_container_simple {
  > *:first-child {
    margin-top: 0 !important;
  }
  > *:last-child {
    margin-bottom: 0 !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 20px;
    @include text();
    font-size: 18px;
    @media (max-width: 640px) {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  p {
    margin-bottom: 20px;
    // line-height: 2;
    @include text();
    @media (max-width: 640px) {
      margin-bottom: 15px;
    }
  }

  ul {
    list-style: disc;
    // list-style-position: inside;
    margin-left: 2em;
    margin-bottom: 20px;
    @include text();
    @media (max-width: 640px) {
      margin-bottom: 15px;
    }
  }
  ol {
    // list-style-position: inside;
    margin-bottom: 20px;
    margin-left: 2em;
    @include text();
    @media (max-width: 640px) {
      margin-bottom: 15px;
    }
  }

  hr {
    border-top: dotted 2px #ccc;
  }

  img.aligncenter,
  img.alignleft,
  img.alignright {
    display: block;
  }
  img.aligncenter {
    margin-right: auto;
    margin-left: auto;
  }
  img.alignleft {
    margin-left: 0;
    margin-right: auto;
  }
  img.alignright {
    margin-right: 0;
    margin-left: auto;
  }

  blockquote {
    margin: 0 0 28px;
    padding: 30px 25px 35px;
    border: 1px #e5e5e5 solid;
    background: #fafafa;

    *:last-child {
      margin-bottom: 0 !important;
    }
  }

  a {
    $color: saturate($color_orange, 5%);
    $color: #333;
    color: $color;
    border-bottom: solid 1px $color;
  }

  // a[target="_blank"] {
  //   transition: opacity 0.3s ease;
  //   &:hover {
  //     @media (min-width: 769px) {
  //       opacity: 0.7;
  //     }
  //   }
  // }


  .movie-wrap {
    display: block;
    position: relative;
    &::before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }
    > iframe {
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
