// ================================================================================
//
// ▼ SVGの比率を保持するファイル
//
//================================================================================
.m_svg {
  position: relative;
  &::before {
    content: '';
    display: block;
  }
  .m_svg_object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }


  // ▽ ロゴ
  // ----------------------------------------
  &.is_icon_8ing {
    &::before {
      $width: 104.2;
      $height: 59.7;
      padding-top: my_floor($height / $width * 100%)
    }
  }


  // ▽ ロゴ（シンプル）
  // ----------------------------------------
  &.is_icon_8ing_simple {
    &::before {
      $width: 102.2;
      $height: 55.7;
      padding-top: my_floor($height / $width * 100%)
    }
  }


  // ▽ コロプラロゴ
  // ----------------------------------------
  &.is_icon_colopl {
    &::before {
      $width: 907.1;
      $height: 76.6;
      padding-top: my_floor($height / $width * 100%)
    }
  }


  // ▽ スマホ
  // ----------------------------------------
  &.is_icon_blank {
    &::before {
      $width: 15;
      $height: 13;
      padding-top: my_floor(($height / $width) * 100) * 1%;
    }
    .m_svg_object {
      .a {
        fill: #aaaaaa;
      }
      .b {
        fill: #aaaaaa;
      }
    }
  }


  // ▽ スマホ
  // ----------------------------------------
  &.is_icon_linkicon {
    &::before {
      $width: 128;
      $height: 128;
      padding-top: my_floor(($height / $width) * 100) * 1%;
    }
    .m_svg_object {
      .a {
        fill: #aaaaaa;
      }
    }
  }


  // ▽ リンクの矢印
  // ----------------------------------------
  &.is_icon_arrow {
    &::before {
      $width: 42.9;
      $height: 10.1;
      padding-top: my_floor(($height / $width) * 100) * 1%;
    }
    .m_svg_object {
      .a {
        fill: #fff;
      }
    }
  }
}



// [-EOT-]
