// ================================================================================
//
// ▼ keyframes.scss
//
//================================================================================
@keyframes fade_in {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes fade_out {
  from   { opacity: 1; }
  to { opacity: 0; }
}

@keyframes slide_up {
  from { transform: translate3d(0, 30px, 0) }
  to { transform: translate3d(0, 0, 0) }
}


@keyframes bar_01 {
  from {
  }
  to {
    top: 50%;
  }
}

@keyframes navigation_close {
  to {
    filter: blur(100px);
    opacity: 0;
  }
}



@keyframes slide_flip_left {
  from {
    transform: rotate3d(0, 1, 0, 90deg);
    opacity: 0;
   }
  to {
    transform: rotate3d(0, 1, 0, 0deg);
    opacity: 1;
  }
}
@keyframes slide_flip_right {
  from {
    transform: rotate3d(0, 1, 0, -90deg);
    opacity: 0;
   }
  to {
    transform: rotate3d(0, 1, 0, 0deg);
    opacity: 1;
  }
}


@keyframes fadeUp {
  0% {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
  75% {
    transform: translate3d(0, -5px, 0);
  }
  100% {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
  }
}



@keyframes fadeUp {
  0% {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
  75% {
    transform: translate3d(0, -5px, 0);
  }
  100% {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
  }
}

/*
問題：
５秒ごとに繰り返すアニメーションを実装する。
アニメーションの内容は0.3秒かけて フェードインするものとする。
*/
@mixin interval_keyframes($animationName, $duration, $interval, $animationHash) {
  $durationRatio: $duration / $interval;

  @keyframes #{$animationName} {
    @each $key, $hash in $animationHash  {
      #{$key * $durationRatio}% {
        @each $selector, $val in $hash {
          #{$selector}: $val;
        }
      }
    }
  }
}

$PopUpHash: (
  0: ( transform: translate3d(0 ,0, 0) ),
  50: ( transform: translate3d(0 ,-5px, 0) ),
  100: ( transform: translate3d(0 ,0, 0) )
);
@include interval_keyframes(popUp, 0.5s, 3s, $PopUpHash);
//
