// ================================================================================
//
// ▼ header.scss
//
//================================================================================

.l_header {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10000;
  transform: translate3d(0,0,0); // チラツキ解消のために必要
  transition: background 0.3s ease;
  background: #222;
}

.l_header_inner {
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  @media (max-width: 640px) {
    padding-left: 15px;
    padding-right: 15px;
    height: 70px;
  }
  @media (max-width: 375px) {
    height: 60px;
  }
}

.l_header_logo {
  position: relative;
  display: flex;
  // @media (max-width: 640px) {
  //   width: 84px;
  // }
  // @media (max-width: 375px) {
  //   width: 74px;
  // }

  // > ._logo {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  // }
  align-items: center;
  .l_header_logo_icon {
    width: 80px;
    @media (max-width: 480px) {
      width: 60px;
    }
    .m_svg {
      path,
      rect,
      g {
        fill: #fff !important;
      }
    }
  }
  .l_header_logo_text {
    margin-left: 20px;
    font-size: 18px;
    @media (max-width: 480px) {
      font-size: 17px;
      margin-left: 10px;
      margin-top: 8px;
    }

    white-space: nowrap;
    color: #fff;
    font-family: $font_family_en;
    font-weight: bold;
  }
}


.l_header_right {
  display: flex;
  align-items: center;
}
.l_header_recruit {
  width: 410px;
  display: flex;
  @media (max-width: 768px) {
    width: 310px;
  }
  @media (max-width: 640px) {
    display: none;
  }
  .l_header_recruit_item {
    @include flex();

    + .l_header_recruit_item {
      margin-left: 10px;
    }
  }
}

.l_recruit_btn {
  background: #000;
  color: #fff;
  font-family: $font_family_en;
  font-size: 18px;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  @media (max-width: 640px) {
    height: 40px;
    font-size: 16px;
  }
  @media (max-width: 440px) {
    font-size: 14px;
  }
  @media (max-width: 375px) {

    height: 35px;
  }

   > ._text {
     margin-right: -0.2em;
     letter-spacing: 0.2em;
     @media (max-width: 640px) {
       margin-right: -0.15em;
       letter-spacing: 0.15em;
     }
   }

   &:hover {
     @media (min-width: 769px) {
       background: lighten($color_orange, 10%);
     }
   }
}


.l_header_menu_btn {
  display: block;
  width: 40px;
  height: 40px;
  // background: orange;
  margin-left: 20px;
  position: relative;
  cursor: pointer;
  @media (max-width: 640px) {
    width: 45px;
    height: 45px;
    margin-left: 15px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
    width: 40%;
    height: 40%;
    opacity: 0;
    background: rgba( lighten($color_orange, 10%) , 0.8);
    border-radius: 50%;
    transition:
      width 0.3s ease,
      height 0.3s ease,
      opacity 0.3s ease;
  }
  > ._bar {
    display: block;
    background: #fff;
    position: absolute;
    width: 80%;
    height: 3px;
    left: 50%;
    @media (max-width: 640px) {
      width: 75%;
      height: 3px;
    }
    &:nth-child(1) {
      top: 10px;
      transform: translate(-50%, -50%);
      transition:
        top 0.15s  0.15s ease,
        transform 0.15s 0s ease ;
      @media (max-width: 640px) {
        top: 11px;
      }
      body.VIEW_NAVIGATION & {
        top: 50%;
        transition:
          top 0.15s  0s ease,
          transform 0.15s 0.15s ease ;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    &:nth-child(2) {
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0s 0.15s linear;
      body.VIEW_NAVIGATION & {
        opacity: 0;
      }
    }
    &:nth-child(3) {
      bottom: 10px;
      transform: translate(-50%, 50%);
      transition:
        bottom 0.15s  0.15s ease,
        transform 0.15s 0s ease ;
      @media (max-width: 640px) {
        bottom: 11px;
      }
      body.VIEW_NAVIGATION & {
        bottom: 50%;
        transition:
          bottom 0.15s  0s ease,
          transform 0.15s 0.15s ease ;
        transform: translate(-50%, 50%) rotate(45deg);
      }
    }
  }
  @media (min-width: 769px) {
    transition: background 0.3s ease;
    &:hover {
      &::before {
        width: 120%;
        height: 120%;
        opacity: 1;
      }
    }
  }

}

// EOT
