// ================================================================================
//
// ▼ core.scss
//
//    全体のSASSで使用する 変数、関数、エクステンド、ミックスインを管理しているファイルです。
//
//
//================================================================================


// ------------------------------------------------------------
// - ▼ font設定
// ------------------------------------------------------------
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/_materials/font/opensans.woff2) format('woff2');
  font-display: swap;
}


// ------------------------------------------------------------
// - ▼ グローバル変数の設定
// ------------------------------------------------------------

// ▽ カラー
// ----------------------------------------
$color_orange: #f5ae19;
// f5ae19
// efac29;



// ▽ パス
// ----------------------------------------
$path: '/';
$img: '/_materials/images/';


// ▽ ブレイクポイント
// ----------------------------------------
// 以下のブレイクポイント以外が必要な場合は都度宣言

$break_ll : 1400px; // デザイン上の最大幅
$break_l  : 1024px; // タブレット(ipad pro など) / メニュー表示の切り替わり
$break_m  : 768px;  // タブレット（縦）
$break_s  : 640px;  // スマホ版
$break_xs : 480px;  // スマホ版（ミニ）
$break_xxs: 375px;  // スマホ版（最小）


// ▽ font-family
// ----------------------------------------
$font_family_gothic: Robots, 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, Meiryo, "メイリオ", sans-serif;
$font_family_open_sans: 'Open Sans', Robots, 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, Meiryo, "メイリオ", sans-serif;
$font_family_mincho: "ヒラギノ明朝 ProN W6","HiraMinProN-W6","HG明朝E","ＭＳ Ｐ明朝","MS PMincho","MS 明朝",'Noto Serif JP',serif;
$font_family_en: "Century Gothic", Robots, Helvetica, arial, sans-serif;
$font_family_en_open_sans: 'Open Sans', Robots, "Century Gothic", Helvetica, arial, sans-serif;





// ------------------------------------------------------------
// - ▼ 関数
// ------------------------------------------------------------

// ▽ split関数
// ----------------------------------------
@function str_split($string,$separator) {
  $split_arr: ();
  $index : str-index($string, $separator);

  @while $index != null {
    $item     : str-slice($string, 1, $index - 1);
    $split_arr: append($split_arr, $item);
    $string   : str-slice($string, $index + 1);
    $index    : str-index($string, $separator);
  }
  $split_arr: append($split_arr, $string);

  @return $split_arr;
}


// ▽ 数値から単位を削除する関数
// ----------------------------------------
@function ununit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}


// 任意の桁数で切り捨て
// ----------------------------------------
@function my_floor($int: 100, $cut: 0) {
  $squared: 10;
  @for $i from 1 to $cut {
    $squared: $squared * 10;
  }
  @return (floor($int * $squared) / $squared);
}


// ▽ pxをvewwidthに変換（単位ありなし対応）
// ----------------------------------------
@function vw($size: 16px, $baseWidth: $break_s){
  $size_int: ununit($size);
  $baseWidth_int: ununit($baseWidth);
  @return my_floor(($size_int / $baseWidth_int) * 100, 2) * 1vw;
}



// 可変長引数からネストされた値を取得する
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return null;
    }
    $map: map-get($map, $key);
  }

  @return $map;
}

// ▽ 配列の数字を合計する
// ----------------------------------------
@function sum_array($array) {
  $result: 0;
  @if type-of($array) == list {
    @each $num in $array {
      @if type-of($num) == number {
        $result: $result + $num;
      }
    }
  }
  @return $result;
}


// ------------------------------------------------------------
// - ▼ テキスト系
// ------------------------------------------------------------

// ▽ VW
// ----------------------------------------
@mixin font_vw($font_size: 16, $viewport: $break_xs) {
  font-size: ununit($font_size) * 1px;
  font-size: convert_vw($font_size, $viewport);
}

// ▽ REM
// ----------------------------------------
@mixin font_rem($font-size:16) {
  font-size: ununit($font-size) * 1px;
  font-size: (unniu($font-size) / 10) * 1rem;
}

// ▽ メイリオ優先
// ----------------------------------------
@mixin fontfamily_yuGothic {
  font-family: 'YuGothic', 'Yu Gothic', '游ゴシック', '游ゴシック体', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', 'Meiryo UI', 'メイリオ', Meiryo, Avenir, 'Helvetica Neue', Roboto, 'Droid Sans', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}
@mixin fontfamily_meiryo {
  font-family: 'メイリオ', 'Meiryo UI', Meiryo, 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', 'YuGothic', 'Yu Gothic', '游ゴシック', '游ゴシック体', Avenir, 'Helvetica Neue', Roboto, 'Droid Sans', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}
@mixin fontfamily_en {
  font-family: Avenir, 'Helvetica Neue', Roboto, sans-serif;
}



// ------------------------------------------------------------
// - ▼ レイアウト
// ------------------------------------------------------------

// ▽ ブロック要素のセンタリング
// ----------------------------------------
%margin_auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
@mixin margin_auto() {
  margin-left: auto !important;
  margin-right: auto !important;
}

// ▽ inner系の左右のパディング
// ----------------------------------------
%inner_padding {
  padding-right: 20px !important;
  padding-left: 20px !important;
  @media (max-width: $break_s) {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
@mixin inner_padding {
  padding-right: 20px !important;
  padding-left: 20px !important;
  @media (max-width: $break_s) {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}


// ------------------------------------------------------------
// - ▼ 便利系
// ------------------------------------------------------------

// ▽ clearfix
// ----------------------------------------
@mixin clearfix {
  content: '';
  display: block;
  clear: both;
}


// ------------------------------------------------------------
// - ▼ CSS ARROR TEMPLATE
// ------------------------------------------------------------

// ▽ ベタ塗り
// ----------------------------------------
@mixin arrow_fill() {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}

// ▽ borderタイプ
// ----------------------------------------
@mixin arrow_border($size: 10px, $broder-width: 1px, $color: #000, $gravity:right) {
  content: '';
  width: $size;
  height: $size;
  display: block;
  position: absolute;
  border-style: solid solid none none;
  border-color: $color;
  border-top-width: $broder-width;
  border-right-width: $broder-width;
  @if $gravity == 'top' {
    transform: rotate(-45deg);
  } @else if $gravity == 'right' {
    transform: rotate(45deg);
  } @else if $gravity == 'bottom' {
    transform: rotate(135deg);
  } @else if $gravity == 'keft' {
    transform: rotate(-135deg);
  }
}


// ▽  ▽
@mixin hover_underline($color: #333, $position: 'before') {
  display: inline-block;
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    transition: width 0.3s;
    background: $color;
  }
  @media (min-width: 1025px) {
    &:hover {
      &::before {
        width: 100%;
      }
    }
  }
}


// ▽ リンクとかの右下についてる青いボタン
// ----------------------------------------
@mixin link_arrow() {
  content: '';
  display: block;
  border-style: solid;
  border-color: $color_navy transparent transparent transparent;
  border-width: 8px 8px 0 8px;
  position: absolute;
  transform-origin: bottom center;
  transform: translateX(50%) rotate(-45deg);
  right: 7px;
  bottom: 7px;
}




// ------------------------------------------------------------
// - ▼ レティナ用のメディアクエリ
// ------------------------------------------------------------
@mixin retina($ratio: 1.5) {
  @media (-webkit-min-device-pixel-ratio: $ratio), (min-resolution: #{$ratio}dppx) {
    @content;
  }
}


// ------------------------------------------------------------
// - ▼ flex: 1 のショートハンド
// ------------------------------------------------------------
@mixin flex($grow: 1, $shrink: 1, $basis: 0%) {
  flex-grow: $grow;
  flex-shrink: $shrink;
  flex-basis: $basis;
}


// ------------------------------------------------------------
// - ▼ 基本となるテキスト
// ------------------------------------------------------------
@mixin text() {
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.6;
  // text-align: justify;
  // word-break: break-all;

  @media (max-width: 640px) {
    font-size: 14px;
  }
}


@mixin h1() {
  font-size: 48px;
  text-align: center;
  font-weight: normal;
  @media (max-width: 640px) {
    font-size: 42px;
  }
  @media (max-width: 375px) {
    font-size: 36px;
  }
}


@mixin h2() {
  font-size: 40px;
  text-align: center;
  font-weight: normal;
  @media (max-width: 640px) {
    font-size: 32px;
  }
  @media (max-width: 375px) {
    font-size: 28px;
  }
}


@mixin h3() {
  font-size: 30px;
  text-align: center;
  font-weight: normal;
  @media (max-width: 640px) {
    font-size: 26px;
  }
  @media (max-width: 375px) {
    font-size: 22px;
  }
}


@mixin h4() {
  font-size: 24px;
  text-align: center;
  font-weight: normal;
  @media (max-width: 640px) {
    font-size: 20px;
  }
  @media (max-width: 375px) {
    font-size: 18px;
  }
}


@mixin h5() {
  font-size: 18px;
  text-align: center;
  font-weight: normal;
  @media (max-width: 640px) {
    font-size: 16px;
  }
  @media (max-width: 375px) {
    font-size: 15px;
  }
}


@mixin h6() {
  font-size: 14px;
  text-align: center;
  font-weight: normal;
  @media (max-width: 640px) {
    font-size: 13px;
  }
}


// EOT
