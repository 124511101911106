// ================================================================================
//
// ▼ _module.scss
//
//================================================================================

// ------------------------------------------------------------
// - ▼ inner
// ------------------------------------------------------------
%inner_setting {
  @extend %inner_padding;
  margin-right: auto;
  margin-left: auto;
}
$widths: 1400 1200 1180 1020 1000 900 860 800 690;
@each $width in $widths {
  .m_inner_#{$width} {
    max-width: #{$width}px !important;
    box-sizing: content-box !important;
    @extend %inner_setting;
  }
}


// ▽ サイドの余白
// ----------------------------------------
.m_inner_padding {
  @extend %inner_padding;
}
.m_inner_padding__none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.m_sp_inner_padding__none {
  @media (max-width: 640px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}


/* ================================================================================

▼ マージン・パディング（上下のみ）

TODO:
0 ～ 100pxまでのマージンモジュールです。

m_marginTopXX  / m_marginBottomXX / m_paddingTopXX / m_paddingBottomXX
- 数字と同一のマージンが与えられます

m_sp_marginTopXX  / m_sp_marginBottomXX / m_sp_paddingTopXX / m_sp_paddingBottomXX
- window widthが640px以下の時に数字と同一のマージンが与えられます

m_rsp_marginTopXX  / m_rsp_marginBottomXX / m_rsp_paddingTopXX / m_rsp_paddingBottomXX
- window widthが641px以上の時は数字と同一のマージンが与えられます
- window widthが640px以下の時は数字とおよおそ半分のマージンが与えられます

m_rsp_marginTopXX  / m_rsp_marginBottomXX / m_rsp_paddingTopXX / m_rsp_paddingBottomXX
- window widthが640px以下の時に、640pxを基準とした場合の数字の間隔をもった間隔をVWで与えます。

================================================================================ */

// ------------------------------------------------------------
// - ▼ PC版
// ------------------------------------------------------------

// ▽ マージントップ
// ----------------------------------------
$options: -145 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100 110 120 130 140 150 160 170 180;
@each $option in $options {
  .m_marginTop#{$option} { margin-top: #{$option}px !important;}
}

// ▽ マージンボトム
// ----------------------------------------
$options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 86 90 95 100 114 116 152 172 226 256 264;
@each $option in $options {
  .m_marginBottom#{$option} { margin-bottom: #{$option}px !important;}
}

// ▽ パディングトップ
// ----------------------------------------
$options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
@each $option in $options {
  .m_paddingTop#{$option} { padding-top: #{$option}px !important;}
}

// ▽ パディングンボトム
// ----------------------------------------
$options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100 225;
@each $option in $options {
  .m_paddingBottom#{$option} { padding-bottom: #{$option}px !important;}
}


// ------------------------------------------------------------
// - ▼ SP版
// ------------------------------------------------------------

@media (max-width: 640px) {
  // ▽ マージントップ
  // ----------------------------------------
  $options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100 120;
  @each $option in $options {
    .m_sp_marginTop#{$option} { margin-top: #{$option}px !important;}
  }

  // ▽ マージンボトム
  // ----------------------------------------
  $options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
  @each $option in $options {
    .m_sp_marginBottom#{$option} { margin-bottom: #{$option}px !important;}
  }

  // ▽ パディングトップ
  // ----------------------------------------
  $options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
  @each $option in $options {
    .m_sp_paddingTop#{$option} { padding-top: #{$option}px !important;}
  }

  // ▽ パディングンボトム
  // ----------------------------------------
  $options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
  @each $option in $options {
    .m_sp_paddingBottom#{$option} { padding-bottom: #{$option}px !important;}
  }
}


// ------------------------------------------------------------
// - ▼ SP mini 版
// ------------------------------------------------------------

@media (max-width: 480px) {
  // ▽ マージントップ
  // ----------------------------------------
  $options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
  @each $option in $options {
    .m_xs_marginTop#{$option} { margin-top: #{$option}px !important;}
  }

  // ▽ マージンボトム
  // ----------------------------------------
  $options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
  @each $option in $options {
    .m_xs_marginBottom#{$option} { margin-bottom: #{$option}px !important;}
  }

  // ▽ パディングトップ
  // ----------------------------------------
  $options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
  @each $option in $options {
    .m_xs_paddingTop#{$option} { padding-top: #{$option}px !important;}
  }

  // ▽ パディングンボトム
  // ----------------------------------------
  $options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
  @each $option in $options {
    .m_xs_paddingBottom#{$option} { padding-bottom: #{$option}px !important;}
  }
}


// ------------------------------------------------------------
// - ▼ RSP
// ------------------------------------------------------------

// ▽ マージントップ
// ----------------------------------------
$options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100 126 130 150;
@each $option in $options {
  .m_rsp_marginTop#{$option} { margin-top: #{$option}px !important;}
}
@media (max-width: 640px) {
  @each $option in $options {
    $mod: $option % 2;
    $half: null;

    // 2で割ったあまりが存在するならば元の値に+5
    @if $mod == 1 {
      $half: ($option + 5) / 2;
    } @else {
      $half: $option / 2;
    }

    .m_rsp_marginTop#{$option} { margin-top: #{$half}px !important;}
  }
}

// ▽ マージンボトム
// ----------------------------------------
$options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
@each $option in $options {
  .m_rsp_marginBottom#{$option} { margin-bottom: #{$option}px !important;}
}
@media (max-width: 640px) {
  @each $option in $options {
    $mod: $option % 2;
    $half: null;

    // 2で割ったあまりが存在するならば元の値に+5
    @if $mod == 1 {
      $half: ($option + 5) / 2;
    } @else {
      $half: $option / 2;
    }

    .m_rsp_marginBotom#{$option} { margin-bottom: #{$half}px !important;}
  }
}

// ▽ パディングトップ
// ----------------------------------------
$options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100 130 150;
@each $option in $options {
  .m_rsp_paddingTop#{$option} { padding-top: #{$option}px !important;}
}
@media (max-width: 640px) {
  @each $option in $options {
    $mod: $option % 2;
    $half: null;

    // 2で割ったあまりが存在するならば元の値に+5
    @if $mod == 1 {
      $half: ($option + 5) / 2;
    } @else {
      $half: $option / 2;
    }

    .m_rsp_paddingTop#{$option} { padding-top: #{$half}px !important;}
  }
}

// ▽ パディングンボトム
// ----------------------------------------
$options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100 140 150 200;
@each $option in $options {
  .m_rsp_paddingBottom#{$option} { padding-bottom: #{$option}px !important;}
}
@media (max-width: 640px) {
  @each $option in $options {
    $mod: $option % 2;
    $half: null;

    // 2で割ったあまりが存在するならば元の値に+5
    @if $mod == 1 {
      $half: ($option + 5) / 2;
    } @else {
      $half: $option / 2;
    }

    .m_rsp_paddingBotom#{$option} { padding-bottom: #{$half}px !important;}
  }
}


// ------------------------------------------------------------
// - ▼ vw (640ベース)
// ------------------------------------------------------------

@media (max-width: 640px) {
  // ▽ マージントップ
  // ----------------------------------------
  $options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
  @each $option in $options {
    .m_sp_marginTop#{$option}vw { margin-top: vw($option, 640) !important;}
  }

  // ▽ マージンボトム
  // ----------------------------------------
  $options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
  @each $option in $options {
    .m_sp_marginBottom#{$option}vw { margin-bottom: vw($option, 640) !important;}
  }

  // ▽ パディングトップ
  // ----------------------------------------
  $options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
  @each $option in $options {
    .m_sp_paddingTop#{$option}vw { padding-top: vw($option, 640) !important;}
  }

  // ▽ パディングンボトム
  // ----------------------------------------
  $options: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
  @each $option in $options {
    .m_sp_paddingBottom#{$option}vw { padding-bottom: vw($option, 640) !important;}
  }
}


/* ================================================================================

▼ 表示切り替え

================================================================================ */
.m_show_1400 { display: none !important; }
.m_show_1024 { display: none !important; }
.m_show_768  { display : none !important; }
.m_show_640  { display : none !important; }
.m_show_440  { display : none !important; }
.m_show_375  { display : none !important; }
.m_show_374  { display:  none !important; }

@media (max-width: 1400px) {
  .m_hide_1400 { display: none !important; }
  .m_show_1400 { display: block !important; }
}
@media (max-width: 1024px) {
  .m_hide_1024 { display: none !important; }
  .m_show_1024 { display: block !important; }
}
@media (max-width: 768px) {
  .m_hide_768 { display: none !important; }
  .m_show_768 { display: block !important; }
}
@media (max-width: 640px) {
  .m_hide_640 { display: none !important; }
  .m_show_640 { display: block !important; }
}
@media (max-width: 440px) {
  .m_hide_440 { display: none !important; }
  .m_show_440 { display: block !important; }
}
@media (max-width: 375px) {
  .m_hide_375 { display: none !important; }
  .m_show_375 { display: block !important; }
}
@media (max-width: 374px) {
  .m_hide_374 { display: none !important; }
  .m_show_374 { display: block !important; }
}

.m_display_none {
  display: none;
}

// ------------------------------------------------------------
// - ▼ 便利系
// ------------------------------------------------------------

// ▽ clearfix
// ----------------------------------------
.m_clearfix,
.m_clear {
  &::after {
    @include clearfix;
  }
}

// ▽ display 操作
// ----------------------------------------
.m_display_block {
  display: block !important;
}

.m_display_inline_block {
  display: inline-block !important;
}

.m_display_inline {
  display: inline !important;
}


// ------------------------------------------------------------
// - ▼ テキスト系
// ------------------------------------------------------------

// ▽ 一般的なテキスト
// ----------------------------------------
.m_text {
  @include text();
}


// ▽ PC版のフォントサイズ
// ----------------------------------------
$sizes: 32, 30, 28, 26, 24, 22, 20, 18, 16, 15, 14, 13, 12 ,11, 10;

@each $size in $sizes {
  .m_text#{$size} {
    font-size: #{$size}px !important;
  }
}

// ▽ SP版のフォントサイズ
// ----------------------------------------
$sizes: 32, 30, 28, 26, 24, 22, 20, 18, 16, 15, 14, 13, 12, 11 ,10;

@each $size in $sizes {
  .m_sp_text#{$size} {
    @media (max-width: 640px) {
      font-size: #{$size}px !important;
    }
  }
}

// ▽ SP版で2px小さくなるフォントサイズ
// ----------------------------------------
$sizes: 26, 24, 22, 20, 18, 16, 15, 14, 13;

@each $size in $sizes {
  $rsp: $size - 2;
  .m_rsp_text#{$size} {
    font-size: #{$size}px !important;
    @media (max-width: 640px) {
      font-size: #{$rsp}px !important;
    }
  }
}

// ▽ 640pxを基準としてVWでの指定
// ----------------------------------------
$sizes: 22, 20, 18, 16, 15, 14, 13;

@each $size in $sizes {
  .m_vw_text#{$size} {
    @media (max-width: 640px) {
      font-size: vw($size, 640) !important;
    }
  }
}


// ▽ パーセンテージ
// ----------------------------------------
$sizes: 200, 150, 130, 115, 100, 85, 75, 70;
@each $size in $sizes {
  .m_text#{$size}p {
    font-size: $size * 1%;
  }
}


// ▽ パーセンテージ（SP版）
// ----------------------------------------
$sizes: 200, 150, 130, 115, 100, 85, 75, 70;
@media (max-width: 640px) {
  @each $size in $sizes {
    .m_sp_text#{$size}p {
      font-size: $size * 1%;
    }
  }
}



// ------------------------------------------------------------
// - ▼ 均等割付
// ------------------------------------------------------------
.m_text_justify {
  // text-align: justify;
  // word-break: break-all;
}



// ▽ 簡易装飾
// ----------------------------------------
.m_italic {
  font-style: italic !important;
}

.m_bold {
  font-weight: bold !important;
}

.m_weight_normal {
  font-weight: normal !important;
}

.m_underline {
  text-decoration: underline !important;
}


// ▽ letter spacing
// ----------------------------------------
.m_letter_little {
  letter-spacing: 0.1em;
}


// ▽ テキストカラー変更
// ----------------------------------------
.m_color_white {
  color: #fff !important;
}
.m_color_red {
  color: #f00 !important;
}
.m_color_black {
  color: #000 !important;
}


// ▽ 注釈などの1文字分を頭出しする
// ----------------------------------------
.m_text_note {
  display: flex;
  align-items: baseline;
  > ._label {
    white-space: nowrap;
  }
  > ._text {
    flex: 1 1 auto;
  }

  &.is_align_right {
    justify-content: flex-end;
  }
}


// ▽ 位置揃え
// ----------------------------------------
.m_align_left {
  text-align: left !important;
}
.m_align_center {
  text-align: center !important;
}
.m_align_right {
  text-align: right !important;
}

// ▽ SP版の時 ▽
.m_sp_align_left {
  @media (max-width: 640px) {
    text-align: left !important;
  }
}
.m_sp_align_center {
  @media (max-width: 640px) {
    text-align: center !important;
  }
}


// ▽ テキストの折り返し位置
// ----------------------------------------
.m_nowrap {
  white-space: nowrap;
}

.m_break_all {
  word-break: break-all;;
}


// ▽ vertical-align
// ----------------------------------------
.m_vertical_super {
  vertical-align: super;
}
.m_vertical_bottom {
  vertical-align: bottom!important;
}

// ▽ font-family
// ----------------------------------------
.m_font_en {
font-family: $font_family_en;
}

// ------------------------------------------------------------
// - ▼ 背景色
// ------------------------------------------------------------
.m_background_gray {
  background: #F2F2F2;
}

.m_background_orange {
  background: #efac29;
}


// ------------------------------------------------------------
// - ▼ ボーダー
// ------------------------------------------------------------
.m_horizon_gray {
  display: block;
  width: auto;
  margin-top: 0;
  margin-bottom: 0;
  background: #d3d3d3;
}


// ------------------------------------------------------------
// - ▼ 背景色のついたボックス
// ------------------------------------------------------------
.m_gray_box {
  background: #f5f5f5;
  padding: 40px 30px;
  @media (max-width: 640px) {
    padding: 20px 15px;
  }
}

// ------------------------------------------------------------
// - ▼ 注釈のアイコン頭出し
// ------------------------------------------------------------
.m_note_icon {
  display: flex;
  > ._icon {
    white-space: nowrap;
  }
  > ._text {
    @include flex();
  }
}


// ------------------------------------------------------------
// - ▼ チェックアイコンのついたテキスト
// ------------------------------------------------------------
.m_check_text {
  position: relative;
  padding-left: 29px;
  @media (max-width: 640px) {
    padding-left: 23px;
  }
  &::before {
    content: '';
    background: url(/_materials/images/common/parts/icon_check.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 21px;
    position: absolute;
    left: 0;
    top: 0.75em;
    transform: translateY(-50%);
    @media (max-width: 640px) {
      width: 18px;
    }
  }

  // 使用するテキストのline-heightが1.8の場合
  &.is_height_16 {
    &::before {
      top: 0.8em;
    }
  }
}


// ------------------------------------------------------------
// - ▼ 矢印付きのリンク
// ------------------------------------------------------------
.m_link_arrow {
  display: inline-block;
  position: relative;
  padding-left: 13px;
  overflow: hidden;
  font-size: 16px;
  @media (max-width: 640px) {
    font-size: 14px;
  }
  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-top: solid 1px #333;
    border-right: solid 1px #333;
    // transform-origin: right top;
    transform: translate(-50%, -50%) rotate(45deg);
    position: absolute;
    top: 50%;
    left: 0;
  }

  > ._underline {
    border-bottom: solid 1px #333;
  }

  &:hover {
    $color: darken($color_orange, 5%);
    @media (min-width: 769px) {
      color: $color;
      &::before {
        border-color: $color;
      }

      > ._underline {
        border-bottom-color: $color;
      }
    }
  }
}


.m_link_arrow_large {
  display: inline-block;
  position: relative;
  padding-left: 13px;
  overflow: hidden;
  font-size: 18px;
  @media (max-width: 640px) {
    font-size: 16px;
  }
  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-top: solid 1px #333;
    border-right: solid 1px #333;
    // transform-origin: right top;
    transform: translate(-50%, -50%) rotate(45deg);
    position: absolute;
    top: 50%;
    left: 0;
  }
  &::after {
    content: '';
    display: block;
    width: 0%;
    height: 1px;
    transition: width 0.3s ease;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &:hover {
    $color: darken($color_orange, 5%);
    @media (min-width: 769px) {
      color: $color;
      &::before {
        border-color: $color;
      }
      &::after {
        background: $color;
        width: 100%;
      }
    }
  }
}

// ------------------------------------------------------------
// - ▼ テキストリンク
// ------------------------------------------------------------
.m_link_text {
  color: saturate($color_orange, 5%);
  border-bottom: solid 1px saturate($color_orange, 5%);
  &:hover {
    opacity: 0.6;
  }
}
.m_link_text_white {
  color: #fff;
  border-bottom: solid 1px #fff;
  &:hover {
    opacity: 0.6;
  }
}
.m_link_text_black {
  color: #333;
  border-bottom: solid 1px #333;
  &:hover {
    opacity: 0.6;
  }
}

// ------------------------------------------------------------
// - ▼ 電話番号のリンク
// ------------------------------------------------------------
.m_tel_link {
  color: #333;
  @media (min-width: 641px) {
    pointer-events: none;
  }
  @media (max-width: 640px) {
    border-bottom: solid 1px #333;
  }
}

.m_tel_link_white {
  color: #fff;
  @media (min-width: 641px) {
    pointer-events: none;
  }
  @media (max-width: 640px) {
    border-bottom: solid 1px #fff;
  }
}




// ------------------------------------------------------------
// - ▼ テキスト横のアロー
// ------------------------------------------------------------
.m_arrow {
  padding-right: 20px;
  position: relative;
  display: inline-block;
  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: solid 2px #333;
    border-right: solid 2px #333;
    transform-origin: right top;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    right: 0;
  }
}





// ------------------------------------------------------------
// - ▼ Youtube
// ------------------------------------------------------------
.m_youtube {
  max-width: 730px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  &::before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }
  > iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}


// ------------------------------------------------------------
// - ▼ 外部ページからのアンカー
// ------------------------------------------------------------
.m_anchor_spacer {
  display: flex;
  align-items: flex-end;
  height: 0;
  .m_anchor_spacer_inner {
    display: block;
    height: 100px;
    width: 0;
    visibility: hidden;
    @media (max-width: 480px) {
      height: 80px;
    }
  }
  &.is_tall {
    .m_anchor_spacer_inner {
      height: 120px;
      @media (max-width: 480px) {
        height: 100px;
      }
  }
  }
}


// ------------------------------------------------------------
// - ▼ マウスホバー時の透過アニメーション
// ------------------------------------------------------------
.m_hover_alpha {
  display: block;
  transition: opacity 0.3s ease;
  &:hover {
    @media (min-width: 769px) {
      opacity: 0.8;
    }
  }
}



//[EOT]
