// ================================================================================
//
// ▼ layout.scss
//
//================================================================================
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  @media (max-width: 640px) {
    font-size: 14px;
  }
}

body {
  // letter-spacing: 0.05em;
  // font-family: 'Open Sans', 'Hiragino Kaku Gothic ProN', "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, Meiryo, "メイリオ", sans-serif;
  font-family: $font_family_gothic;
  line-height: 1.4;
  background-color: #fff;
  color: #333;
  max-width: 2000px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  font-size: 1rem;
  padding-top: 80px;
  @media (max-width: 640px) {
    padding-top: 70px;
    padding-bottom: 65px;
  }
  @media (max-width: 375px) {
    padding-top: 60px;
  }

  &.IS_BODY_FIXED {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

a {
  color: initial;
  color: #333;
  text-decoration: none;
  outline: none;
}


ul {
  list-style: none;
}


img {
  width: auto;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

sup {
  font-size: 50%;
  vertical-align: top;
  vertical-align: text-top;
}


// EOT
