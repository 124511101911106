// ================================================================================
//
// ▼ footer.scss
//
//================================================================================
.l_pagetop {
  text-align: center;
  font-size: 0;
  margin-top: 150px;
  margin-bottom: 150px;
  @media (max-width: 640px) {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
.l_pagetop_btn {
  padding-top: 45px;
  display: inline-block;
  font-size: 15px;
  position: relative;
  line-height: 1;
  overflow: hidden;
  cursor: pointer;
  @media (max-width: 640px) {
    padding-top: 38px;
    font-size: 13px;
  }
  &::before {
    $size: 48px;

    content: '';
    display: block;
    width: $size;
    height: $size;
    border-top: solid 1px #333;
    border-left: solid 1px #333;
    transform-origin: left top;
    transform: rotate(45deg);
    position: absolute;
    top: 5px;
    left: 50%;
    @media (min-width: 769px) {
      transition: top 0.3s ease;
    }
    @media (max-width: 640px) {
      $size: 36px;
      width: $size;
      height: $size;

    }
  }

  @media (min-width: 769px) {
    &:hover {
      &::before {
        top: 0;
      }
    }
  }
}



// ------------------------------------------------------------
// - ▼ パンくず
// ------------------------------------------------------------
.l_breadcrumb {
  border-top: solid 1px #ccc;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;

  .l_breadcrumb_inner {
    max-width: 1400px;
    // box-sizing: content-box;
    margin-right: auto;
    margin-left: auto;
    @include inner_padding();
  }
}
.l_breadcrumb_list {
  font-size: 0;
  margin-right: -10px;
  // margin-top: -10px;
  .l_breadcrumb_list_item {
    display: inline;
    position: relative;
    margin-right: 8px;
    // margin-top: 10px;
    font-size: 14px;
    @media (max-width: 480px) {
      font-size: 12px;
    }

    + .l_breadcrumb_list_item {
      &::before {
        content: '＞';
        margin-right: 8px;
        font-size: 80%;
        transform: translateY(-0.2em);
      }
    }

    .l_breadcrumb_title {
      line-height: 1.8;
    }
    &.is_link_none {
      .l_breadcrumb_title {
        color: #8e8e8e;
      }
    }
  }
}
.l_breadcrumb_link {
  display: inline;
  &:hover {
    .l_breadcrumb_title {
      border-bottom: solid 1px #333;
    }
  }
}

// ------------------------------------------------------------
// - ▼ footerサイトマップ
// ------------------------------------------------------------

.l_sitemap {
  background: #d5d5d5;
  padding-top: 55px;
  padding-bottom: 60px;
}
.l_sitemap_inner {
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 1024px) {
    display: block;
    max-width: 680px;
    margin-right: auto;
    margin-left: auto;
  }
}

.l_sitemap_contents {
  @include flex();
  display: flex;
  justify-content: space-around;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
  @media (max-width: 640px) {
    display: block;
    // padding-left: 15px;
    // padding-right: 15px;
  }
}
.l_sitemap_contents_column {
  // width: calc(33.33% - 12px);
  // margin-left: 18px;
  @media (max-width: 640px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  &:first-child {
    margin-left: 0;
  }
  + .l_sitemap_contents_column {
    @media (max-width: 640px) {
      border-top: solid 1px #6d6d6d;
      padding-top: 15px;
      margin-top: 15px;
    }
  }
}
.l_sitemap_contents_title {
  font-size: 16px;
  // color: #３３３;
  font-weight: bold;
  letter-spacing: 0.1em;
  @media (max-width: 640px) {
    font-size: 18px;
  }
  @media (max-width: 375px) {
    font-size: 16px;
  }
}

// カテゴリーのリンク
.l_sitemap_category_link {
  display: inline-block;
  position: relative;
  font-size: 0;
  @media (max-width: 640px) {
    display: block;
  }
  > ._text {
    display: inline-block;
    padding-right: 18px;
    font-size: 16px;
    // color: #fff;
    @media (max-width: 375px) {
      font-size: 16px;
    }
    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-top: solid 2px #333;
      border-right: solid 2px #333;
      transform-origin: right top;
      position: absolute;
      top: 50%;
      right: 0;
      transform: rotate(45deg);
      @media (max-width: 640px) {
        width: 10px;
        height: 10px;
        right: 1px;
      }
    }
  }
  &::after {
    content: '';
    display: block;
    width:0;
    left: 0;
    bottom: 0;
    height: 1px;
    background: #333;
    z-index: 1;
    @media (min-width: 769px) {
      transition: width 0.3s ease;
    }
  }
  &:hover {
    @media (min-width: 769px) {
      &::after {
      width: 100%;
      }
    }
  }
}

.l_sitemap_achordion_btn {
  @media (max-width: 640px) {
    padding-right: 30px;
    position: relative;
    cursor: pointer;
    $x: 12px;
    $y: 2px;
    &::before,
    &::after {
      content: '';
      display: block;
      background: #333;
      position: absolute;
      top: 50%;
    }
    &::before { // 縦
      width: $y;
      height: $x;
      // margin-top: $x / -2;
      transform: translate3d(0, -50%, 0);
      right: ($x / 2) - ($y / 2);
      transition: height 0.3s ease;
    }
    &::after { // 横
      width: $x;
      height: $y;
      margin-top: $y / -2;
      right: 0;
    }

    &.is_active {
      &::before { // 縦
        height: 0;
      }
    }
  }
}

.l_sitemap_achordion_body {
  @media (max-width: 640px) {
    display: none;
  }
}

.l_sitemap_contents_list {
  padding-top: 10px;
  @media (max-width: 640px) {
    padding-top: 20px;
  }
}
.l_sitemap_contents_list_item {
  + .l_sitemap_contents_list_item {
    margin-top: 5px;
    @media (max-width: 640px) {
      margin-top: 10px;
    }
  }
}



.l_sitemap_link {
  display: inline-block;
  position: relative;
  font-size: 0;
  padding-left: 11px;
  > ._text {
    display: inline-block;
    font-size: 13px;
    // color: #fff;
    @media (max-width: 640px) {
      font-size: 14px;
    }
  }
  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-top: solid 1px #333;
    border-right: solid 1px #333;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    content: '';
    display: block;
    width:0;
    left: 0;
    bottom: 0;
    height: 1px;
    background: #333;
    z-index: 1;
    position: absolute;
    @media (min-width: 769px) {
      transition: width 0.3s ease;
    }
  }
  &:hover {
    @media (min-width: 769px) {
      &::after {
      width: 100%;
      }
    }
  }
}




// ------------------------------------------------------------
// - ▼ サイトマップ右側
// ------------------------------------------------------------
.l_sitemap_information {
  width: 450px;
  border-left: solid 1px #6d6d6d;
  padding-left: 50px;
  margin-left: 50px;
  // display: flex;
  // justify-content: space-between;
  @media (max-width: 1024px) {
    display: block;
    width: auto;
    border-left: none;
    padding-left: 0;
    margin-left: 0;
    border-top: solid 1px #6d6d6d;
    padding-top: 25px;
    margin-top: 25px;
  }
  @media (max-width: 640px) {
    padding-top: 0;
    margin-top: 15px;
  }
}


// ------------------------------------------------------------
// - ▼ サイトマップ右側のメニュー
// ------------------------------------------------------------
.l_sitemap_information_list {
  overflow: hidden;
  .l_sitemap_information_list_inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -10px;
    @media (max-width: 1024px) {
      margin-left: 0;
      margin-right: 0;
      justify-content: center;
    }
    @media (max-width: 640px) {
      margin-top: 0;
    }
  }
  .l_sitemap_information_list_item {
    width: calc(50% - 10px);
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    @media (max-width: 1024px) {
      vertical-align: top;
      display: inline-block;
      width: auto;
      position: relative;
      padding-right: 11px;
      margin-left: 0px;
      margin-right: 10px;
    }
    @media (max-width: 640px) {
      display: block;
      padding-right: 0;
      width: 50%;
      @include flex(1, 1, 50%);
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      padding-right: 0;
      border-bottom: solid 1px #6d6d6d;

      // 偶数番目にbourder-left
      &:nth-child(even) {
        border-left: solid 1px #6d6d6d;
      }
      // 最後が奇数ならばwidth 100%
      &:last-child:nth-child(odd) {
        width: 100%;
      }
    }

    &::after {
      @media (max-width: 1024px) {
        content: '';
        display: block;
        height: 14px;
        width: 1px;
        background: #333;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      @media (max-width: 640px) {
        display: none;
      }
    }
    &:last-child {
      padding-right: 0;
      &::after {
        display: none;
      }
    }
  }
}

.l_sitemap_information_link {
  display: inline-block;
  position: relative;
  font-size: 13px;
  padding-left: 13px;
  @media (max-width: 1024px) {
    padding-left: 0;
  }
  // color: #dddddd;
  @media (max-width: 640px) {
    text-align: left;
    display: flex;
    padding-left: 10px;
    padding-right: 30px;
    position: relative;
    height: 40px;
    align-items: center;
    font-size: 15px;
  }
  @media (max-width: 375px) {
    font-size: 13px;
  }
  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    background: #333;
    @media (max-width: 1024px) {
      display: none;
    }
    @media (max-width: 640px) {
      display: block;
      width: 8px;
      height: 8px;
      background: none;
      border-top: solid 1px #333;
      border-right: solid 1px #333;
      transform-origin: right top;
      transform: rotate(45deg);
      left: auto;
      right: 10px;
    }
  }
  &::after {
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    background: #dddddd;
    bottom: 0;
    left: 0;
    width: 0%;
    z-index: 1;
    @media (min-width: 769px) {
      transition: width 0.3s ease;
    }
  }
  &:hover {
    @media (min-width: 769px) {
      &::after {
        width: 100%;
      }
    }
  }
}




.l_sitemap_logo{
  margin-top: 25px;
  align-self: center;
  width: 220px;

  @media (max-width: 1024px) {
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
  }
  .l_sitemap_logo_item {
    display: flex;
    @media (max-width: 880px) {
      justify-content: center;
    }
    + .l_sitemap_logo_item {
      margin-top: 15px;
    }
  }
}


.l_sitemap_8ing {
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  font-weight: bold;
  > ._logo {
    width: 70px;
    @include flex();
    display: block;
    margin-right: 10px;
    // position: relative;
    // &::before {
    //   content: '';
    //   display: block;
    //   padding-top: 57.29%;
    .m_svg_object {
      path,rect,g {
        fill: #333 !important;
      }
    }
    // }
    > ._svg {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }
  > ._text {
    white-space: nowrap;
    padding-bottom: 5px;
    color: #333;
  }
}

.l_sitemap_colopl {
  display: block;
  width: 100%;
  // max-width: 255px;
  .m_svg_object {
    fill: #f00 !important;
    path,rect,g {
      fill: #333 !important;
    }
  }
}


// ------------------------------------------------------------
// - ▼ コピーライト
// ------------------------------------------------------------
.l_copyright {
  font-size: 12px;
  text-align: center;
  padding: 20px 10px 30px;
  @media (max-width: 640px) {
    font-size: 11px;
  }
}


// ------------------------------------------------------------
// - ▼ SP版の追従ボタン
// ------------------------------------------------------------
.l_sp_conversion {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: #fff;
  @media (max-width: 640px) {
    display: block;
  }
  .l_sp_conversion_inner {
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }

  .l_sp_conversion_item {
    width: calc(50% - 5px);
  }
}






// EOT
