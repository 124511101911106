/*
CMSのページ毎のCSS記述ファイル
*/


// ------------------------------------------------------------
// - ▼ トップページ
// ------------------------------------------------------------
.this__index {
  .m_footer_front_only {
    display: block;
  }
}

// ------------------------------------------------------------
// - ▼ 8ingだからできること
// ------------------------------------------------------------
.this__8ing_project__index {
  background: #f2f2f2;
}

// [-EOF-]
