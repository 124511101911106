@charset "UTF-8";
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/_materials/font/opensans.woff2) format("woff2");
  font-display: swap;
}

.m_inner_1400, .m_inner_1200, .m_inner_1180, .m_inner_1020, .m_inner_1000, .m_inner_900, .m_inner_860, .m_inner_800, .m_inner_690, .m_inner_padding {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

@media (max-width: 640px) {
  .m_inner_1400, .m_inner_1200, .m_inner_1180, .m_inner_1020, .m_inner_1000, .m_inner_900, .m_inner_860, .m_inner_800, .m_inner_690, .m_inner_padding {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

@keyframes fade_in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade_out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slide_up {
  from {
    transform: translate3d(0, 30px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bar_01 {
  from {
  }
  to {
    top: 50%;
  }
}

@keyframes navigation_close {
  to {
    filter: blur(100px);
    opacity: 0;
  }
}

@keyframes slide_flip_left {
  from {
    transform: rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
  to {
    transform: rotate3d(0, 1, 0, 0deg);
    opacity: 1;
  }
}

@keyframes slide_flip_right {
  from {
    transform: rotate3d(0, 1, 0, -90deg);
    opacity: 0;
  }
  to {
    transform: rotate3d(0, 1, 0, 0deg);
    opacity: 1;
  }
}

@keyframes fadeUp {
  0% {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
  75% {
    transform: translate3d(0, -5px, 0);
  }
  100% {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
  }
}

@keyframes fadeUp {
  0% {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
  75% {
    transform: translate3d(0, -5px, 0);
  }
  100% {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
  }
}

/*
問題：
５秒ごとに繰り返すアニメーションを実装する。
アニメーションの内容は0.3秒かけて フェードインするものとする。
*/
@keyframes popUp {
  0% {
    transform: translate3d(0, 0, 0);
  }
  8.33333% {
    transform: translate3d(0, -5px, 0);
  }
  16.66667% {
    transform: translate3d(0, 0, 0);
  }
}

.m_editor_container > *:first-child {
  margin-top: 0 !important;
}

.m_editor_container > *:last-child {
  margin-bottom: 0 !important;
}

.m_editor_container h1 {
  font-size: 48px;
  text-align: center;
  font-weight: normal;
  margin-top: 100px;
  margin-bottom: 35px;
}

@media (max-width: 640px) {
  .m_editor_container h1 {
    font-size: 42px;
  }
}

@media (max-width: 375px) {
  .m_editor_container h1 {
    font-size: 36px;
  }
}

@media (max-width: 640px) {
  .m_editor_container h1 {
    margin-top: 60px;
    margin-bottom: 20px;
  }
}

.m_editor_container h2 {
  font-size: 34px;
  margin-top: 80px;
  margin-bottom: 20px;
}

@media (max-width: 640px) {
  .m_editor_container h2 {
    font-size: 28px;
    margin-top: 50px;
    margin-bottom: 15px;
  }
}

@media (max-width: 375px) {
  .m_editor_container h2 {
    font-size: 24px;
  }
}

.m_editor_container h3, .m_editor_container h4, .m_editor_container h5, .m_editor_container h6 {
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 15px;
  line-height: 1.8;
}

@media (max-width: 640px) {
  .m_editor_container h3, .m_editor_container h4, .m_editor_container h5, .m_editor_container h6 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.m_editor_container h2 + h3 {
  margin-top: 20px;
}

@media (max-width: 640px) {
  .m_editor_container h2 + h3 {
    margin-top: 15px;
  }
}

.m_editor_container p {
  margin-bottom: 10px;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.6;
  line-height: 1.8;
}

@media (max-width: 640px) {
  .m_editor_container p {
    font-size: 14px;
  }
}

@media (max-width: 640px) {
  .m_editor_container p {
    margin-bottom: 5px;
  }
}

.m_editor_container ul {
  list-style: disc;
  margin-left: 2em;
  margin-bottom: 20px;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.6;
}

@media (max-width: 640px) {
  .m_editor_container ul {
    font-size: 14px;
  }
}

@media (max-width: 640px) {
  .m_editor_container ul {
    margin-bottom: 15px;
  }
}

.m_editor_container ol {
  margin-bottom: 20px;
  margin-left: 2em;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.6;
}

@media (max-width: 640px) {
  .m_editor_container ol {
    font-size: 14px;
  }
}

@media (max-width: 640px) {
  .m_editor_container ol {
    margin-bottom: 15px;
  }
}

.m_editor_container img.aligncenter,
.m_editor_container img.alignleft,
.m_editor_container img.alignright {
  display: block;
}

.m_editor_container img.aligncenter {
  margin-right: auto;
  margin-left: auto;
}

.m_editor_container img.alignleft {
  margin-left: 0;
  margin-right: auto;
}

.m_editor_container img.alignright {
  margin-right: 0;
  margin-left: auto;
}

.m_editor_container blockquote {
  margin: 0 0 28px;
  padding: 30px 25px 35px;
  border: 1px #e5e5e5 solid;
  background: #fafafa;
}

.m_editor_container blockquote *:last-child {
  margin-bottom: 0 !important;
}

.m_editor_container a {
  color: #fbb013;
  border-bottom: solid 1px #fbb013;
}

.m_editor_container a[target="_blank"] {
  transition: opacity 0.3s ease;
}

.m_editor_container a[target="_blank"]::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAALCAYAAACgR9dcAAAAUklEQVQokWP8//8/AwMDA8OvizUQBing6zrJ/////2f4eaH6/////xmIxT8vVP9nItk2JDCqmUTAgswhOa5h8UwOZmFgYGD4tl6K5NTFFfiMEQBJTGdN5mEBfwAAAABJRU5ErkJggg==);
  margin-left: 2px;
  margin-right: 2px;
}

@media (min-width: 769px) {
  .m_editor_container a[target="_blank"]:hover {
    opacity: 0.7;
  }
}

.m_editor_container .movie-wrap {
  display: block;
  position: relative;
}

.m_editor_container .movie-wrap::before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.m_editor_container .movie-wrap > iframe {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
}

.m_editor_container_simple > *:first-child {
  margin-top: 0 !important;
}

.m_editor_container_simple > *:last-child {
  margin-bottom: 0 !important;
}

.m_editor_container_simple h1,
.m_editor_container_simple h2,
.m_editor_container_simple h3,
.m_editor_container_simple h4,
.m_editor_container_simple h5,
.m_editor_container_simple h6 {
  margin-bottom: 20px;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.6;
  font-size: 18px;
}

@media (max-width: 640px) {
  .m_editor_container_simple h1,
  .m_editor_container_simple h2,
  .m_editor_container_simple h3,
  .m_editor_container_simple h4,
  .m_editor_container_simple h5,
  .m_editor_container_simple h6 {
    font-size: 14px;
  }
}

@media (max-width: 640px) {
  .m_editor_container_simple h1,
  .m_editor_container_simple h2,
  .m_editor_container_simple h3,
  .m_editor_container_simple h4,
  .m_editor_container_simple h5,
  .m_editor_container_simple h6 {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.m_editor_container_simple p {
  margin-bottom: 20px;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.6;
}

@media (max-width: 640px) {
  .m_editor_container_simple p {
    font-size: 14px;
  }
}

@media (max-width: 640px) {
  .m_editor_container_simple p {
    margin-bottom: 15px;
  }
}

.m_editor_container_simple ul {
  list-style: disc;
  margin-left: 2em;
  margin-bottom: 20px;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.6;
}

@media (max-width: 640px) {
  .m_editor_container_simple ul {
    font-size: 14px;
  }
}

@media (max-width: 640px) {
  .m_editor_container_simple ul {
    margin-bottom: 15px;
  }
}

.m_editor_container_simple ol {
  margin-bottom: 20px;
  margin-left: 2em;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.6;
}

@media (max-width: 640px) {
  .m_editor_container_simple ol {
    font-size: 14px;
  }
}

@media (max-width: 640px) {
  .m_editor_container_simple ol {
    margin-bottom: 15px;
  }
}

.m_editor_container_simple hr {
  border-top: dotted 2px #ccc;
}

.m_editor_container_simple img.aligncenter,
.m_editor_container_simple img.alignleft,
.m_editor_container_simple img.alignright {
  display: block;
}

.m_editor_container_simple img.aligncenter {
  margin-right: auto;
  margin-left: auto;
}

.m_editor_container_simple img.alignleft {
  margin-left: 0;
  margin-right: auto;
}

.m_editor_container_simple img.alignright {
  margin-right: 0;
  margin-left: auto;
}

.m_editor_container_simple blockquote {
  margin: 0 0 28px;
  padding: 30px 25px 35px;
  border: 1px #e5e5e5 solid;
  background: #fafafa;
}

.m_editor_container_simple blockquote *:last-child {
  margin-bottom: 0 !important;
}

.m_editor_container_simple a {
  color: #333;
  border-bottom: solid 1px #333;
}

.m_editor_container_simple .movie-wrap {
  display: block;
  position: relative;
}

.m_editor_container_simple .movie-wrap::before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.m_editor_container_simple .movie-wrap > iframe {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
}

.m_inner_1400, .m_inner_1200, .m_inner_1180, .m_inner_1020, .m_inner_1000, .m_inner_900, .m_inner_860, .m_inner_800, .m_inner_690 {
  margin-right: auto;
  margin-left: auto;
}

.m_inner_1400 {
  max-width: 1400px !important;
  box-sizing: content-box !important;
}

.m_inner_1200 {
  max-width: 1200px !important;
  box-sizing: content-box !important;
}

.m_inner_1180 {
  max-width: 1180px !important;
  box-sizing: content-box !important;
}

.m_inner_1020 {
  max-width: 1020px !important;
  box-sizing: content-box !important;
}

.m_inner_1000 {
  max-width: 1000px !important;
  box-sizing: content-box !important;
}

.m_inner_900 {
  max-width: 900px !important;
  box-sizing: content-box !important;
}

.m_inner_860 {
  max-width: 860px !important;
  box-sizing: content-box !important;
}

.m_inner_800 {
  max-width: 800px !important;
  box-sizing: content-box !important;
}

.m_inner_690 {
  max-width: 690px !important;
  box-sizing: content-box !important;
}

.m_inner_padding__none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (max-width: 640px) {
  .m_sp_inner_padding__none {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* ================================================================================

▼ マージン・パディング（上下のみ）

TODO:
0 ～ 100pxまでのマージンモジュールです。

m_marginTopXX  / m_marginBottomXX / m_paddingTopXX / m_paddingBottomXX
- 数字と同一のマージンが与えられます

m_sp_marginTopXX  / m_sp_marginBottomXX / m_sp_paddingTopXX / m_sp_paddingBottomXX
- window widthが640px以下の時に数字と同一のマージンが与えられます

m_rsp_marginTopXX  / m_rsp_marginBottomXX / m_rsp_paddingTopXX / m_rsp_paddingBottomXX
- window widthが641px以上の時は数字と同一のマージンが与えられます
- window widthが640px以下の時は数字とおよおそ半分のマージンが与えられます

m_rsp_marginTopXX  / m_rsp_marginBottomXX / m_rsp_paddingTopXX / m_rsp_paddingBottomXX
- window widthが640px以下の時に、640pxを基準とした場合の数字の間隔をもった間隔をVWで与えます。

================================================================================ */
.m_marginTop-145 {
  margin-top: -145px !important;
}

.m_marginTop0 {
  margin-top: 0px !important;
}

.m_marginTop5 {
  margin-top: 5px !important;
}

.m_marginTop10 {
  margin-top: 10px !important;
}

.m_marginTop15 {
  margin-top: 15px !important;
}

.m_marginTop20 {
  margin-top: 20px !important;
}

.m_marginTop25 {
  margin-top: 25px !important;
}

.m_marginTop30 {
  margin-top: 30px !important;
}

.m_marginTop35 {
  margin-top: 35px !important;
}

.m_marginTop40 {
  margin-top: 40px !important;
}

.m_marginTop45 {
  margin-top: 45px !important;
}

.m_marginTop50 {
  margin-top: 50px !important;
}

.m_marginTop55 {
  margin-top: 55px !important;
}

.m_marginTop60 {
  margin-top: 60px !important;
}

.m_marginTop65 {
  margin-top: 65px !important;
}

.m_marginTop70 {
  margin-top: 70px !important;
}

.m_marginTop75 {
  margin-top: 75px !important;
}

.m_marginTop80 {
  margin-top: 80px !important;
}

.m_marginTop85 {
  margin-top: 85px !important;
}

.m_marginTop90 {
  margin-top: 90px !important;
}

.m_marginTop95 {
  margin-top: 95px !important;
}

.m_marginTop100 {
  margin-top: 100px !important;
}

.m_marginTop110 {
  margin-top: 110px !important;
}

.m_marginTop120 {
  margin-top: 120px !important;
}

.m_marginTop130 {
  margin-top: 130px !important;
}

.m_marginTop140 {
  margin-top: 140px !important;
}

.m_marginTop150 {
  margin-top: 150px !important;
}

.m_marginTop160 {
  margin-top: 160px !important;
}

.m_marginTop170 {
  margin-top: 170px !important;
}

.m_marginTop180 {
  margin-top: 180px !important;
}

.m_marginBottom0 {
  margin-bottom: 0px !important;
}

.m_marginBottom5 {
  margin-bottom: 5px !important;
}

.m_marginBottom10 {
  margin-bottom: 10px !important;
}

.m_marginBottom15 {
  margin-bottom: 15px !important;
}

.m_marginBottom20 {
  margin-bottom: 20px !important;
}

.m_marginBottom25 {
  margin-bottom: 25px !important;
}

.m_marginBottom30 {
  margin-bottom: 30px !important;
}

.m_marginBottom35 {
  margin-bottom: 35px !important;
}

.m_marginBottom40 {
  margin-bottom: 40px !important;
}

.m_marginBottom45 {
  margin-bottom: 45px !important;
}

.m_marginBottom50 {
  margin-bottom: 50px !important;
}

.m_marginBottom55 {
  margin-bottom: 55px !important;
}

.m_marginBottom60 {
  margin-bottom: 60px !important;
}

.m_marginBottom65 {
  margin-bottom: 65px !important;
}

.m_marginBottom70 {
  margin-bottom: 70px !important;
}

.m_marginBottom75 {
  margin-bottom: 75px !important;
}

.m_marginBottom80 {
  margin-bottom: 80px !important;
}

.m_marginBottom85 {
  margin-bottom: 85px !important;
}

.m_marginBottom86 {
  margin-bottom: 86px !important;
}

.m_marginBottom90 {
  margin-bottom: 90px !important;
}

.m_marginBottom95 {
  margin-bottom: 95px !important;
}

.m_marginBottom100 {
  margin-bottom: 100px !important;
}

.m_marginBottom114 {
  margin-bottom: 114px !important;
}

.m_marginBottom116 {
  margin-bottom: 116px !important;
}

.m_marginBottom152 {
  margin-bottom: 152px !important;
}

.m_marginBottom172 {
  margin-bottom: 172px !important;
}

.m_marginBottom226 {
  margin-bottom: 226px !important;
}

.m_marginBottom256 {
  margin-bottom: 256px !important;
}

.m_marginBottom264 {
  margin-bottom: 264px !important;
}

.m_paddingTop0 {
  padding-top: 0px !important;
}

.m_paddingTop5 {
  padding-top: 5px !important;
}

.m_paddingTop10 {
  padding-top: 10px !important;
}

.m_paddingTop15 {
  padding-top: 15px !important;
}

.m_paddingTop20 {
  padding-top: 20px !important;
}

.m_paddingTop25 {
  padding-top: 25px !important;
}

.m_paddingTop30 {
  padding-top: 30px !important;
}

.m_paddingTop35 {
  padding-top: 35px !important;
}

.m_paddingTop40 {
  padding-top: 40px !important;
}

.m_paddingTop45 {
  padding-top: 45px !important;
}

.m_paddingTop50 {
  padding-top: 50px !important;
}

.m_paddingTop55 {
  padding-top: 55px !important;
}

.m_paddingTop60 {
  padding-top: 60px !important;
}

.m_paddingTop65 {
  padding-top: 65px !important;
}

.m_paddingTop70 {
  padding-top: 70px !important;
}

.m_paddingTop75 {
  padding-top: 75px !important;
}

.m_paddingTop80 {
  padding-top: 80px !important;
}

.m_paddingTop85 {
  padding-top: 85px !important;
}

.m_paddingTop90 {
  padding-top: 90px !important;
}

.m_paddingTop95 {
  padding-top: 95px !important;
}

.m_paddingTop100 {
  padding-top: 100px !important;
}

.m_paddingBottom0 {
  padding-bottom: 0px !important;
}

.m_paddingBottom5 {
  padding-bottom: 5px !important;
}

.m_paddingBottom10 {
  padding-bottom: 10px !important;
}

.m_paddingBottom15 {
  padding-bottom: 15px !important;
}

.m_paddingBottom20 {
  padding-bottom: 20px !important;
}

.m_paddingBottom25 {
  padding-bottom: 25px !important;
}

.m_paddingBottom30 {
  padding-bottom: 30px !important;
}

.m_paddingBottom35 {
  padding-bottom: 35px !important;
}

.m_paddingBottom40 {
  padding-bottom: 40px !important;
}

.m_paddingBottom45 {
  padding-bottom: 45px !important;
}

.m_paddingBottom50 {
  padding-bottom: 50px !important;
}

.m_paddingBottom55 {
  padding-bottom: 55px !important;
}

.m_paddingBottom60 {
  padding-bottom: 60px !important;
}

.m_paddingBottom65 {
  padding-bottom: 65px !important;
}

.m_paddingBottom70 {
  padding-bottom: 70px !important;
}

.m_paddingBottom75 {
  padding-bottom: 75px !important;
}

.m_paddingBottom80 {
  padding-bottom: 80px !important;
}

.m_paddingBottom85 {
  padding-bottom: 85px !important;
}

.m_paddingBottom90 {
  padding-bottom: 90px !important;
}

.m_paddingBottom95 {
  padding-bottom: 95px !important;
}

.m_paddingBottom100 {
  padding-bottom: 100px !important;
}

.m_paddingBottom225 {
  padding-bottom: 225px !important;
}

@media (max-width: 640px) {
  .m_sp_marginTop0 {
    margin-top: 0px !important;
  }
  .m_sp_marginTop5 {
    margin-top: 5px !important;
  }
  .m_sp_marginTop10 {
    margin-top: 10px !important;
  }
  .m_sp_marginTop15 {
    margin-top: 15px !important;
  }
  .m_sp_marginTop20 {
    margin-top: 20px !important;
  }
  .m_sp_marginTop25 {
    margin-top: 25px !important;
  }
  .m_sp_marginTop30 {
    margin-top: 30px !important;
  }
  .m_sp_marginTop35 {
    margin-top: 35px !important;
  }
  .m_sp_marginTop40 {
    margin-top: 40px !important;
  }
  .m_sp_marginTop45 {
    margin-top: 45px !important;
  }
  .m_sp_marginTop50 {
    margin-top: 50px !important;
  }
  .m_sp_marginTop55 {
    margin-top: 55px !important;
  }
  .m_sp_marginTop60 {
    margin-top: 60px !important;
  }
  .m_sp_marginTop65 {
    margin-top: 65px !important;
  }
  .m_sp_marginTop70 {
    margin-top: 70px !important;
  }
  .m_sp_marginTop75 {
    margin-top: 75px !important;
  }
  .m_sp_marginTop80 {
    margin-top: 80px !important;
  }
  .m_sp_marginTop85 {
    margin-top: 85px !important;
  }
  .m_sp_marginTop90 {
    margin-top: 90px !important;
  }
  .m_sp_marginTop95 {
    margin-top: 95px !important;
  }
  .m_sp_marginTop100 {
    margin-top: 100px !important;
  }
  .m_sp_marginTop120 {
    margin-top: 120px !important;
  }
  .m_sp_marginBottom0 {
    margin-bottom: 0px !important;
  }
  .m_sp_marginBottom5 {
    margin-bottom: 5px !important;
  }
  .m_sp_marginBottom10 {
    margin-bottom: 10px !important;
  }
  .m_sp_marginBottom15 {
    margin-bottom: 15px !important;
  }
  .m_sp_marginBottom20 {
    margin-bottom: 20px !important;
  }
  .m_sp_marginBottom25 {
    margin-bottom: 25px !important;
  }
  .m_sp_marginBottom30 {
    margin-bottom: 30px !important;
  }
  .m_sp_marginBottom35 {
    margin-bottom: 35px !important;
  }
  .m_sp_marginBottom40 {
    margin-bottom: 40px !important;
  }
  .m_sp_marginBottom45 {
    margin-bottom: 45px !important;
  }
  .m_sp_marginBottom50 {
    margin-bottom: 50px !important;
  }
  .m_sp_marginBottom55 {
    margin-bottom: 55px !important;
  }
  .m_sp_marginBottom60 {
    margin-bottom: 60px !important;
  }
  .m_sp_marginBottom65 {
    margin-bottom: 65px !important;
  }
  .m_sp_marginBottom70 {
    margin-bottom: 70px !important;
  }
  .m_sp_marginBottom75 {
    margin-bottom: 75px !important;
  }
  .m_sp_marginBottom80 {
    margin-bottom: 80px !important;
  }
  .m_sp_marginBottom85 {
    margin-bottom: 85px !important;
  }
  .m_sp_marginBottom90 {
    margin-bottom: 90px !important;
  }
  .m_sp_marginBottom95 {
    margin-bottom: 95px !important;
  }
  .m_sp_marginBottom100 {
    margin-bottom: 100px !important;
  }
  .m_sp_paddingTop0 {
    padding-top: 0px !important;
  }
  .m_sp_paddingTop5 {
    padding-top: 5px !important;
  }
  .m_sp_paddingTop10 {
    padding-top: 10px !important;
  }
  .m_sp_paddingTop15 {
    padding-top: 15px !important;
  }
  .m_sp_paddingTop20 {
    padding-top: 20px !important;
  }
  .m_sp_paddingTop25 {
    padding-top: 25px !important;
  }
  .m_sp_paddingTop30 {
    padding-top: 30px !important;
  }
  .m_sp_paddingTop35 {
    padding-top: 35px !important;
  }
  .m_sp_paddingTop40 {
    padding-top: 40px !important;
  }
  .m_sp_paddingTop45 {
    padding-top: 45px !important;
  }
  .m_sp_paddingTop50 {
    padding-top: 50px !important;
  }
  .m_sp_paddingTop55 {
    padding-top: 55px !important;
  }
  .m_sp_paddingTop60 {
    padding-top: 60px !important;
  }
  .m_sp_paddingTop65 {
    padding-top: 65px !important;
  }
  .m_sp_paddingTop70 {
    padding-top: 70px !important;
  }
  .m_sp_paddingTop75 {
    padding-top: 75px !important;
  }
  .m_sp_paddingTop80 {
    padding-top: 80px !important;
  }
  .m_sp_paddingTop85 {
    padding-top: 85px !important;
  }
  .m_sp_paddingTop90 {
    padding-top: 90px !important;
  }
  .m_sp_paddingTop95 {
    padding-top: 95px !important;
  }
  .m_sp_paddingTop100 {
    padding-top: 100px !important;
  }
  .m_sp_paddingBottom0 {
    padding-bottom: 0px !important;
  }
  .m_sp_paddingBottom5 {
    padding-bottom: 5px !important;
  }
  .m_sp_paddingBottom10 {
    padding-bottom: 10px !important;
  }
  .m_sp_paddingBottom15 {
    padding-bottom: 15px !important;
  }
  .m_sp_paddingBottom20 {
    padding-bottom: 20px !important;
  }
  .m_sp_paddingBottom25 {
    padding-bottom: 25px !important;
  }
  .m_sp_paddingBottom30 {
    padding-bottom: 30px !important;
  }
  .m_sp_paddingBottom35 {
    padding-bottom: 35px !important;
  }
  .m_sp_paddingBottom40 {
    padding-bottom: 40px !important;
  }
  .m_sp_paddingBottom45 {
    padding-bottom: 45px !important;
  }
  .m_sp_paddingBottom50 {
    padding-bottom: 50px !important;
  }
  .m_sp_paddingBottom55 {
    padding-bottom: 55px !important;
  }
  .m_sp_paddingBottom60 {
    padding-bottom: 60px !important;
  }
  .m_sp_paddingBottom65 {
    padding-bottom: 65px !important;
  }
  .m_sp_paddingBottom70 {
    padding-bottom: 70px !important;
  }
  .m_sp_paddingBottom75 {
    padding-bottom: 75px !important;
  }
  .m_sp_paddingBottom80 {
    padding-bottom: 80px !important;
  }
  .m_sp_paddingBottom85 {
    padding-bottom: 85px !important;
  }
  .m_sp_paddingBottom90 {
    padding-bottom: 90px !important;
  }
  .m_sp_paddingBottom95 {
    padding-bottom: 95px !important;
  }
  .m_sp_paddingBottom100 {
    padding-bottom: 100px !important;
  }
}

@media (max-width: 480px) {
  .m_xs_marginTop0 {
    margin-top: 0px !important;
  }
  .m_xs_marginTop5 {
    margin-top: 5px !important;
  }
  .m_xs_marginTop10 {
    margin-top: 10px !important;
  }
  .m_xs_marginTop15 {
    margin-top: 15px !important;
  }
  .m_xs_marginTop20 {
    margin-top: 20px !important;
  }
  .m_xs_marginTop25 {
    margin-top: 25px !important;
  }
  .m_xs_marginTop30 {
    margin-top: 30px !important;
  }
  .m_xs_marginTop35 {
    margin-top: 35px !important;
  }
  .m_xs_marginTop40 {
    margin-top: 40px !important;
  }
  .m_xs_marginTop45 {
    margin-top: 45px !important;
  }
  .m_xs_marginTop50 {
    margin-top: 50px !important;
  }
  .m_xs_marginTop55 {
    margin-top: 55px !important;
  }
  .m_xs_marginTop60 {
    margin-top: 60px !important;
  }
  .m_xs_marginTop65 {
    margin-top: 65px !important;
  }
  .m_xs_marginTop70 {
    margin-top: 70px !important;
  }
  .m_xs_marginTop75 {
    margin-top: 75px !important;
  }
  .m_xs_marginTop80 {
    margin-top: 80px !important;
  }
  .m_xs_marginTop85 {
    margin-top: 85px !important;
  }
  .m_xs_marginTop90 {
    margin-top: 90px !important;
  }
  .m_xs_marginTop95 {
    margin-top: 95px !important;
  }
  .m_xs_marginTop100 {
    margin-top: 100px !important;
  }
  .m_xs_marginBottom0 {
    margin-bottom: 0px !important;
  }
  .m_xs_marginBottom5 {
    margin-bottom: 5px !important;
  }
  .m_xs_marginBottom10 {
    margin-bottom: 10px !important;
  }
  .m_xs_marginBottom15 {
    margin-bottom: 15px !important;
  }
  .m_xs_marginBottom20 {
    margin-bottom: 20px !important;
  }
  .m_xs_marginBottom25 {
    margin-bottom: 25px !important;
  }
  .m_xs_marginBottom30 {
    margin-bottom: 30px !important;
  }
  .m_xs_marginBottom35 {
    margin-bottom: 35px !important;
  }
  .m_xs_marginBottom40 {
    margin-bottom: 40px !important;
  }
  .m_xs_marginBottom45 {
    margin-bottom: 45px !important;
  }
  .m_xs_marginBottom50 {
    margin-bottom: 50px !important;
  }
  .m_xs_marginBottom55 {
    margin-bottom: 55px !important;
  }
  .m_xs_marginBottom60 {
    margin-bottom: 60px !important;
  }
  .m_xs_marginBottom65 {
    margin-bottom: 65px !important;
  }
  .m_xs_marginBottom70 {
    margin-bottom: 70px !important;
  }
  .m_xs_marginBottom75 {
    margin-bottom: 75px !important;
  }
  .m_xs_marginBottom80 {
    margin-bottom: 80px !important;
  }
  .m_xs_marginBottom85 {
    margin-bottom: 85px !important;
  }
  .m_xs_marginBottom90 {
    margin-bottom: 90px !important;
  }
  .m_xs_marginBottom95 {
    margin-bottom: 95px !important;
  }
  .m_xs_marginBottom100 {
    margin-bottom: 100px !important;
  }
  .m_xs_paddingTop0 {
    padding-top: 0px !important;
  }
  .m_xs_paddingTop5 {
    padding-top: 5px !important;
  }
  .m_xs_paddingTop10 {
    padding-top: 10px !important;
  }
  .m_xs_paddingTop15 {
    padding-top: 15px !important;
  }
  .m_xs_paddingTop20 {
    padding-top: 20px !important;
  }
  .m_xs_paddingTop25 {
    padding-top: 25px !important;
  }
  .m_xs_paddingTop30 {
    padding-top: 30px !important;
  }
  .m_xs_paddingTop35 {
    padding-top: 35px !important;
  }
  .m_xs_paddingTop40 {
    padding-top: 40px !important;
  }
  .m_xs_paddingTop45 {
    padding-top: 45px !important;
  }
  .m_xs_paddingTop50 {
    padding-top: 50px !important;
  }
  .m_xs_paddingTop55 {
    padding-top: 55px !important;
  }
  .m_xs_paddingTop60 {
    padding-top: 60px !important;
  }
  .m_xs_paddingTop65 {
    padding-top: 65px !important;
  }
  .m_xs_paddingTop70 {
    padding-top: 70px !important;
  }
  .m_xs_paddingTop75 {
    padding-top: 75px !important;
  }
  .m_xs_paddingTop80 {
    padding-top: 80px !important;
  }
  .m_xs_paddingTop85 {
    padding-top: 85px !important;
  }
  .m_xs_paddingTop90 {
    padding-top: 90px !important;
  }
  .m_xs_paddingTop95 {
    padding-top: 95px !important;
  }
  .m_xs_paddingTop100 {
    padding-top: 100px !important;
  }
  .m_xs_paddingBottom0 {
    padding-bottom: 0px !important;
  }
  .m_xs_paddingBottom5 {
    padding-bottom: 5px !important;
  }
  .m_xs_paddingBottom10 {
    padding-bottom: 10px !important;
  }
  .m_xs_paddingBottom15 {
    padding-bottom: 15px !important;
  }
  .m_xs_paddingBottom20 {
    padding-bottom: 20px !important;
  }
  .m_xs_paddingBottom25 {
    padding-bottom: 25px !important;
  }
  .m_xs_paddingBottom30 {
    padding-bottom: 30px !important;
  }
  .m_xs_paddingBottom35 {
    padding-bottom: 35px !important;
  }
  .m_xs_paddingBottom40 {
    padding-bottom: 40px !important;
  }
  .m_xs_paddingBottom45 {
    padding-bottom: 45px !important;
  }
  .m_xs_paddingBottom50 {
    padding-bottom: 50px !important;
  }
  .m_xs_paddingBottom55 {
    padding-bottom: 55px !important;
  }
  .m_xs_paddingBottom60 {
    padding-bottom: 60px !important;
  }
  .m_xs_paddingBottom65 {
    padding-bottom: 65px !important;
  }
  .m_xs_paddingBottom70 {
    padding-bottom: 70px !important;
  }
  .m_xs_paddingBottom75 {
    padding-bottom: 75px !important;
  }
  .m_xs_paddingBottom80 {
    padding-bottom: 80px !important;
  }
  .m_xs_paddingBottom85 {
    padding-bottom: 85px !important;
  }
  .m_xs_paddingBottom90 {
    padding-bottom: 90px !important;
  }
  .m_xs_paddingBottom95 {
    padding-bottom: 95px !important;
  }
  .m_xs_paddingBottom100 {
    padding-bottom: 100px !important;
  }
}

.m_rsp_marginTop0 {
  margin-top: 0px !important;
}

.m_rsp_marginTop5 {
  margin-top: 5px !important;
}

.m_rsp_marginTop10 {
  margin-top: 10px !important;
}

.m_rsp_marginTop15 {
  margin-top: 15px !important;
}

.m_rsp_marginTop20 {
  margin-top: 20px !important;
}

.m_rsp_marginTop25 {
  margin-top: 25px !important;
}

.m_rsp_marginTop30 {
  margin-top: 30px !important;
}

.m_rsp_marginTop35 {
  margin-top: 35px !important;
}

.m_rsp_marginTop40 {
  margin-top: 40px !important;
}

.m_rsp_marginTop45 {
  margin-top: 45px !important;
}

.m_rsp_marginTop50 {
  margin-top: 50px !important;
}

.m_rsp_marginTop55 {
  margin-top: 55px !important;
}

.m_rsp_marginTop60 {
  margin-top: 60px !important;
}

.m_rsp_marginTop65 {
  margin-top: 65px !important;
}

.m_rsp_marginTop70 {
  margin-top: 70px !important;
}

.m_rsp_marginTop75 {
  margin-top: 75px !important;
}

.m_rsp_marginTop80 {
  margin-top: 80px !important;
}

.m_rsp_marginTop85 {
  margin-top: 85px !important;
}

.m_rsp_marginTop90 {
  margin-top: 90px !important;
}

.m_rsp_marginTop95 {
  margin-top: 95px !important;
}

.m_rsp_marginTop100 {
  margin-top: 100px !important;
}

.m_rsp_marginTop126 {
  margin-top: 126px !important;
}

.m_rsp_marginTop130 {
  margin-top: 130px !important;
}

.m_rsp_marginTop150 {
  margin-top: 150px !important;
}

@media (max-width: 640px) {
  .m_rsp_marginTop0 {
    margin-top: 0px !important;
  }
  .m_rsp_marginTop5 {
    margin-top: 5px !important;
  }
  .m_rsp_marginTop10 {
    margin-top: 5px !important;
  }
  .m_rsp_marginTop15 {
    margin-top: 10px !important;
  }
  .m_rsp_marginTop20 {
    margin-top: 10px !important;
  }
  .m_rsp_marginTop25 {
    margin-top: 15px !important;
  }
  .m_rsp_marginTop30 {
    margin-top: 15px !important;
  }
  .m_rsp_marginTop35 {
    margin-top: 20px !important;
  }
  .m_rsp_marginTop40 {
    margin-top: 20px !important;
  }
  .m_rsp_marginTop45 {
    margin-top: 25px !important;
  }
  .m_rsp_marginTop50 {
    margin-top: 25px !important;
  }
  .m_rsp_marginTop55 {
    margin-top: 30px !important;
  }
  .m_rsp_marginTop60 {
    margin-top: 30px !important;
  }
  .m_rsp_marginTop65 {
    margin-top: 35px !important;
  }
  .m_rsp_marginTop70 {
    margin-top: 35px !important;
  }
  .m_rsp_marginTop75 {
    margin-top: 40px !important;
  }
  .m_rsp_marginTop80 {
    margin-top: 40px !important;
  }
  .m_rsp_marginTop85 {
    margin-top: 45px !important;
  }
  .m_rsp_marginTop90 {
    margin-top: 45px !important;
  }
  .m_rsp_marginTop95 {
    margin-top: 50px !important;
  }
  .m_rsp_marginTop100 {
    margin-top: 50px !important;
  }
  .m_rsp_marginTop126 {
    margin-top: 63px !important;
  }
  .m_rsp_marginTop130 {
    margin-top: 65px !important;
  }
  .m_rsp_marginTop150 {
    margin-top: 75px !important;
  }
}

.m_rsp_marginBottom0 {
  margin-bottom: 0px !important;
}

.m_rsp_marginBottom5 {
  margin-bottom: 5px !important;
}

.m_rsp_marginBottom10 {
  margin-bottom: 10px !important;
}

.m_rsp_marginBottom15 {
  margin-bottom: 15px !important;
}

.m_rsp_marginBottom20 {
  margin-bottom: 20px !important;
}

.m_rsp_marginBottom25 {
  margin-bottom: 25px !important;
}

.m_rsp_marginBottom30 {
  margin-bottom: 30px !important;
}

.m_rsp_marginBottom35 {
  margin-bottom: 35px !important;
}

.m_rsp_marginBottom40 {
  margin-bottom: 40px !important;
}

.m_rsp_marginBottom45 {
  margin-bottom: 45px !important;
}

.m_rsp_marginBottom50 {
  margin-bottom: 50px !important;
}

.m_rsp_marginBottom55 {
  margin-bottom: 55px !important;
}

.m_rsp_marginBottom60 {
  margin-bottom: 60px !important;
}

.m_rsp_marginBottom65 {
  margin-bottom: 65px !important;
}

.m_rsp_marginBottom70 {
  margin-bottom: 70px !important;
}

.m_rsp_marginBottom75 {
  margin-bottom: 75px !important;
}

.m_rsp_marginBottom80 {
  margin-bottom: 80px !important;
}

.m_rsp_marginBottom85 {
  margin-bottom: 85px !important;
}

.m_rsp_marginBottom90 {
  margin-bottom: 90px !important;
}

.m_rsp_marginBottom95 {
  margin-bottom: 95px !important;
}

.m_rsp_marginBottom100 {
  margin-bottom: 100px !important;
}

@media (max-width: 640px) {
  .m_rsp_marginBotom0 {
    margin-bottom: 0px !important;
  }
  .m_rsp_marginBotom5 {
    margin-bottom: 5px !important;
  }
  .m_rsp_marginBotom10 {
    margin-bottom: 5px !important;
  }
  .m_rsp_marginBotom15 {
    margin-bottom: 10px !important;
  }
  .m_rsp_marginBotom20 {
    margin-bottom: 10px !important;
  }
  .m_rsp_marginBotom25 {
    margin-bottom: 15px !important;
  }
  .m_rsp_marginBotom30 {
    margin-bottom: 15px !important;
  }
  .m_rsp_marginBotom35 {
    margin-bottom: 20px !important;
  }
  .m_rsp_marginBotom40 {
    margin-bottom: 20px !important;
  }
  .m_rsp_marginBotom45 {
    margin-bottom: 25px !important;
  }
  .m_rsp_marginBotom50 {
    margin-bottom: 25px !important;
  }
  .m_rsp_marginBotom55 {
    margin-bottom: 30px !important;
  }
  .m_rsp_marginBotom60 {
    margin-bottom: 30px !important;
  }
  .m_rsp_marginBotom65 {
    margin-bottom: 35px !important;
  }
  .m_rsp_marginBotom70 {
    margin-bottom: 35px !important;
  }
  .m_rsp_marginBotom75 {
    margin-bottom: 40px !important;
  }
  .m_rsp_marginBotom80 {
    margin-bottom: 40px !important;
  }
  .m_rsp_marginBotom85 {
    margin-bottom: 45px !important;
  }
  .m_rsp_marginBotom90 {
    margin-bottom: 45px !important;
  }
  .m_rsp_marginBotom95 {
    margin-bottom: 50px !important;
  }
  .m_rsp_marginBotom100 {
    margin-bottom: 50px !important;
  }
}

.m_rsp_paddingTop0 {
  padding-top: 0px !important;
}

.m_rsp_paddingTop5 {
  padding-top: 5px !important;
}

.m_rsp_paddingTop10 {
  padding-top: 10px !important;
}

.m_rsp_paddingTop15 {
  padding-top: 15px !important;
}

.m_rsp_paddingTop20 {
  padding-top: 20px !important;
}

.m_rsp_paddingTop25 {
  padding-top: 25px !important;
}

.m_rsp_paddingTop30 {
  padding-top: 30px !important;
}

.m_rsp_paddingTop35 {
  padding-top: 35px !important;
}

.m_rsp_paddingTop40 {
  padding-top: 40px !important;
}

.m_rsp_paddingTop45 {
  padding-top: 45px !important;
}

.m_rsp_paddingTop50 {
  padding-top: 50px !important;
}

.m_rsp_paddingTop55 {
  padding-top: 55px !important;
}

.m_rsp_paddingTop60 {
  padding-top: 60px !important;
}

.m_rsp_paddingTop65 {
  padding-top: 65px !important;
}

.m_rsp_paddingTop70 {
  padding-top: 70px !important;
}

.m_rsp_paddingTop75 {
  padding-top: 75px !important;
}

.m_rsp_paddingTop80 {
  padding-top: 80px !important;
}

.m_rsp_paddingTop85 {
  padding-top: 85px !important;
}

.m_rsp_paddingTop90 {
  padding-top: 90px !important;
}

.m_rsp_paddingTop95 {
  padding-top: 95px !important;
}

.m_rsp_paddingTop100 {
  padding-top: 100px !important;
}

.m_rsp_paddingTop130 {
  padding-top: 130px !important;
}

.m_rsp_paddingTop150 {
  padding-top: 150px !important;
}

@media (max-width: 640px) {
  .m_rsp_paddingTop0 {
    padding-top: 0px !important;
  }
  .m_rsp_paddingTop5 {
    padding-top: 5px !important;
  }
  .m_rsp_paddingTop10 {
    padding-top: 5px !important;
  }
  .m_rsp_paddingTop15 {
    padding-top: 10px !important;
  }
  .m_rsp_paddingTop20 {
    padding-top: 10px !important;
  }
  .m_rsp_paddingTop25 {
    padding-top: 15px !important;
  }
  .m_rsp_paddingTop30 {
    padding-top: 15px !important;
  }
  .m_rsp_paddingTop35 {
    padding-top: 20px !important;
  }
  .m_rsp_paddingTop40 {
    padding-top: 20px !important;
  }
  .m_rsp_paddingTop45 {
    padding-top: 25px !important;
  }
  .m_rsp_paddingTop50 {
    padding-top: 25px !important;
  }
  .m_rsp_paddingTop55 {
    padding-top: 30px !important;
  }
  .m_rsp_paddingTop60 {
    padding-top: 30px !important;
  }
  .m_rsp_paddingTop65 {
    padding-top: 35px !important;
  }
  .m_rsp_paddingTop70 {
    padding-top: 35px !important;
  }
  .m_rsp_paddingTop75 {
    padding-top: 40px !important;
  }
  .m_rsp_paddingTop80 {
    padding-top: 40px !important;
  }
  .m_rsp_paddingTop85 {
    padding-top: 45px !important;
  }
  .m_rsp_paddingTop90 {
    padding-top: 45px !important;
  }
  .m_rsp_paddingTop95 {
    padding-top: 50px !important;
  }
  .m_rsp_paddingTop100 {
    padding-top: 50px !important;
  }
  .m_rsp_paddingTop130 {
    padding-top: 65px !important;
  }
  .m_rsp_paddingTop150 {
    padding-top: 75px !important;
  }
}

.m_rsp_paddingBottom0 {
  padding-bottom: 0px !important;
}

.m_rsp_paddingBottom5 {
  padding-bottom: 5px !important;
}

.m_rsp_paddingBottom10 {
  padding-bottom: 10px !important;
}

.m_rsp_paddingBottom15 {
  padding-bottom: 15px !important;
}

.m_rsp_paddingBottom20 {
  padding-bottom: 20px !important;
}

.m_rsp_paddingBottom25 {
  padding-bottom: 25px !important;
}

.m_rsp_paddingBottom30 {
  padding-bottom: 30px !important;
}

.m_rsp_paddingBottom35 {
  padding-bottom: 35px !important;
}

.m_rsp_paddingBottom40 {
  padding-bottom: 40px !important;
}

.m_rsp_paddingBottom45 {
  padding-bottom: 45px !important;
}

.m_rsp_paddingBottom50 {
  padding-bottom: 50px !important;
}

.m_rsp_paddingBottom55 {
  padding-bottom: 55px !important;
}

.m_rsp_paddingBottom60 {
  padding-bottom: 60px !important;
}

.m_rsp_paddingBottom65 {
  padding-bottom: 65px !important;
}

.m_rsp_paddingBottom70 {
  padding-bottom: 70px !important;
}

.m_rsp_paddingBottom75 {
  padding-bottom: 75px !important;
}

.m_rsp_paddingBottom80 {
  padding-bottom: 80px !important;
}

.m_rsp_paddingBottom85 {
  padding-bottom: 85px !important;
}

.m_rsp_paddingBottom90 {
  padding-bottom: 90px !important;
}

.m_rsp_paddingBottom95 {
  padding-bottom: 95px !important;
}

.m_rsp_paddingBottom100 {
  padding-bottom: 100px !important;
}

.m_rsp_paddingBottom140 {
  padding-bottom: 140px !important;
}

.m_rsp_paddingBottom150 {
  padding-bottom: 150px !important;
}

.m_rsp_paddingBottom200 {
  padding-bottom: 200px !important;
}

@media (max-width: 640px) {
  .m_rsp_paddingBotom0 {
    padding-bottom: 0px !important;
  }
  .m_rsp_paddingBotom5 {
    padding-bottom: 5px !important;
  }
  .m_rsp_paddingBotom10 {
    padding-bottom: 5px !important;
  }
  .m_rsp_paddingBotom15 {
    padding-bottom: 10px !important;
  }
  .m_rsp_paddingBotom20 {
    padding-bottom: 10px !important;
  }
  .m_rsp_paddingBotom25 {
    padding-bottom: 15px !important;
  }
  .m_rsp_paddingBotom30 {
    padding-bottom: 15px !important;
  }
  .m_rsp_paddingBotom35 {
    padding-bottom: 20px !important;
  }
  .m_rsp_paddingBotom40 {
    padding-bottom: 20px !important;
  }
  .m_rsp_paddingBotom45 {
    padding-bottom: 25px !important;
  }
  .m_rsp_paddingBotom50 {
    padding-bottom: 25px !important;
  }
  .m_rsp_paddingBotom55 {
    padding-bottom: 30px !important;
  }
  .m_rsp_paddingBotom60 {
    padding-bottom: 30px !important;
  }
  .m_rsp_paddingBotom65 {
    padding-bottom: 35px !important;
  }
  .m_rsp_paddingBotom70 {
    padding-bottom: 35px !important;
  }
  .m_rsp_paddingBotom75 {
    padding-bottom: 40px !important;
  }
  .m_rsp_paddingBotom80 {
    padding-bottom: 40px !important;
  }
  .m_rsp_paddingBotom85 {
    padding-bottom: 45px !important;
  }
  .m_rsp_paddingBotom90 {
    padding-bottom: 45px !important;
  }
  .m_rsp_paddingBotom95 {
    padding-bottom: 50px !important;
  }
  .m_rsp_paddingBotom100 {
    padding-bottom: 50px !important;
  }
  .m_rsp_paddingBotom140 {
    padding-bottom: 70px !important;
  }
  .m_rsp_paddingBotom150 {
    padding-bottom: 75px !important;
  }
  .m_rsp_paddingBotom200 {
    padding-bottom: 100px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_marginTop0vw {
    margin-top: 0vw !important;
  }
  .m_sp_marginTop5vw {
    margin-top: 0.78vw !important;
  }
  .m_sp_marginTop10vw {
    margin-top: 1.56vw !important;
  }
  .m_sp_marginTop15vw {
    margin-top: 2.34vw !important;
  }
  .m_sp_marginTop20vw {
    margin-top: 3.12vw !important;
  }
  .m_sp_marginTop25vw {
    margin-top: 3.9vw !important;
  }
  .m_sp_marginTop30vw {
    margin-top: 4.68vw !important;
  }
  .m_sp_marginTop35vw {
    margin-top: 5.46vw !important;
  }
  .m_sp_marginTop40vw {
    margin-top: 6.25vw !important;
  }
  .m_sp_marginTop45vw {
    margin-top: 7.03vw !important;
  }
  .m_sp_marginTop50vw {
    margin-top: 7.81vw !important;
  }
  .m_sp_marginTop55vw {
    margin-top: 8.59vw !important;
  }
  .m_sp_marginTop60vw {
    margin-top: 9.37vw !important;
  }
  .m_sp_marginTop65vw {
    margin-top: 10.15vw !important;
  }
  .m_sp_marginTop70vw {
    margin-top: 10.93vw !important;
  }
  .m_sp_marginTop75vw {
    margin-top: 11.71vw !important;
  }
  .m_sp_marginTop80vw {
    margin-top: 12.5vw !important;
  }
  .m_sp_marginTop85vw {
    margin-top: 13.28vw !important;
  }
  .m_sp_marginTop90vw {
    margin-top: 14.06vw !important;
  }
  .m_sp_marginTop95vw {
    margin-top: 14.84vw !important;
  }
  .m_sp_marginTop100vw {
    margin-top: 15.62vw !important;
  }
  .m_sp_marginBottom0vw {
    margin-bottom: 0vw !important;
  }
  .m_sp_marginBottom5vw {
    margin-bottom: 0.78vw !important;
  }
  .m_sp_marginBottom10vw {
    margin-bottom: 1.56vw !important;
  }
  .m_sp_marginBottom15vw {
    margin-bottom: 2.34vw !important;
  }
  .m_sp_marginBottom20vw {
    margin-bottom: 3.12vw !important;
  }
  .m_sp_marginBottom25vw {
    margin-bottom: 3.9vw !important;
  }
  .m_sp_marginBottom30vw {
    margin-bottom: 4.68vw !important;
  }
  .m_sp_marginBottom35vw {
    margin-bottom: 5.46vw !important;
  }
  .m_sp_marginBottom40vw {
    margin-bottom: 6.25vw !important;
  }
  .m_sp_marginBottom45vw {
    margin-bottom: 7.03vw !important;
  }
  .m_sp_marginBottom50vw {
    margin-bottom: 7.81vw !important;
  }
  .m_sp_marginBottom55vw {
    margin-bottom: 8.59vw !important;
  }
  .m_sp_marginBottom60vw {
    margin-bottom: 9.37vw !important;
  }
  .m_sp_marginBottom65vw {
    margin-bottom: 10.15vw !important;
  }
  .m_sp_marginBottom70vw {
    margin-bottom: 10.93vw !important;
  }
  .m_sp_marginBottom75vw {
    margin-bottom: 11.71vw !important;
  }
  .m_sp_marginBottom80vw {
    margin-bottom: 12.5vw !important;
  }
  .m_sp_marginBottom85vw {
    margin-bottom: 13.28vw !important;
  }
  .m_sp_marginBottom90vw {
    margin-bottom: 14.06vw !important;
  }
  .m_sp_marginBottom95vw {
    margin-bottom: 14.84vw !important;
  }
  .m_sp_marginBottom100vw {
    margin-bottom: 15.62vw !important;
  }
  .m_sp_paddingTop0vw {
    padding-top: 0vw !important;
  }
  .m_sp_paddingTop5vw {
    padding-top: 0.78vw !important;
  }
  .m_sp_paddingTop10vw {
    padding-top: 1.56vw !important;
  }
  .m_sp_paddingTop15vw {
    padding-top: 2.34vw !important;
  }
  .m_sp_paddingTop20vw {
    padding-top: 3.12vw !important;
  }
  .m_sp_paddingTop25vw {
    padding-top: 3.9vw !important;
  }
  .m_sp_paddingTop30vw {
    padding-top: 4.68vw !important;
  }
  .m_sp_paddingTop35vw {
    padding-top: 5.46vw !important;
  }
  .m_sp_paddingTop40vw {
    padding-top: 6.25vw !important;
  }
  .m_sp_paddingTop45vw {
    padding-top: 7.03vw !important;
  }
  .m_sp_paddingTop50vw {
    padding-top: 7.81vw !important;
  }
  .m_sp_paddingTop55vw {
    padding-top: 8.59vw !important;
  }
  .m_sp_paddingTop60vw {
    padding-top: 9.37vw !important;
  }
  .m_sp_paddingTop65vw {
    padding-top: 10.15vw !important;
  }
  .m_sp_paddingTop70vw {
    padding-top: 10.93vw !important;
  }
  .m_sp_paddingTop75vw {
    padding-top: 11.71vw !important;
  }
  .m_sp_paddingTop80vw {
    padding-top: 12.5vw !important;
  }
  .m_sp_paddingTop85vw {
    padding-top: 13.28vw !important;
  }
  .m_sp_paddingTop90vw {
    padding-top: 14.06vw !important;
  }
  .m_sp_paddingTop95vw {
    padding-top: 14.84vw !important;
  }
  .m_sp_paddingTop100vw {
    padding-top: 15.62vw !important;
  }
  .m_sp_paddingBottom0vw {
    padding-bottom: 0vw !important;
  }
  .m_sp_paddingBottom5vw {
    padding-bottom: 0.78vw !important;
  }
  .m_sp_paddingBottom10vw {
    padding-bottom: 1.56vw !important;
  }
  .m_sp_paddingBottom15vw {
    padding-bottom: 2.34vw !important;
  }
  .m_sp_paddingBottom20vw {
    padding-bottom: 3.12vw !important;
  }
  .m_sp_paddingBottom25vw {
    padding-bottom: 3.9vw !important;
  }
  .m_sp_paddingBottom30vw {
    padding-bottom: 4.68vw !important;
  }
  .m_sp_paddingBottom35vw {
    padding-bottom: 5.46vw !important;
  }
  .m_sp_paddingBottom40vw {
    padding-bottom: 6.25vw !important;
  }
  .m_sp_paddingBottom45vw {
    padding-bottom: 7.03vw !important;
  }
  .m_sp_paddingBottom50vw {
    padding-bottom: 7.81vw !important;
  }
  .m_sp_paddingBottom55vw {
    padding-bottom: 8.59vw !important;
  }
  .m_sp_paddingBottom60vw {
    padding-bottom: 9.37vw !important;
  }
  .m_sp_paddingBottom65vw {
    padding-bottom: 10.15vw !important;
  }
  .m_sp_paddingBottom70vw {
    padding-bottom: 10.93vw !important;
  }
  .m_sp_paddingBottom75vw {
    padding-bottom: 11.71vw !important;
  }
  .m_sp_paddingBottom80vw {
    padding-bottom: 12.5vw !important;
  }
  .m_sp_paddingBottom85vw {
    padding-bottom: 13.28vw !important;
  }
  .m_sp_paddingBottom90vw {
    padding-bottom: 14.06vw !important;
  }
  .m_sp_paddingBottom95vw {
    padding-bottom: 14.84vw !important;
  }
  .m_sp_paddingBottom100vw {
    padding-bottom: 15.62vw !important;
  }
}

/* ================================================================================

▼ 表示切り替え

================================================================================ */
.m_show_1400 {
  display: none !important;
}

.m_show_1024 {
  display: none !important;
}

.m_show_768 {
  display: none !important;
}

.m_show_640 {
  display: none !important;
}

.m_show_440 {
  display: none !important;
}

.m_show_375 {
  display: none !important;
}

.m_show_374 {
  display: none !important;
}

@media (max-width: 1400px) {
  .m_hide_1400 {
    display: none !important;
  }
  .m_show_1400 {
    display: block !important;
  }
}

@media (max-width: 1024px) {
  .m_hide_1024 {
    display: none !important;
  }
  .m_show_1024 {
    display: block !important;
  }
}

@media (max-width: 768px) {
  .m_hide_768 {
    display: none !important;
  }
  .m_show_768 {
    display: block !important;
  }
}

@media (max-width: 640px) {
  .m_hide_640 {
    display: none !important;
  }
  .m_show_640 {
    display: block !important;
  }
}

@media (max-width: 440px) {
  .m_hide_440 {
    display: none !important;
  }
  .m_show_440 {
    display: block !important;
  }
}

@media (max-width: 375px) {
  .m_hide_375 {
    display: none !important;
  }
  .m_show_375 {
    display: block !important;
  }
}

@media (max-width: 374px) {
  .m_hide_374 {
    display: none !important;
  }
  .m_show_374 {
    display: block !important;
  }
}

.m_display_none {
  display: none;
}

.m_clearfix::after,
.m_clear::after {
  content: '';
  display: block;
  clear: both;
}

.m_display_block {
  display: block !important;
}

.m_display_inline_block {
  display: inline-block !important;
}

.m_display_inline {
  display: inline !important;
}

.m_text {
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.6;
}

@media (max-width: 640px) {
  .m_text {
    font-size: 14px;
  }
}

.m_text32 {
  font-size: 32px !important;
}

.m_text30 {
  font-size: 30px !important;
}

.m_text28 {
  font-size: 28px !important;
}

.m_text26 {
  font-size: 26px !important;
}

.m_text24 {
  font-size: 24px !important;
}

.m_text22 {
  font-size: 22px !important;
}

.m_text20 {
  font-size: 20px !important;
}

.m_text18 {
  font-size: 18px !important;
}

.m_text16 {
  font-size: 16px !important;
}

.m_text15 {
  font-size: 15px !important;
}

.m_text14 {
  font-size: 14px !important;
}

.m_text13 {
  font-size: 13px !important;
}

.m_text12 {
  font-size: 12px !important;
}

.m_text11 {
  font-size: 11px !important;
}

.m_text10 {
  font-size: 10px !important;
}

@media (max-width: 640px) {
  .m_sp_text32 {
    font-size: 32px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_text30 {
    font-size: 30px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_text28 {
    font-size: 28px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_text26 {
    font-size: 26px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_text24 {
    font-size: 24px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_text22 {
    font-size: 22px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_text20 {
    font-size: 20px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_text18 {
    font-size: 18px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_text16 {
    font-size: 16px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_text15 {
    font-size: 15px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_text14 {
    font-size: 14px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_text13 {
    font-size: 13px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_text12 {
    font-size: 12px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_text11 {
    font-size: 11px !important;
  }
}

@media (max-width: 640px) {
  .m_sp_text10 {
    font-size: 10px !important;
  }
}

.m_rsp_text26 {
  font-size: 26px !important;
}

@media (max-width: 640px) {
  .m_rsp_text26 {
    font-size: 24px !important;
  }
}

.m_rsp_text24 {
  font-size: 24px !important;
}

@media (max-width: 640px) {
  .m_rsp_text24 {
    font-size: 22px !important;
  }
}

.m_rsp_text22 {
  font-size: 22px !important;
}

@media (max-width: 640px) {
  .m_rsp_text22 {
    font-size: 20px !important;
  }
}

.m_rsp_text20 {
  font-size: 20px !important;
}

@media (max-width: 640px) {
  .m_rsp_text20 {
    font-size: 18px !important;
  }
}

.m_rsp_text18 {
  font-size: 18px !important;
}

@media (max-width: 640px) {
  .m_rsp_text18 {
    font-size: 16px !important;
  }
}

.m_rsp_text16 {
  font-size: 16px !important;
}

@media (max-width: 640px) {
  .m_rsp_text16 {
    font-size: 14px !important;
  }
}

.m_rsp_text15 {
  font-size: 15px !important;
}

@media (max-width: 640px) {
  .m_rsp_text15 {
    font-size: 13px !important;
  }
}

.m_rsp_text14 {
  font-size: 14px !important;
}

@media (max-width: 640px) {
  .m_rsp_text14 {
    font-size: 12px !important;
  }
}

.m_rsp_text13 {
  font-size: 13px !important;
}

@media (max-width: 640px) {
  .m_rsp_text13 {
    font-size: 11px !important;
  }
}

@media (max-width: 640px) {
  .m_vw_text22 {
    font-size: 3.43vw !important;
  }
}

@media (max-width: 640px) {
  .m_vw_text20 {
    font-size: 3.12vw !important;
  }
}

@media (max-width: 640px) {
  .m_vw_text18 {
    font-size: 2.81vw !important;
  }
}

@media (max-width: 640px) {
  .m_vw_text16 {
    font-size: 2.5vw !important;
  }
}

@media (max-width: 640px) {
  .m_vw_text15 {
    font-size: 2.34vw !important;
  }
}

@media (max-width: 640px) {
  .m_vw_text14 {
    font-size: 2.18vw !important;
  }
}

@media (max-width: 640px) {
  .m_vw_text13 {
    font-size: 2.03vw !important;
  }
}

.m_text200p {
  font-size: 200%;
}

.m_text150p {
  font-size: 150%;
}

.m_text130p {
  font-size: 130%;
}

.m_text115p {
  font-size: 115%;
}

.m_text100p {
  font-size: 100%;
}

.m_text85p {
  font-size: 85%;
}

.m_text75p {
  font-size: 75%;
}

.m_text70p {
  font-size: 70%;
}

@media (max-width: 640px) {
  .m_sp_text200p {
    font-size: 200%;
  }
  .m_sp_text150p {
    font-size: 150%;
  }
  .m_sp_text130p {
    font-size: 130%;
  }
  .m_sp_text115p {
    font-size: 115%;
  }
  .m_sp_text100p {
    font-size: 100%;
  }
  .m_sp_text85p {
    font-size: 85%;
  }
  .m_sp_text75p {
    font-size: 75%;
  }
  .m_sp_text70p {
    font-size: 70%;
  }
}

.m_italic {
  font-style: italic !important;
}

.m_bold {
  font-weight: bold !important;
}

.m_weight_normal {
  font-weight: normal !important;
}

.m_underline {
  text-decoration: underline !important;
}

.m_letter_little {
  letter-spacing: 0.1em;
}

.m_color_white {
  color: #fff !important;
}

.m_color_red {
  color: #f00 !important;
}

.m_color_black {
  color: #000 !important;
}

.m_text_note {
  display: flex;
  align-items: baseline;
}

.m_text_note > ._label {
  white-space: nowrap;
}

.m_text_note > ._text {
  flex: 1 1 auto;
}

.m_text_note.is_align_right {
  justify-content: flex-end;
}

.m_align_left {
  text-align: left !important;
}

.m_align_center {
  text-align: center !important;
}

.m_align_right {
  text-align: right !important;
}

@media (max-width: 640px) {
  .m_sp_align_left {
    text-align: left !important;
  }
}

@media (max-width: 640px) {
  .m_sp_align_center {
    text-align: center !important;
  }
}

.m_nowrap {
  white-space: nowrap;
}

.m_break_all {
  word-break: break-all;
}

.m_vertical_super {
  vertical-align: super;
}

.m_vertical_bottom {
  vertical-align: bottom !important;
}

.m_font_en {
  font-family: "Century Gothic", Robots, Helvetica, arial, sans-serif;
}

.m_background_gray {
  background: #F2F2F2;
}

.m_background_orange {
  background: #efac29;
}

.m_horizon_gray {
  display: block;
  width: auto;
  margin-top: 0;
  margin-bottom: 0;
  background: #d3d3d3;
}

.m_gray_box {
  background: #f5f5f5;
  padding: 40px 30px;
}

@media (max-width: 640px) {
  .m_gray_box {
    padding: 20px 15px;
  }
}

.m_note_icon {
  display: flex;
}

.m_note_icon > ._icon {
  white-space: nowrap;
}

.m_note_icon > ._text {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}

.m_check_text {
  position: relative;
  padding-left: 29px;
}

@media (max-width: 640px) {
  .m_check_text {
    padding-left: 23px;
  }
}

.m_check_text::before {
  content: '';
  background: url(/_materials/images/common/parts/icon_check.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 21px;
  position: absolute;
  left: 0;
  top: 0.75em;
  transform: translateY(-50%);
}

@media (max-width: 640px) {
  .m_check_text::before {
    width: 18px;
  }
}

.m_check_text.is_height_16::before {
  top: 0.8em;
}

.m_link_arrow {
  display: inline-block;
  position: relative;
  padding-left: 13px;
  overflow: hidden;
  font-size: 16px;
}

@media (max-width: 640px) {
  .m_link_arrow {
    font-size: 14px;
  }
}

.m_link_arrow::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-top: solid 1px #333;
  border-right: solid 1px #333;
  transform: translate(-50%, -50%) rotate(45deg);
  position: absolute;
  top: 50%;
  left: 0;
}

.m_link_arrow > ._underline {
  border-bottom: solid 1px #333;
}

@media (min-width: 769px) {
  .m_link_arrow:hover {
    color: #eaa20a;
  }
  .m_link_arrow:hover::before {
    border-color: #eaa20a;
  }
  .m_link_arrow:hover > ._underline {
    border-bottom-color: #eaa20a;
  }
}

.m_link_arrow_large {
  display: inline-block;
  position: relative;
  padding-left: 13px;
  overflow: hidden;
  font-size: 18px;
}

@media (max-width: 640px) {
  .m_link_arrow_large {
    font-size: 16px;
  }
}

.m_link_arrow_large::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-top: solid 1px #333;
  border-right: solid 1px #333;
  transform: translate(-50%, -50%) rotate(45deg);
  position: absolute;
  top: 50%;
  left: 0;
}

.m_link_arrow_large::after {
  content: '';
  display: block;
  width: 0%;
  height: 1px;
  transition: width 0.3s ease;
  position: absolute;
  left: 0;
  bottom: 0;
}

@media (min-width: 769px) {
  .m_link_arrow_large:hover {
    color: #eaa20a;
  }
  .m_link_arrow_large:hover::before {
    border-color: #eaa20a;
  }
  .m_link_arrow_large:hover::after {
    background: #eaa20a;
    width: 100%;
  }
}

.m_link_text {
  color: #fbb013;
  border-bottom: solid 1px #fbb013;
}

.m_link_text:hover {
  opacity: 0.6;
}

.m_link_text_white {
  color: #fff;
  border-bottom: solid 1px #fff;
}

.m_link_text_white:hover {
  opacity: 0.6;
}

.m_link_text_black {
  color: #333;
  border-bottom: solid 1px #333;
}

.m_link_text_black:hover {
  opacity: 0.6;
}

.m_tel_link {
  color: #333;
}

@media (min-width: 641px) {
  .m_tel_link {
    pointer-events: none;
  }
}

@media (max-width: 640px) {
  .m_tel_link {
    border-bottom: solid 1px #333;
  }
}

.m_tel_link_white {
  color: #fff;
}

@media (min-width: 641px) {
  .m_tel_link_white {
    pointer-events: none;
  }
}

@media (max-width: 640px) {
  .m_tel_link_white {
    border-bottom: solid 1px #fff;
  }
}

.m_arrow {
  padding-right: 20px;
  position: relative;
  display: inline-block;
}

.m_arrow::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-top: solid 2px #333;
  border-right: solid 2px #333;
  transform-origin: right top;
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  right: 0;
}

.m_youtube {
  max-width: 730px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.m_youtube::before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.m_youtube > iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.m_anchor_spacer {
  display: flex;
  align-items: flex-end;
  height: 0;
}

.m_anchor_spacer .m_anchor_spacer_inner {
  display: block;
  height: 100px;
  width: 0;
  visibility: hidden;
}

@media (max-width: 480px) {
  .m_anchor_spacer .m_anchor_spacer_inner {
    height: 80px;
  }
}

.m_anchor_spacer.is_tall .m_anchor_spacer_inner {
  height: 120px;
}

@media (max-width: 480px) {
  .m_anchor_spacer.is_tall .m_anchor_spacer_inner {
    height: 100px;
  }
}

.m_hover_alpha {
  display: block;
  transition: opacity 0.3s ease;
}

@media (min-width: 769px) {
  .m_hover_alpha:hover {
    opacity: 0.8;
  }
}

.m_svg {
  position: relative;
}

.m_svg::before {
  content: '';
  display: block;
}

.m_svg .m_svg_object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.m_svg.is_icon_8ing::before {
  padding-top: 57.29%;
}

.m_svg.is_icon_8ing_simple::before {
  padding-top: 54.5%;
}

.m_svg.is_icon_colopl::before {
  padding-top: 8.44%;
}

.m_svg.is_icon_blank::before {
  padding-top: 86.66%;
}

.m_svg.is_icon_blank .m_svg_object .a {
  fill: #aaaaaa;
}

.m_svg.is_icon_blank .m_svg_object .b {
  fill: #aaaaaa;
}

.m_svg.is_icon_linkicon::before {
  padding-top: 100%;
}

.m_svg.is_icon_linkicon .m_svg_object .a {
  fill: #aaaaaa;
}

.m_svg.is_icon_arrow::before {
  padding-top: 23.54%;
}

.m_svg.is_icon_arrow .m_svg_object .a {
  fill: #fff;
}

.m_title_h2 {
  color: #000;
  font-family: "Century Gothic", Robots, Helvetica, arial, sans-serif;
  font-weight: bold;
  font-size: 52px;
  line-height: 62px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-right: -0.05em;
}

@media (max-width: 640px) {
  .m_title_h2 {
    font-size: 46px;
    line-height: 56px;
  }
}

@media (max-width: 480px) {
  .m_title_h2 {
    font-size: 40px;
    line-height: 50px;
  }
}

@media (max-width: 375px) {
  .m_title_h2 {
    font-size: 36px;
    line-height: 46px;
  }
}

.m_title_h2.is_half_up {
  position: relative;
  top: -30px;
  margin-bottom: -30px;
}

@media (max-width: 640px) {
  .m_title_h2.is_half_up {
    top: -26px;
    margin-bottom: -26px;
  }
}

@media (max-width: 480px) {
  .m_title_h2.is_half_up {
    top: -22px;
    margin-bottom: -22px;
  }
}

.m_title_h2_sub {
  font-size: 20px;
  color: #f5ae19;
  text-align: center;
  letter-spacing: 0.2em;
  margin-right: -0.2em;
}

@media (max-width: 640px) {
  .m_title_h2_sub {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .m_title_h2_sub {
    font-size: 16px;
  }
}

.m_label_balloon {
  text-align: center;
  font-family: "Century Gothic", Robots, Helvetica, arial, sans-serif;
  color: #000;
}

.m_label_balloon .m_label_balloon_inner {
  display: inline-block;
  background: #f5ae19;
  padding-top: 3px;
  padding-bottom: 3px;
  position: relative;
  font-size: 24px;
}

.m_label_balloon .m_label_balloon_inner::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 8px 0 8px;
  border-color: #f5ae19 transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.m_label_balloon .m_label_balloon_inner > ._text {
  margin-right: -5px;
  margin-left: -5px;
  font-weight: bold;
}

.m_title_8ing {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: #000;
}

@media (max-width: 640px) {
  .m_title_8ing {
    font-size: 36px;
  }
}

@media (max-width: 480px) {
  .m_title_8ing {
    font-size: 32px;
  }
}

@media (max-width: 375px) {
  .m_title_8ing {
    font-size: 28px;
  }
}

.m_title_8ing .m_title_8ing_en {
  font-family: "Century Gothic", Robots, Helvetica, arial, sans-serif;
}

.m_title_8ing ._large {
  font-size: 125%;
}

.m_title_slash {
  text-align: center;
  font-size: 20px;
}

@media (max-width: 640px) {
  .m_title_slash {
    font-size: 18px;
  }
}

.m_title_slash > ._text {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  color: #80868b;
}

.m_title_slash > ._text::before, .m_title_slash > ._text::after {
  content: '/';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.m_title_slash > ._text::before {
  left: 0;
}

.m_title_slash > ._text::after {
  right: 0;
}

.m_about_contents {
  display: flex;
}

@media (max-width: 640px) {
  .m_about_contents {
    display: block;
  }
}

.m_about_contents .m_about_contents_item {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}

.m_about_contents .m_about_contents_item + .m_about_contents_item {
  margin-left: 20px;
}

@media (max-width: 640px) {
  .m_about_contents .m_about_contents_item + .m_about_contents_item {
    margin-left: 0;
    margin-top: 10px;
  }
}

.m_title_band {
  width: 100%;
}

.m_title_band ._inner {
  display: block;
  width: calc(((100% - 1000px) / 2) + 900px);
  padding-left: calc(((100% - 1000px) / 2));
  background: #383838;
  color: #fff;
  font-size: 36px;
  padding-top: 5px;
  padding-bottom: 5px;
  letter-spacing: 0.1em;
}

@media (max-width: 1040px) {
  .m_title_band ._inner {
    padding-left: 20px;
  }
}

@media (max-width: 880px) {
  .m_title_band ._inner {
    width: calc(100% - 40px);
  }
}

@media (max-width: 640px) {
  .m_title_band ._inner {
    padding-left: 15px;
    font-size: 32px;
    font-weight: normal;
  }
}

@media (max-width: 480px) {
  .m_title_band ._inner {
    font-size: 28px;
  }
}

@media (max-width: 375px) {
  .m_title_band ._inner {
    font-size: 6.4vw;
  }
}

.m_title_band::first-letter {
  color: #f5ae19;
}

.m_title_band.is_font_small > ._inner {
  font-size: 34px;
}

@media (max-width: 640px) {
  .m_title_band.is_font_small > ._inner {
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  .m_title_band.is_font_small > ._inner {
    font-size: 26px;
  }
}

@media (max-width: 375px) {
  .m_title_band.is_font_small > ._inner {
    font-size: 5.86vw;
  }
}

.m_title_step {
  display: flex;
  align-items: center;
}

@media (max-width: 480px) {
  .m_title_step {
    display: block;
  }
}

.m_title_step .m_title_step_label {
  background: #feac29;
  display: block;
  color: #fff;
  font-weight: bold;
  border-radius: 20px;
  padding: 8px 20px;
  font-family: "Open Sans", Robots, "Century Gothic", Helvetica, arial, sans-serif;
  line-height: 1;
  margin-right: 10px;
  overflow: hidden;
  width: 140px;
  text-align: center;
}

@media (max-width: 640px) {
  .m_title_step .m_title_step_label {
    width: 120px;
    padding: 5px 20px;
  }
}

@media (max-width: 480px) {
  .m_title_step .m_title_step_label {
    margin-left: -10px;
  }
}

.m_title_step .m_title_step_label > ._inner {
  display: block;
  font-size: 20px;
  letter-spacing: 0.1em;
  margin-right: -0.1em;
}

@media (max-width: 640px) {
  .m_title_step .m_title_step_label > ._inner {
    font-size: 18px;
  }
}

.m_title_step .m_title_step_title {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  letter-spacing: 0.1em;
  font-size: 34px;
}

@media (max-width: 640px) {
  .m_title_step .m_title_step_title {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .m_title_step .m_title_step_title {
    margin-top: 5px;
    font-size: 24px;
    display: block;
  }
}

.m_btn_wrapper {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.m_btn_wrapper.is_max_400 {
  max-width: 400px;
}

.m_btn_wrapper.is_max_350 {
  max-width: 350px;
}

.m_btn_gradation_header {
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: center;
}

.m_btn_gradation_header .m_btn_gradation_header_text {
  letter-spacing: 4px;
  font-size: 16px;
  white-space: nowrap;
  font-weight: bold;
  color: #fff;
}

@media (max-width: 768px) {
  .m_btn_gradation_header .m_btn_gradation_header_text {
    letter-spacing: 2px;
    font-size: 15px;
  }
}

@media (max-width: 375px) {
  .m_btn_gradation_header .m_btn_gradation_header_text {
    font-size: 14px;
  }
}

.m_btn_gradation_header .m_btn_gradation_header_text > ._large {
  font-size: 125%;
}

.m_btn_gradation_header.is_color_orange {
  background: linear-gradient(to right, #ff9600, #ff6600);
}

.m_btn_gradation_header.is_color_blue {
  background: linear-gradient(to right, #00aeff, #003cff);
}

.m_btn_border_blank {
  display: flex;
  height: 65px;
  border: solid 1px #929292;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}

@media (max-width: 480px) {
  .m_btn_border_blank {
    height: 55px;
  }
}

.m_btn_border_blank > ._text {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

@media (max-width: 480px) {
  .m_btn_border_blank > ._text {
    font-size: 14px;
  }
}

.m_btn_border_blank > ._icon {
  width: 16px;
  margin-left: 10px;
}

.m_btn_border_blank > ._icon .m_svg polygon {
  fill: #fff;
}

@media (min-width: 769px) {
  .m_btn_border_blank:hover {
    background: #f6b731;
  }
}

.m_btn_border_arrow {
  display: flex;
  height: 65px;
  border: solid 1px #fff;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}

@media (max-width: 480px) {
  .m_btn_border_arrow {
    height: 55px;
  }
}

.m_btn_border_arrow > ._text {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

@media (max-width: 480px) {
  .m_btn_border_arrow > ._text {
    font-size: 14px;
  }
}

.m_btn_border_arrow > ._icon {
  width: 20px;
  margin-left: 10px;
}

.m_btn_border_arrow > ._icon .m_svg polygon {
  fill: #fff;
}

.m_btn_border_arrow.is_color_black {
  border-color: #929292;
}

.m_btn_border_arrow.is_color_black > ._text {
  color: #333;
}

.m_btn_border_arrow.is_color_black > ._icon .m_svg polygon {
  fill: #333;
}

@media (min-width: 769px) {
  .m_btn_border_arrow:hover {
    background: #f6b731;
  }
}

.m_about_link {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85px;
  background-repeat: no-repeat;
  position: relative;
  transition: opacity 0.3s ease, background-color 0.3s ease;
}

.m_about_link::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 14px 14px;
  border-color: transparent transparent #999 transparent;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.m_about_link ._text {
  text-align: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
}

.m_about_link.is_icon_8ing {
  background-image: url(/_materials/images/common/parts/icon_8ing.png);
  background-size: 40px auto;
  background-position: left 43px center;
}

.m_about_link.is_icon_building {
  background-image: url(/_materials/images/common/parts/icon_building.png);
  background-size: 46px auto;
  background-position: left 43px center;
}

.m_about_link.is_icon_heart {
  background-image: url(/_materials/images/common/parts/icon_heart.png);
  background-size: 56px auto;
  background-position: left 43px center;
}

@media (min-width: 769px) {
  .m_about_link:hover {
    opacity: 0.6;
    background-color: #eee;
  }
}

.m_hero_visual {
  background: #f1f1f1;
  overflow: hidden;
}

.m_hero_visual .m_hero_visual_inner {
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
}

.m_hero_visual .m_hero_visual_object_01 {
  width: 200px;
  height: 200px;
  border-width: 10px;
  border-style: solid;
  border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAvUlEQVRIie2T3QqFIBCEx82SIqr3f8VuIrQfrDyM4HmBvVVYVvxcltllzLqu6TxPDMOAeZ6RUsL3fbDW5vu2bdBwSziOY/7AB562bRFjhPceWi4EjNLRGIPneRBCwHEc0HITY0wEjKZp8L4v9n3Hfd9wzmFZFmi4FFkikiFlXdeFruv+M9Vw4ULYmZmdKavve0zTlIu0XDi7spAii0vjojhLNadEwtI5yxLJRcxaXn1QfVB9UH1QfVB9ICL4Ab0IpD4OPD29AAAAAElFTkSuQmCC) 8px round;
  background: pink;
}

.m_index_employee {
  margin-top: 170px;
  background: url(data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAABQAAD/7gAOQWRvYmUAZMAAAAAB/9sAhAACAgICAgICAgICAwICAgMEAwICAwQFBAQEBAQFBgUFBQUFBQYGBwcIBwcGCQkKCgkJDAwMDAwMDAwMDAwMDAwMAQMDAwUEBQkGBgkNCwkLDQ8ODg4ODw8MDAwMDA8PDAwMDAwMDwwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAIAAgDAREAAhEBAxEB/8QAVwABAAAAAAAAAAAAAAAAAAAACQEBAAAAAAAAAAAAAAAAAAAAABAAAAILAQAAAAAAAAAAAAAAMRMAAREhQQIyIwQ0BSURAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AHOp8zmWy9vLEtovjOtA/9k=);
}

@media (max-width: 640px) {
  .m_index_employee {
    margin-top: 80px;
    background-size: 4px 4px;
  }
}

.m_topics_banner {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .m_topics_banner {
    display: block;
  }
}

.m_topics_banner .m_topics_banner_item {
  width: calc(50% - 15px);
}

@media (max-width: 768px) {
  .m_topics_banner .m_topics_banner_item {
    width: calc(50% - 10px);
  }
}

@media (max-width: 640px) {
  .m_topics_banner .m_topics_banner_item {
    width: auto;
  }
  .m_topics_banner .m_topics_banner_item:nth-child(2) {
    margin-top: 5px;
  }
}

.m_topics_banner_link {
  position: relative;
  display: block;
  transition: opacity 0.3s ease;
}

.m_topics_banner_link .m_topics_banner_link_title {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
  text-align: center;
  z-index: 1;
  color: #fff;
  font-size: 20px;
  letter-spacing: 0.1em;
  padding-left: 0.1em;
}

.m_topics_banner_link .m_topics_banner_link_thumbnail {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.m_topics_banner_link .m_topics_banner_link_thumbnail::before {
  content: '';
  display: block;
  padding-top: 34.21%;
}

@media (min-width: 769px) {
  .m_topics_banner_link:hover {
    opacity: 0.4;
  }
}

.m_topics_list .m_topics_list_item + .m_topics_list_item {
  margin-top: 10px;
  padding-top: 10px;
  border-top: dashed 1px #a3a3a3;
}

.m_topics_list_link {
  display: flex;
  align-items: flex-start;
  transition: opacity 0.3s ease;
}

@media (max-width: 640px) {
  .m_topics_list_link {
    flex-wrap: wrap;
  }
}

.m_topics_list_link > ._date {
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  margin-top: 1.5px;
}

.m_topics_list_link > ._label {
  display: inline-block;
  background: #bbb;
  color: #fff;
  font-size: 13px;
  border-radius: 3px;
  margin-right: 10px;
  font-size: 11px;
  line-height: 1;
  width: 100px;
  margin-top: 2.5px;
  padding: 3px 10px;
  text-align: center;
  font-family: "Open Sans", Robots, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, Meiryo, "メイリオ", sans-serif;
}

.m_topics_list_link > ._title {
  display: inline-block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  font-size: 15px;
}

@media (max-width: 640px) {
  .m_topics_list_link > ._title {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    width: 100%;
    margin-top: 5px;
    font-size: 13px;
  }
}

@media (min-width: 769px) {
  .m_topics_list_link:hover:not(.is_title_only) {
    opacity: 0.6;
  }
}

.m_employee_slider .m_employee_slider_item .m_employee_link {
  position: relative;
  transform: scale(0.8);
  transition: all 0.3s ease;
}

.m_employee_slider .slick-list {
  padding-bottom: 90px !important;
}

.m_employee_slider .slick-slide.slick-center .m_employee_link {
  z-index: 1;
  transform: scale(1);
  box-shadow: 0 50px 80px -30px rgba(0, 0, 0, 0.4);
}

.m_employee_slider .slick-slide.slick-center .m_employee_link_detail {
  opacity: 1;
}

.m_employee_link {
  display: block;
}

.m_employee_link .m_employee_link_thumbnail {
  width: 370px;
  background-size: cover;
}

@media (max-width: 1024px) {
  .m_employee_link .m_employee_link_thumbnail {
    width: 36.13vw;
  }
}

@media (max-width: 768px) {
  .m_employee_link .m_employee_link_thumbnail {
    width: 48.17vw;
  }
}

@media (max-width: 640px) {
  .m_employee_link .m_employee_link_thumbnail {
    width: 57.81vw;
  }
}

.m_employee_link .m_employee_link_thumbnail::after {
  content: '';
  display: block;
  padding-top: 129.73%;
}

.m_employee_link .m_employee_link_detail {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: 175px;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s;
}

@media (max-width: 640px) {
  .m_employee_link .m_employee_link_detail {
    bottom: -10px;
    right: -10px;
    padding: 15px;
  }
}

.m_employee_link .m_employee_link_label {
  font-size: 15px;
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.m_employee_link .m_employee_link_label.is_recruit {
  color: #ff9600;
  background-image: -webkit-linear-gradient(0deg, #ff9600, #ff6600);
}

.m_employee_link .m_employee_link_label.is_career {
  color: #00aeff;
  background-image: -webkit-linear-gradient(0deg, #00aeff, #003cff);
}

.m_employee_link .m_employee_link_position {
  margin-top: 5px;
  color: #fff;
  font-size: 22px;
}

@media (max-width: 640px) {
  .m_employee_link .m_employee_link_position {
    font-size: 18px;
  }
}

.m_employee_link .m_employee_link_name {
  font-size: 14px;
  color: #d9d9d9;
  line-height: 1;
  margin-top: 10px;
}

.m_employee_link .m_employee_link_arrow {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 30px;
}

.m_challange_wrapper {
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: content-box !important;
}

@media (max-width: 640px) {
  .m_challange_wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

.m_challange {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .m_challange {
    display: block;
  }
}

.m_challange .m_challange_item {
  width: calc(50% - 20px);
}

@media (max-width: 880px) {
  .m_challange .m_challange_item {
    width: calc(50% - 15px);
  }
}

@media (max-width: 768px) {
  .m_challange .m_challange_item {
    max-width: 640px;
    width: auto;
  }
}

@media (max-width: 768px) {
  .m_challange .m_challange_item + .m_challange_item {
    margin-left: auto;
    margin-top: 10px;
  }
}

.m_challange_link {
  display: block;
  position: relative;
  overflow: hidden;
}

.m_challange_link .m_challange_link_textbox {
  position: absolute;
  left: 0;
  bottom: 40px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  z-index: 1;
}

@media (max-width: 1024px) {
  .m_challange_link .m_challange_link_textbox {
    bottom: 3.9vw;
  }
}

@media (max-width: 768px) {
  .m_challange_link .m_challange_link_textbox {
    bottom: 40px;
  }
}

@media (max-width: 480px) {
  .m_challange_link .m_challange_link_textbox {
    bottom: 8.33vw;
  }
}

.m_challange_link .m_challange_link_number {
  background: #fff;
  color: #383838;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 20px;
  font-weight: bold;
}

@media (max-width: 1024px) {
  .m_challange_link .m_challange_link_number {
    font-size: 1.95vw;
    padding-left: 3.9vw;
    padding-right: 3.9vw;
  }
}

@media (max-width: 768px) {
  .m_challange_link .m_challange_link_number {
    padding-left: 40px;
    padding-right: 40px;
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .m_challange_link .m_challange_link_number {
    font-size: 4.16vw;
    padding-left: 8.33vw;
    padding-right: 8.33vw;
  }
}

.m_challange_link .m_challange_link_title {
  background: #383838;
  color: #fff;
  margin-top: 10px;
  padding-left: 40px;
  padding-right: 50px;
  font-size: 28px;
  font-weight: bold;
}

@media (max-width: 1024px) {
  .m_challange_link .m_challange_link_title {
    font-size: 2.73vw;
    margin-top: 0.97vw;
    padding-left: 3.9vw;
    padding-right: 4.88vw;
  }
}

@media (max-width: 768px) {
  .m_challange_link .m_challange_link_title {
    margin-top: 10px;
    padding-left: 40px;
    padding-right: 50px;
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .m_challange_link .m_challange_link_title {
    font-size: 5.83vw;
    margin-top: 2.08vw;
    padding-left: 8.33vw;
    padding-right: 10.41vw;
  }
}

.m_challange_link .m_challange_link_thumbnail {
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 0;
  max-height: 310px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.m_challange_link .m_challange_link_thumbnail::before {
  content: '';
  display: block;
  padding-top: 53.44828%;
}

.m_challange_link .m_challange_link_thumbnail img {
  width: 100%;
}

@media (min-width: 769px) {
  .m_challange_link:hover .m_challange_link_number {
    color: #f5ae19;
    background: #fef3dd;
  }
  .m_challange_link:hover .m_challange_link_title {
    color: #fef3dd;
    background: #f5ae19;
  }
  .m_challange_link:hover .m_challange_link_thumbnail {
    transform: scale(1.1);
    opacity: 0.6;
  }
}

.m_8ing_report_header {
  padding-top: 60px;
  padding-bottom: 135px;
  background: url(/_materials/images/top/8ing_report_background.jpg);
  background-size: cover;
}

@media (max-width: 640px) {
  .m_8ing_report_header {
    padding-top: 35px;
    padding-bottom: 115px;
  }
}

.m_8ing_report_more {
  width: 90%;
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
}

.m_footer_front_only {
  display: none;
}

.m_timeline {
  background: #191919;
  padding-top: 70px;
  padding-bottom: 70px;
}

.m_timeline .m_timeline_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .m_timeline .m_timeline_inner {
    display: block;
  }
}

.m_timeline .m_timeline_item {
  overflow: hidden;
  width: calc(50% - 20px);
}

@media (max-width: 768px) {
  .m_timeline .m_timeline_item {
    width: auto;
    max-width: 460px;
    margin-right: auto;
    margin-left: auto;
  }
  .m_timeline .m_timeline_item + .m_timeline_item {
    margin-top: 40px;
  }
}

.m_8ing_report {
  background: #f2f2f2;
  padding-bottom: 120px;
}

.m_8ing_report_contnts {
  max-width: 1040px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  margin-top: -80px;
  overflow: hidden;
}

.m_8ing_report_contnts .m_8ing_report_contnts_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -25px;
  margin-right: -25px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 768px) {
  .m_8ing_report_contnts .m_8ing_report_contnts_inner {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 640px) {
  .m_8ing_report_contnts .m_8ing_report_contnts_inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.m_8ing_report_contnts .m_8ing_report_contnts_column {
  width: calc(50% - 50px);
  margin-top: 30px;
  margin-left: 25px;
  margin-right: 25px;
  display: inline-block;
  background: #fff;
  padding-top: 35px;
  padding-bottom: 40px;
}

@media (max-width: 768px) {
  .m_8ing_report_contnts .m_8ing_report_contnts_column {
    width: auto;
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .m_8ing_report_contnts .m_8ing_report_contnts_column {
    margin-top: 5px;
  }
}

.m_8ing_report_contnts_title {
  background: #333;
  color: #fff;
  margin-right: 20px;
  font-size: 24px;
  padding-left: 20px;
  padding-top: 10px;
  margin-left: -20px;
  padding-right: 20px;
  padding-bottom: 10px;
  font-weight: normal;
}

@media (max-width: 640px) {
  .m_8ing_report_contnts_title {
    font-size: 20px;
    padding-left: 15px;
    margin-left: -15px;
    padding-right: 15px;
  }
}

@media (max-width: 480px) {
  .m_8ing_report_contnts_title {
    font-size: 18px;
  }
}

.m_8ing_report_list {
  margin-top: 30px;
}

.m_8ing_report_list .m_8ing_report_list_item {
  padding-right: 30px;
  padding-left: 30px;
}

@media (max-width: 480px) {
  .m_8ing_report_list .m_8ing_report_list_item {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.m_8ing_report_list .m_8ing_report_list_item + .m_8ing_report_list_item {
  margin-top: 30px;
  padding-top: 30px;
  border-top: solid 1px #f2f2f2;
}

@media (max-width: 640px) {
  .m_8ing_report_list .m_8ing_report_list_item + .m_8ing_report_list_item {
    margin-top: 20px;
    padding-top: 20px;
  }
}

.m_8ing_report_link {
  display: flex;
  align-items: flex-start;
}

.m_8ing_report_link .m_8ing_report_link_thumbnail {
  width: 120px;
  margin-right: 25px;
  background-size: cover;
  background-position: center;
}

@media (max-width: 480px) {
  .m_8ing_report_link .m_8ing_report_link_thumbnail {
    margin-right: 15px;
    width: 25vw;
  }
}

.m_8ing_report_link .m_8ing_report_link_thumbnail::before {
  content: '';
  display: block;
  padding-top: 83.33%;
}

.m_8ing_report_link .m_8ing_report_link_detail {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}

.m_8ing_report_link .m_8ing_report_link_title {
  font-size: 16px;
  letter-spacing: 0.1em;
  margin-right: -0.1em;
}

@media (max-width: 480px) {
  .m_8ing_report_link .m_8ing_report_link_title {
    font-size: 14px;
  }
}

.m_8ing_report_link .m_8ing_report_link_text {
  margin-top: 5px;
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-right: -0.1em;
  overflow: hidden;
  position: relative;
}

@media (max-width: 480px) {
  .m_8ing_report_link .m_8ing_report_link_text {
    font-size: 13px;
  }
}

.m_8ing_report_link .m_8ing_report_link_footer {
  margin-top: 25px;
}

@media (max-width: 640px) {
  .m_8ing_report_link .m_8ing_report_link_footer {
    margin-top: 10px;
  }
}

.m_8ing_report_link .m_8ing_report_link_post_date {
  letter-spacing: 0.1em;
  font-size: 12px;
  color: #999;
}

@media (min-width: 769px) {
  .m_8ing_report_link:hover {
    background: #f9d07b;
    box-shadow: 0 0 0 10px #f9d07b;
  }
}

.m_8ing_report_contnts_more {
  margin-top: 40px;
  max-width: 270px;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  line-height: 1;
}

.m_8ing_report_contnts_more .m_btn_border_arrow {
  height: 55px;
  padding-left: 32px;
}

.m_8ing_report_contnts_more .m_btn_border_arrow > ._icon {
  width: 32px;
}

.m_maivisual_underlayer {
  display: flex;
  align-items: center;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.m_maivisual_underlayer::before {
  content: '';
  display: block;
  width: 0%;
  height: 240px;
}

@media (max-width: 640px) {
  .m_maivisual_underlayer::before {
    height: 160px;
  }
}

.m_maivisual_underlayer .m_maivisual_underlayer_inner {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.m_maivisual_underlayer .m_maivisual_underlayer_title {
  font-size: 52px;
  font-family: "Century Gothic", Robots, Helvetica, arial, sans-serif;
  word-break: break-all;
  line-height: 1.2;
}

@media (max-width: 640px) {
  .m_maivisual_underlayer .m_maivisual_underlayer_title {
    font-size: 46px;
  }
}

@media (max-width: 480px) {
  .m_maivisual_underlayer .m_maivisual_underlayer_title {
    font-size: 40px;
  }
}

@media (max-width: 375px) {
  .m_maivisual_underlayer .m_maivisual_underlayer_title {
    font-size: 32px;
  }
}

.m_maivisual_underlayer .m_maivisual_underlayer_sub {
  margin-top: 5px;
  font-size: 20px;
}

@media (max-width: 640px) {
  .m_maivisual_underlayer .m_maivisual_underlayer_sub {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .m_maivisual_underlayer .m_maivisual_underlayer_sub {
    font-size: 16px;
  }
}

.m_maivisual_underlayer.is_career {
  background-image: url(/_materials/images/common/visual/career.jpg);
}

.m_maivisual_underlayer.is_company {
  background-image: url(/_materials/images/common/visual/company.jpg);
}

.m_maivisual_underlayer.is_employee {
  background-image: url(/_materials/images/common/visual/employee.jpg);
}

.m_maivisual_underlayer.is_faq {
  background-image: url(/_materials/images/common/visual/faq.jpg);
}

.m_maivisual_underlayer.is_project {
  background-image: url(/_materials/images/common/visual/project.jpg);
}

.m_maivisual_underlayer.is_recruit {
  background-image: url(/_materials/images/common/visual/recruit.jpg);
}

.m_maivisual_underlayer.is_report {
  background-image: url(/_materials/images/common/visual/report.jpg);
}

.m_report_category {
  overflow: hidden;
}

.m_report_category .m_report_category_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -20px;
}

@media (max-width: 640px) {
  .m_report_category .m_report_category_inner {
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -10px;
  }
}

.m_report_category .m_report_category_btn {
  width: calc(50% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  background: #eee;
  color: #999;
}

@media (max-width: 640px) {
  .m_report_category .m_report_category_btn {
    width: calc(50% - 10px);
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
  }
}

.m_report_category .m_report_category_btn::before {
  content: '';
  display: block;
  width: 0%;
  height: 55px;
}

@media (max-width: 480px) {
  .m_report_category .m_report_category_btn::before {
    height: 45px;
  }
}

.m_report_category .m_report_category_btn ._text {
  width: 100%;
  padding: 10px;
  font-size: 22px;
  text-align: center;
}

@media (max-width: 640px) {
  .m_report_category .m_report_category_btn ._text {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .m_report_category .m_report_category_btn ._text {
    font-size: 18px;
  }
}

@media (max-width: 375px) {
  .m_report_category .m_report_category_btn ._text {
    font-size: 4.26vw;
  }
}

@media (min-width: 769px) {
  .m_report_category .m_report_category_btn:not(.is_current):hover {
    background: #f9d07b;
  }
}

.m_report_category .m_report_category_btn.is_current {
  background: #333;
  color: #fff;
}

.m_report_archive {
  border-top: solid 4px #333;
  padding-top: 60px;
}

.m_report_archive .m_report_archive_item + .m_report_archive_item {
  border-top: solid 2px #f5f6f7;
  margin-top: 40px;
  padding-top: 40px;
}

.m_report_archive_link {
  display: flex;
  align-items: flex-start;
}

.m_report_archive_link .m_report_archive_link_thumbnail {
  width: 25%;
  margin-right: 20px;
  background-size: cover;
  background-position: center;
}

.m_report_archive_link .m_report_archive_link_thumbnail::before {
  content: '';
  display: block;
  padding-top: 61.53846%;
}

.m_report_archive_link .m_report_archive_link_detail {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}

.m_report_archive_link .m_report_archive_link_title {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.6;
  font-size: 18px;
}

@media (max-width: 640px) {
  .m_report_archive_link .m_report_archive_link_title {
    font-size: 14px;
  }
}

.m_report_archive_link .m_report_archive_link_content {
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.6;
}

@media (max-width: 640px) {
  .m_report_archive_link .m_report_archive_link_content {
    font-size: 14px;
  }
}

.m_report_archive_link .m_report_archive_link_date {
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.6;
  margin-top: 20px;
  display: block;
}

@media (max-width: 640px) {
  .m_report_archive_link .m_report_archive_link_date {
    font-size: 14px;
  }
}

.m_report_article_header {
  display: flex;
}

.m_report_article_date {
  font-size: 18px;
  color: #999;
}

@media (max-width: 640px) {
  .m_report_article_date {
    font-size: 16px;
  }
}

.m_report_article_date_category {
  font-size: 18px;
  margin-left: 10px;
}

@media (max-width: 640px) {
  .m_report_article_date_category {
    font-size: 16px;
  }
}

.m_report_article_title {
  margin-top: 20px;
  font-size: 30px;
  border-bottom: solid 3px #666;
  padding-bottom: 20px;
}

@media (max-width: 640px) {
  .m_report_article_title {
    font-size: 26px;
  }
}

@media (max-width: 480px) {
  .m_report_article_title {
    font-size: 22px;
  }
}

.m_article_btn {
  width: 85%;
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
}

.m_report_around_link {
  margin-top: 150px;
}

@media (max-width: 640px) {
  .m_report_around_link {
    margin-top: 80px;
  }
}

.m_report_around_link::after {
  content: '';
  display: block;
  clear: both;
}

.m_report_around_link .m_report_around_link_prev {
  float: left;
}

.m_report_around_link .m_report_around_link_next {
  float: right;
}

.m_prev_link,
.m_next_link {
  display: block;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

.m_prev_link::before,
.m_next_link::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-top: solid 1px #333;
  position: absolute;
  top: 50%;
}

.m_prev_link:hover,
.m_next_link:hover {
  color: #f5ae19;
}

.m_prev_link:hover::before,
.m_next_link:hover::before {
  border-color: #f5ae19 !important;
}

.m_prev_link {
  padding-left: 16px;
}

.m_prev_link::before {
  border-left: solid 1px #333;
  transform-origin: left top;
  transform: rotate(-45deg);
  left: 0;
}

.m_next_link {
  padding-right: 16px;
}

.m_next_link::before {
  border-right: solid 1px #333;
  transform-origin: right top;
  transform: rotate(45deg);
  right: 0;
}

.m_staff_list .m_staff_list_inner {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -60px;
}

@media (max-width: 640px) {
  .m_staff_list .m_staff_list_inner {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -40px;
  }
}

.m_staff_list .m_staff_list_item {
  width: calc(33.33% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 60px;
  animation: fadeUp 0.3s ease both;
}

@media (max-width: 640px) {
  .m_staff_list .m_staff_list_item {
    width: calc(50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 40px;
  }
  .m_staff_list .m_staff_list_item:nth-child(even) {
    padding-top: 60px;
  }
}

.m_staff_list .m_staff_list_item:nth-child(1) {
  animation-delay: 0.2s;
}

.m_staff_list .m_staff_list_item:nth-child(2) {
  animation-delay: 0.4s;
}

.m_staff_list .m_staff_list_item:nth-child(3) {
  animation-delay: 0.6s;
}

.m_staff_list .m_staff_list_item:nth-child(4) {
  animation-delay: 0.8s;
}

.m_staff_list .m_staff_list_item:nth-child(5) {
  animation-delay: 1s;
}

.m_staff_list .m_staff_list_item:nth-child(6) {
  animation-delay: 1.2s;
}

.m_staff_list .m_staff_list_item:nth-child(7) {
  animation-delay: 1.4s;
}

.m_staff_list .m_staff_list_item:nth-child(8) {
  animation-delay: 1.6s;
}

.m_staff_list .m_staff_list_item:nth-child(9) {
  animation-delay: 1.8s;
}

.m_staff_list .m_staff_list_item:nth-child(10) {
  animation-delay: 2s;
}

.m_staff_list .m_staff_list_item:nth-child(11) {
  animation-delay: 2.2s;
}

.m_staff_list .m_staff_list_item:nth-child(12) {
  animation-delay: 2.4s;
}

.m_staff_link {
  overflow: hidden;
  display: block;
  position: relative;
}

.m_staff_link .m_staff_link_thumbnail img {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.m_staff_link .m_staff_link_detail {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.8);
  transition: background 0.3s ease;
}

.m_staff_link .m_staff_link_job {
  color: #fff;
  font-size: 20px;
}

.m_staff_link .m_staff_name {
  font-size: 14px;
}

.m_staff_link .m_staff_name .recruit,
.m_staff_link .m_staff_name .career {
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.m_staff_link .m_staff_name .recruit {
  color: #ff9600;
  background-image: -webkit-linear-gradient(0deg, #ff9600, #ff6600);
}

.m_staff_link .m_staff_name .career {
  color: #00aeff;
  background-image: -webkit-linear-gradient(0deg, #00aeff, #003cff);
}

.m_staff_link .m_staff_name ._name {
  margin-left: 10px;
  color: #d9d9d9;
}

.m_staff_link .m_staff_link_arrow {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30px;
  bottom: 18px;
  right: 15px;
}

@media (min-width: 769px) {
  .m_staff_link:hover .m_staff_link_thumbnail img {
    transform: scale(1.1);
    opacity: 0.8;
  }
  .m_staff_link:hover .m_staff_link_detail {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .m_staff_link:hover .m_staff_link_arrow .m_svg polygon {
    fill: #f5ae19;
  }
}

.m_staff_header {
  margin-top: 100px;
}

@media (max-width: 640px) {
  .m_staff_header {
    margin-top: 60px;
  }
}

.m_staff_recruit_type > ._inner {
  display: block;
  width: calc(((100% - 1000px) / 2) + 290px);
  padding-left: calc(((100% - 1000px) / 2));
  background: #383838;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  padding-top: 5px;
  padding-bottom: 5px;
  letter-spacing: 0.15em;
}

@media (max-width: 1040px) {
  .m_staff_recruit_type > ._inner {
    width: 290px;
    padding-left: 20px;
  }
}

@media (max-width: 640px) {
  .m_staff_recruit_type > ._inner {
    width: calc(50% - 100px);
  }
}

@media (max-width: 480px) {
  .m_staff_recruit_type > ._inner {
    width: 130px;
  }
}

@media (max-width: 375px) {
  .m_staff_recruit_type > ._inner {
    font-size: 14px;
  }
}

.m_staff_title {
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 375px) {
  .m_staff_title {
    margin-top: 5px;
  }
}

.m_staff_title ._inner {
  width: calc(((100% - 1000px) / 2) + 900px);
  padding-left: calc(((100% - 1000px) / 2));
  background: #383838;
  color: #fff;
  font-size: 36px;
  padding-top: 5px;
  padding-bottom: 5px;
  letter-spacing: 0.15em;
}

@media (max-width: 1040px) {
  .m_staff_title ._inner {
    padding-left: 20px;
  }
}

@media (max-width: 880px) {
  .m_staff_title ._inner {
    width: calc(100% - 40px);
  }
}

@media (max-width: 640px) {
  .m_staff_title ._inner {
    padding-left: 15px;
    font-size: 32px;
  }
}

@media (max-width: 480px) {
  .m_staff_title ._inner {
    font-size: 28px;
  }
}

@media (max-width: 375px) {
  .m_staff_title ._inner {
    font-size: 26px;
  }
}

.m_staff_title::first-letter {
  color: #f5ae19;
}

.m_staff_title .m_staff_title_sub {
  font-weight: normal;
  font-size: 50%;
  color: #d9d9d9;
  letter-spacing: 0.05em;
}

@media (max-width: 640px) {
  .m_staff_title .m_staff_title_sub {
    display: none;
  }
}

.m_staff_name_sp {
  display: none;
}

@media (max-width: 640px) {
  .m_staff_name_sp {
    margin-top: 10px;
    display: inline-block;
    min-width: calc(50% - 10px);
    padding-right: 40px;
    padding-left: 15px;
    color: #d9d9d9;
    background: #383838;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media (max-width: 375px) {
  .m_staff_name_sp {
    margin-top: 5px;
    font-size: 14px;
  }
}

.m_qa_list {
  border-bottom: dotted 2px #a3a3a3;
}

.m_qa_list .m_qa_list_item {
  border-top: dotted 2px #a3a3a3;
}

.m_qa_layout {
  transition: background 0.3s ease;
}

.m_qa_layout .m_qa_layout_inner {
  display: flex;
  padding: 20px 30px 20px 20px;
  align-items: baseline;
}

@media (max-width: 640px) {
  .m_qa_layout .m_qa_layout_inner {
    padding: 15px 30px 15px 15px;
  }
}

.m_qa_layout .m_qa_layout_icon {
  font-weight: bold;
  white-space: nowrap;
  font-size: 120%;
  width: 2em;
  font-family: "Century Gothic", Robots, Helvetica, arial, sans-serif;
}

.m_qa_layout .m_qa_layout_text {
  font-weight: normal;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 1.6;
}

@media (max-width: 640px) {
  .m_qa_layout .m_qa_layout_text {
    font-size: 14px;
  }
}

.m_qa_layout.is_head {
  cursor: pointer;
  position: relative;
}

.m_qa_layout.is_head::before, .m_qa_layout.is_head::after {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  background: #666;
  top: 50%;
}

.m_qa_layout.is_head::before {
  width: 14px;
  height: 2px;
  right: 5px;
  transform: translateY(-50%);
  transition: opacity 0.3s ease;
}

.m_qa_layout.is_head::after {
  width: 2px;
  height: 14px;
  right: 12px;
  transform: translate(50%, -50%);
  transition: transform 0.3s ease;
}

.m_qa_layout.is_head.is_active::before {
  opacity: 0;
}

.m_qa_layout.is_head.is_active::after {
  transform: translate(50%, -50%) rotate(90deg);
}

@media (min-width: 769px) {
  .m_qa_layout.is_head:hover {
    background: #f9d07b;
  }
}

.m_qa_layout.is_body {
  display: none;
  background: #f3f3f3;
}

.m_qa_layout.is_body .m_qa_layout_icon {
  color: #ff9600;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: -webkit-linear-gradient(0deg, #ff9600, #ff6600);
}

.m_keywords_label .m_keywords_label_inner {
  font-size: 0;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -15px;
}

@media (max-width: 640px) {
  .m_keywords_label .m_keywords_label_inner {
    margin-left: -3px;
    margin-right: -3px;
    margin-top: -8px;
  }
}

.m_keywords_label .m_keywords_label_item {
  padding: 8px 25px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 15px;
  background: #333;
  color: #fff;
  font-size: 20px;
  display: inline-block;
  font-family: "Open Sans", Robots, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, Meiryo, "メイリオ", sans-serif;
}

@media (max-width: 640px) {
  .m_keywords_label .m_keywords_label_item {
    padding: 3px 10px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 8px;
    font-size: 16px;
  }
}

@media (max-width: 375px) {
  .m_keywords_label .m_keywords_label_item {
    font-size: 14px;
  }
}

.m_welfare_anchor_list {
  overflow: hidden;
}

.m_welfare_anchor_list .m_welfare_anchor_list_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -16px;
}

@media (max-width: 640px) {
  .m_welfare_anchor_list .m_welfare_anchor_list_inner {
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -8px;
  }
}

.m_welfare_anchor_list .m_welfare_anchor_list_item {
  width: calc( 25% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 16px;
}

@media (max-width: 640px) {
  .m_welfare_anchor_list .m_welfare_anchor_list_item {
    width: calc( 33.33% - 20px);
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 8px;
  }
}

@media (max-width: 375px) {
  .m_welfare_anchor_list .m_welfare_anchor_list_item {
    width: calc( 50% - 20px);
  }
}

.m_anchor_link {
  display: flex;
  height: 55px;
  background-color: #ededed;
  padding-left: 20px;
  padding-right: 30px;
  align-items: center;
  position: relative;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.1em;
  transition: background 0.3s ease;
}

@media (max-width: 640px) {
  .m_anchor_link {
    font-size: 16px;
    height: 45px;
    padding-right: 30px;
  }
}

.m_anchor_link::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 7px 0 7px;
  border-color: #333 transparent transparent transparent;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

@media (max-width: 640px) {
  .m_anchor_link::before {
    border-width: 8px 5px 0 5px;
    right: 15px;
  }
}

@media (min-width: 769px) {
  .m_anchor_link:hover {
    background: #f8c862;
  }
}

@media (max-width: 880px) {
  .m_anchor_link.is_recruit_font {
    font-size: 16px;
  }
}

@media (max-width: 640px) {
  .m_anchor_link.is_recruit_font {
    font-size: 14px;
  }
}

.m_welfare_article + .m_welfare_article {
  margin-top: 150px;
}

.m_welfare_article .m_welfare_section + .m_welfare_section {
  margin-top: 95px;
}

.m_welfare_article .m_welfare_title {
  font-size: 34px;
  letter-spacing: 0.2em;
}

@media (max-width: 640px) {
  .m_welfare_article .m_welfare_title {
    font-size: 28px;
    letter-spacing: 0.1em;
  }
}

@media (max-width: 480px) {
  .m_welfare_article .m_welfare_title {
    font-size: 26px;
  }
}

@media (max-width: 375px) {
  .m_welfare_article .m_welfare_title {
    font-size: 24px;
  }
}

.m_welfare_article .m_welfare_layout {
  display: flex;
}

@media (max-width: 640px) {
  .m_welfare_article .m_welfare_layout {
    display: block;
  }
}

.m_welfare_article .m_welfare_detail {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}

.m_welfare_article .m_welfare_thumbnail {
  width: 34%;
  margin-left: 40px;
}

@media (max-width: 640px) {
  .m_welfare_article .m_welfare_thumbnail {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
  }
}

.m_project_article {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  background: #fff;
  padding-bottom: 85px;
  margin-top: 115px;
}

@media (max-width: 640px) {
  .m_project_article {
    margin-top: 60px;
    padding-bottom: 45px;
  }
}

.m_project_article + .m_project_article {
  margin-top: 120px;
}

@media (max-width: 640px) {
  .m_project_article + .m_project_article {
    margin-top: 70px;
  }
}

.m_project_article .m_project_title {
  font-size: 36px;
  position: relative;
  top: -30px;
  margin-bottom: -30px;
  letter-spacing: 0.05em;
}

@media (max-width: 640px) {
  .m_project_article .m_project_title {
    font-size: 32px;
    top: -26px;
    margin-bottom: -26px;
  }
}

@media (max-width: 480px) {
  .m_project_article .m_project_title {
    font-size: 28px;
    top: -22px;
    margin-bottom: -22px;
  }
}

@media (max-width: 375px) {
  .m_project_article .m_project_title {
    font-size: 24px;
    top: -18px;
    margin-bottom: -18px;
  }
}

.m_project_article .m_project_title::after {
  margin-top: 10px;
  content: '';
  display: block;
  width: 180px;
  height: 3px;
  background: linear-gradient(to right, #efac29 50px, #333 50px);
}

@media (max-width: 375px) {
  .m_project_article .m_project_title::after {
    width: 140px;
  }
}

.m_project_article .m_project_name {
  margin-top: 15px;
  letter-spacing: 0.1em;
}

.m_project_article .m_project_photo {
  margin-top: 20px;
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 640px) {
  .m_project_article .m_project_photo {
    margin-top: 15px;
  }
}

.m_project_article .m_project_text {
  margin-top: 35px;
}

@media (max-width: 640px) {
  .m_project_article .m_project_text {
    margin-top: 25px;
  }
}

.m_recruit_list .m_recruit_list_item + .m_recruit_list_item {
  margin-top: 100px;
  padding-top: 100px;
  border-top: solid 1px #ccc;
}

@media (max-width: 640px) {
  .m_recruit_list .m_recruit_list_item + .m_recruit_list_item {
    margin-top: 40px;
    padding-top: 40px;
  }
}

.m_recruit_card {
  display: flex;
}

@media (max-width: 640px) {
  .m_recruit_card {
    display: block;
  }
}

.m_recruit_card .m_recruit_card_icon {
  width: 204px;
  margin-right: 40px;
}

@media (max-width: 768px) {
  .m_recruit_card .m_recruit_card_icon {
    width: 160px;
  }
}

@media (max-width: 640px) {
  .m_recruit_card .m_recruit_card_icon {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 480px) {
  .m_recruit_card .m_recruit_card_icon {
    width: 120px;
  }
}

.m_recruit_card .m_recruit_card_detail {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}

@media (max-width: 640px) {
  .m_recruit_card .m_recruit_card_detail {
    margin-top: 20px;
  }
}

.m_recruit_card .m_recruit_card_title {
  font-size: 34px;
  letter-spacing: 0.1em;
}

@media (max-width: 640px) {
  .m_recruit_card .m_recruit_card_title {
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  .m_recruit_card .m_recruit_card_title {
    font-size: 26px;
  }
}

@media (max-width: 375px) {
  .m_recruit_card .m_recruit_card_title {
    font-size: 22px;
  }
}

.m_recruit_card .m_recruit_card_sub_title {
  color: #f5ae19;
  font-size: 18px;
}

@media (max-width: 480px) {
  .m_recruit_card .m_recruit_card_sub_title {
    font-size: 16px;
  }
}

.m_recruit_card .m_recruit_card_btn {
  max-width: 310px;
  margin-top: 35px;
}

@media (max-width: 640px) {
  .m_recruit_card .m_recruit_card_btn {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 480px) {
  .m_recruit_card .m_recruit_card_btn {
    margin-top: 20px;
  }
}

.m_recruit_anchor {
  overflow: hidden;
}

.m_recruit_anchor .m_recruit_anchor_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -16px;
}

@media (max-width: 640px) {
  .m_recruit_anchor .m_recruit_anchor_inner {
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -10px;
  }
}

@media (max-width: 375px) {
  .m_recruit_anchor .m_recruit_anchor_inner {
    margin-top: -5px;
    display: block;
  }
}

.m_recruit_anchor .m_recruit_anchor_item {
  width: calc(33.33% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 16px;
}

@media (max-width: 768px) {
  .m_recruit_anchor .m_recruit_anchor_item {
    width: calc(50% - 20px);
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 640px) {
  .m_recruit_anchor .m_recruit_anchor_item {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
  }
}

@media (max-width: 375px) {
  .m_recruit_anchor .m_recruit_anchor_item {
    margin-top: 5px;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.m_select_flow .m_select_flow_item + .m_select_flow_item {
  margin-top: 40px;
  position: relative;
}

@media (max-width: 640px) {
  .m_select_flow .m_select_flow_item + .m_select_flow_item {
    margin-top: 30px;
  }
}

.m_select_flow .m_select_flow_item + .m_select_flow_item::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 17px 0 17px;
  border-color: #f5ae19 transparent transparent transparent;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 640px) {
  .m_select_flow .m_select_flow_item + .m_select_flow_item::after {
    top: -15px;
    border-width: 12px 10px 0 10px;
  }
}

.m_select_flow_box_sub {
  background: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
  display: none;
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 640px) {
  .m_select_flow_box_sub {
    display: block;
  }
}

@media (max-width: 375px) {
  .m_select_flow_box_sub {
    font-size: 15px;
  }
}

.m_select_flow_box {
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 640px) {
  .m_select_flow_box {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.m_select_flow_box .m_select_flow_box_inner {
  max-width: 590px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
  font-size: 24px;
  letter-spacing: 0.05em;
  padding-left: 0.05em;
  font-weight: bold;
}

@media (max-width: 640px) {
  .m_select_flow_box .m_select_flow_box_inner {
    height: 70px;
    font-size: 20px;
  }
}

@media (max-width: 375px) {
  .m_select_flow_box .m_select_flow_box_inner {
    font-size: 16px;
    height: 55px;
  }
}

.m_select_flow_box .m_select_flow_box_inner > ._icon {
  position: absolute;
  left: 0;
  width: 64px;
  display: block;
  text-align: center;
}

@media (max-width: 640px) {
  .m_select_flow_box .m_select_flow_box_inner > ._icon {
    width: 58px;
  }
}

@media (max-width: 440px) {
  .m_select_flow_box .m_select_flow_box_inner > ._icon {
    width: 52px;
  }
}

@media (max-width: 375px) {
  .m_select_flow_box .m_select_flow_box_inner > ._icon {
    width: 38px;
  }
}

.m_select_flow_box .m_select_flow_box_inner > ._icon.is_icon_01 img {
  width: 81.25%;
}

.m_select_flow_box .m_select_flow_box_inner > ._icon.is_icon_02 img {
  width: 65.625%;
}

.m_select_flow_box .m_select_flow_box_inner > ._icon.is_icon_03 img {
  width: 65.625%;
}

.m_select_flow_box .m_select_flow_box_inner > ._icon.is_icon_05 img {
  width: 68.75%;
}

.m_select_flow_box .m_select_flow_box_inner > ._text {
  width: 100%;
  display: block;
  text-align: center;
}

.m_select_flow_box .m_select_flow_box_inner > ._text > ._sub {
  margin-left: 15px;
  font-size: 85%;
}

@media (max-width: 640px) {
  .m_select_flow_box .m_select_flow_box_inner > ._text > ._sub {
    display: none;
  }
}

.m_select_flow_box .m_select_flow_box_inner .m_select_note {
  font-size: 14px;
}

@media (max-width: 640px) {
  .m_select_flow_box .m_select_flow_box_inner .m_select_note {
    font-size: 12px;
  }
}

@media (max-width: 375px) {
  .m_select_flow_box .m_select_flow_box_inner .m_select_note {
    font-size: 10px;
  }
}

.m_band_tilte_up {
  position: relative;
  top: -28px;
  margin-bottom: -28px;
}

@media (max-width: 640px) {
  .m_band_tilte_up {
    top: -26px;
    margin-bottom: -26px;
  }
}

@media (max-width: 480px) {
  .m_band_tilte_up {
    top: -23px;
    margin-bottom: -23px;
  }
}

@media (max-width: 375px) {
  .m_band_tilte_up {
    top: -5.34vw;
    margin-bottom: -5.34vw;
  }
}

.m_recruit_banner {
  display: flex;
  justify-content: center;
}

@media (max-width: 640px) {
  .m_recruit_banner {
    display: block;
    width: 90%;
    max-width: 320px;
    margin-right: auto;
    margin-left: auto;
  }
}

.m_recruit_banner .m_recruit_banner_item {
  width: calc(50% - 10px);
}

@media (max-width: 640px) {
  .m_recruit_banner .m_recruit_banner_item {
    width: auto;
  }
}

.m_recruit_banner .m_recruit_banner_item + .m_recruit_banner_item {
  margin-left: 20px;
}

@media (max-width: 640px) {
  .m_recruit_banner .m_recruit_banner_item + .m_recruit_banner_item {
    margin-left: 0;
    margin-top: 10px;
  }
}

.m_recruit_banner img {
  width: 100%;
}

.m_anchor_colum2 .m_anchor_colum2_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -10px;
}

@media (max-width: 480px) {
  .m_anchor_colum2 .m_anchor_colum2_inner {
    display: block;
    margin-left: 0;
    margin-right: 0;
    margin-top: -5px;
  }
}

.m_anchor_colum2 .m_anchor_colum2_item {
  width: calc( 50% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

@media (max-width: 480px) {
  .m_anchor_colum2 .m_anchor_colum2_item {
    width: auto;
    margin-top: 5px;
    margin-left: 0;
    margin-right: 0;
  }
}

.requirements_table {
  border-bottom: solid 1px #ccc;
}

.requirements_table .requirements_table_row {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  border-top: solid 1px #ccc;
}

@media (max-width: 640px) {
  .requirements_table .requirements_table_row {
    display: block;
  }
}

.requirements_table .requirements_table_title {
  width: 140px;
  margin-right: 20px;
  font-size: 18px;
  color: #f5ae19;
}

@media (max-width: 640px) {
  .requirements_table .requirements_table_title {
    width: auto;
    font-size: 16px;
  }
}

.requirements_table .requirements_table_detail {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}

@media (max-width: 640px) {
  .requirements_table .requirements_table_detail {
    margin-top: 10px;
  }
}

.m_sitemap_layout {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .m_sitemap_layout {
    display: block;
  }
}

.m_sitemap_layout .m_sitemap_layout_column + .m_sitemap_layout_column {
  margin-left: 40px;
}

@media (max-width: 640px) {
  .m_sitemap_layout .m_sitemap_layout_column + .m_sitemap_layout_column {
    margin-left: 0;
    margin-top: 30px;
  }
}

.m_sitemap_sub .m_sitemap_sub_inner {
  margin-top: -15px;
}

@media (max-width: 640px) {
  .m_sitemap_sub .m_sitemap_sub_inner {
    font-size: 0;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -10px;
  }
}

.m_sitemap_sub .m_sitemap_sub_item {
  margin-top: 15px;
}

@media (max-width: 640px) {
  .m_sitemap_sub .m_sitemap_sub_item {
    display: inline-block;
    margin-top: 10px;
    font-size: 16px;
    width: calc(33.33% - 10px);
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media (max-width: 480px) {
  .m_sitemap_sub .m_sitemap_sub_item {
    width: calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .m_sitemap_sub .m_sitemap_sub_item.is_wide {
    width: calc(100% - 10px);
  }
}

.m_inline_list {
  overflow: hidden;
}

.m_inline_list .m_inline_list_inner {
  margin-right: -80px;
  margin-top: -15px;
  font-size: 0;
}

@media (max-width: 640px) {
  .m_inline_list .m_inline_list_inner {
    margin-right: 0;
  }
}

.m_inline_list .m_inline_list_item {
  display: inline-block;
  margin-right: 80px;
  font-size: 16px;
  margin-top: 15px;
}

@media (max-width: 640px) {
  .m_inline_list .m_inline_list_item {
    margin-right: 0;
    width: 33.33%;
  }
}

@media (max-width: 480px) {
  .m_inline_list .m_inline_list_item {
    margin-right: 0;
    width: 50%;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

@media (max-width: 640px) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: Robots, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, Meiryo, "メイリオ", sans-serif;
  line-height: 1.4;
  background-color: #fff;
  color: #333;
  max-width: 2000px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  font-size: 1rem;
  padding-top: 80px;
}

@media (max-width: 640px) {
  body {
    padding-top: 70px;
    padding-bottom: 65px;
  }
}

@media (max-width: 375px) {
  body {
    padding-top: 60px;
  }
}

body.IS_BODY_FIXED {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

a {
  color: initial;
  color: #333;
  text-decoration: none;
  outline: none;
}

ul {
  list-style: none;
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

sup {
  font-size: 50%;
  vertical-align: top;
  vertical-align: text-top;
}

.l_header {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10000;
  transform: translate3d(0, 0, 0);
  transition: background 0.3s ease;
  background: #222;
}

.l_header_inner {
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}

@media (max-width: 640px) {
  .l_header_inner {
    padding-left: 15px;
    padding-right: 15px;
    height: 70px;
  }
}

@media (max-width: 375px) {
  .l_header_inner {
    height: 60px;
  }
}

.l_header_logo {
  position: relative;
  display: flex;
  align-items: center;
}

.l_header_logo .l_header_logo_icon {
  width: 80px;
}

@media (max-width: 480px) {
  .l_header_logo .l_header_logo_icon {
    width: 60px;
  }
}

.l_header_logo .l_header_logo_icon .m_svg path,
.l_header_logo .l_header_logo_icon .m_svg rect,
.l_header_logo .l_header_logo_icon .m_svg g {
  fill: #fff !important;
}

.l_header_logo .l_header_logo_text {
  margin-left: 20px;
  font-size: 18px;
  white-space: nowrap;
  color: #fff;
  font-family: "Century Gothic", Robots, Helvetica, arial, sans-serif;
  font-weight: bold;
}

@media (max-width: 480px) {
  .l_header_logo .l_header_logo_text {
    font-size: 17px;
    margin-left: 10px;
    margin-top: 8px;
  }
}

.l_header_right {
  display: flex;
  align-items: center;
}

.l_header_recruit {
  width: 410px;
  display: flex;
}

@media (max-width: 768px) {
  .l_header_recruit {
    width: 310px;
  }
}

@media (max-width: 640px) {
  .l_header_recruit {
    display: none;
  }
}

.l_header_recruit .l_header_recruit_item {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}

.l_header_recruit .l_header_recruit_item + .l_header_recruit_item {
  margin-left: 10px;
}

.l_recruit_btn {
  background: #000;
  color: #fff;
  font-family: "Century Gothic", Robots, Helvetica, arial, sans-serif;
  font-size: 18px;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .l_recruit_btn {
    height: 40px;
    font-size: 16px;
  }
}

@media (max-width: 440px) {
  .l_recruit_btn {
    font-size: 14px;
  }
}

@media (max-width: 375px) {
  .l_recruit_btn {
    height: 35px;
  }
}

.l_recruit_btn > ._text {
  margin-right: -0.2em;
  letter-spacing: 0.2em;
}

@media (max-width: 640px) {
  .l_recruit_btn > ._text {
    margin-right: -0.15em;
    letter-spacing: 0.15em;
  }
}

@media (min-width: 769px) {
  .l_recruit_btn:hover {
    background: #f7bf4a;
  }
}

.l_header_menu_btn {
  display: block;
  width: 40px;
  height: 40px;
  margin-left: 20px;
  position: relative;
  cursor: pointer;
}

@media (max-width: 640px) {
  .l_header_menu_btn {
    width: 45px;
    height: 45px;
    margin-left: 15px;
  }
}

.l_header_menu_btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3D(-50%, -50%, 0);
  width: 40%;
  height: 40%;
  opacity: 0;
  background: rgba(247, 191, 74, 0.8);
  border-radius: 50%;
  transition: width 0.3s ease, height 0.3s ease, opacity 0.3s ease;
}

.l_header_menu_btn > ._bar {
  display: block;
  background: #fff;
  position: absolute;
  width: 80%;
  height: 3px;
  left: 50%;
}

@media (max-width: 640px) {
  .l_header_menu_btn > ._bar {
    width: 75%;
    height: 3px;
  }
}

.l_header_menu_btn > ._bar:nth-child(1) {
  top: 10px;
  transform: translate(-50%, -50%);
  transition: top 0.15s  0.15s ease, transform 0.15s 0s ease;
}

@media (max-width: 640px) {
  .l_header_menu_btn > ._bar:nth-child(1) {
    top: 11px;
  }
}

body.VIEW_NAVIGATION .l_header_menu_btn > ._bar:nth-child(1) {
  top: 50%;
  transition: top 0.15s  0s ease, transform 0.15s 0.15s ease;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.l_header_menu_btn > ._bar:nth-child(2) {
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0s 0.15s linear;
}

body.VIEW_NAVIGATION .l_header_menu_btn > ._bar:nth-child(2) {
  opacity: 0;
}

.l_header_menu_btn > ._bar:nth-child(3) {
  bottom: 10px;
  transform: translate(-50%, 50%);
  transition: bottom 0.15s  0.15s ease, transform 0.15s 0s ease;
}

@media (max-width: 640px) {
  .l_header_menu_btn > ._bar:nth-child(3) {
    bottom: 11px;
  }
}

body.VIEW_NAVIGATION .l_header_menu_btn > ._bar:nth-child(3) {
  bottom: 50%;
  transition: bottom 0.15s  0s ease, transform 0.15s 0.15s ease;
  transform: translate(-50%, 50%) rotate(45deg);
}

@media (min-width: 769px) {
  .l_header_menu_btn {
    transition: background 0.3s ease;
  }
  .l_header_menu_btn:hover::before {
    width: 120%;
    height: 120%;
    opacity: 1;
  }
}

.l_navigation {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 5000;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  display: none;
}

body.VIEW_NAVIGATION .l_navigation {
  display: block;
}

body.VIEW_NAVIGATION .l_navigation {
  display: block;
  animation: fade_in 0.3s ease both;
}

body.NAVIGATION_CLOSE_ANIMATION .l_navigation {
  animation: navigation_close 0.3s ease both;
}

.l_navigation_scroller {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.l_navigation_scroller .ps__rail-y {
  margin-top: 90px;
  background: #eee;
  opacity: 1 !important;
  width: 6px !important;
}

@media (max-width: 640px) {
  .l_navigation_scroller .ps__rail-y {
    margin-top: 70px;
  }
}

@media (max-width: 375px) {
  .l_navigation_scroller .ps__rail-y {
    margin-top: 60px;
  }
}

.l_navigation_scroller .ps__thumb-y {
  background: #999 !important;
  width: 6px !important;
  right: 0;
  opacity: 1 !important;
}

.l_navigation_container {
  padding-top: 90px;
  padding-bottom: 60px;
  background: rgba(34, 34, 34, 0.9);
  overflow: hidden;
}

.l_navigation_inner {
  max-width: 1000px;
  box-sizing: content-box;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 640px) {
  .l_navigation_inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.l_navigation_title {
  font-size: 0;
  font-size: 42px;
  line-height: 1;
}

@media (max-width: 1024px) {
  .l_navigation_title {
    font-size: 36px;
  }
}

@media (max-width: 880px) {
  .l_navigation_title {
    font-size: 30px;
  }
}

@media (max-width: 640px) {
  .l_navigation_title {
    font-size: 26px;
  }
}

@media (max-width: 440px) {
  .l_navigation_title {
    font-size: 5.9vw;
  }
}

.l_navigation_title > ._en {
  color: #fff;
  font-weight: bold;
  font-family: "Century Gothic", Robots, Helvetica, arial, sans-serif;
  letter-spacing: 0.05em;
  transition: color 0.3s ease;
}

.l_navigation_title > ._jp {
  color: #f5ae19;
  font-size: 35.71%;
  font-weight: normal;
  margin-left: 10px;
  letter-spacing: 0.1em;
  transition: color 0.3s ease;
}

@media (max-width: 440px) {
  .l_navigation_title > ._jp {
    margin-left: 4.54vw;
    font-size: 53.85%;
  }
}

.l_navigation_sp_btn {
  display: none;
}

@media (max-width: 640px) {
  .l_navigation_sp_btn {
    display: flex;
    justify-content: space-between;
  }
}

.l_navigation_sp_btn .l_navigation_sp_btn_item {
  width: calc(50% - 5px);
}

.l_navigation_main {
  border-bottom: solid 1px #fff;
  margin-top: 40px;
}

.l_navigation_main_column_2 .l_navigation_main_column_2_item, .l_navigation_main_item {
  padding: 30px 25px;
}

@media (max-width: 480px) {
  .l_navigation_main_column_2 .l_navigation_main_column_2_item, .l_navigation_main_item {
    padding: 20px 10px;
  }
}

.l_navigation_main_column_2 {
  border-top: solid 1px #fff;
  display: flex;
}

@media (max-width: 768px) {
  .l_navigation_main_column_2 {
    display: block;
  }
}

.l_navigation_main_column_2 .l_navigation_main_column_2_item {
  width: 50%;
}

@media (max-width: 768px) {
  .l_navigation_main_column_2 .l_navigation_main_column_2_item {
    width: 100%;
  }
}

.l_navigation_main_column_2 .l_navigation_main_column_2_item:nth-child(even) {
  border-left: solid 1px #fff;
}

@media (max-width: 768px) {
  .l_navigation_main_column_2 .l_navigation_main_column_2_item:nth-child(even) {
    border-left: none;
    border-top: solid 1px #fff;
  }
}

.l_navigation_main_item {
  border-top: solid 1px #fff;
}

.l_navigation_sub_menu {
  overflow: hidden;
  margin-top: 20px;
}

.l_navigation_sub_menu .l_navigation_sub_menu_inner {
  font-size: 0;
  margin-right: -55px;
  margin-top: -10px;
}

@media (max-width: 480px) {
  .l_navigation_sub_menu .l_navigation_sub_menu_inner {
    margin-right: 0;
  }
}

.l_navigation_sub_menu .l_navigation_sub_menu_item {
  display: inline-block;
  font-size: 16px;
  margin-right: 55px;
  margin-top: 10px;
}

@media (max-width: 480px) {
  .l_navigation_sub_menu .l_navigation_sub_menu_item {
    margin-right: 0;
    display: block;
  }
}

.l_navigation_main_link {
  padding-right: 20px;
  position: relative;
  position: relative;
  display: block;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.l_navigation_main_link::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  transform-origin: right top;
  position: absolute;
  top: 50%;
  right: 0;
  transform: rotate(45deg);
  transition: border-color 0.3s ease;
}

@media (min-width: 769px) {
  .l_navigation_main_link:hover {
    background: rgba(64, 64, 64, 0.9);
    box-shadow: 0 0 0 10px rgba(64, 64, 64, 0.9);
  }
  .l_navigation_main_link:hover::before {
    border-color: #f8c862;
  }
  .l_navigation_main_link:hover .l_navigation_title > ._en {
    color: #f8c862;
  }
  .l_navigation_main_link:hover .l_navigation_title > ._jp {
    color: #f8c862;
  }
}

.l_navigation_sub_link {
  color: #fff;
  display: inline-block;
  padding-left: 16px;
  position: relative;
  letter-spacing: 0.2em;
  transition: color 0.3s ease;
}

@media (max-width: 480px) {
  .l_navigation_sub_link {
    font-size: 14px;
  }
}

.l_navigation_sub_link::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  transform-origin: right top;
  position: absolute;
  top: 50%;
  left: 0;
  transform: rotate(45deg);
  transition: border-color 0.3s ease;
}

.l_navigation_sub_link::after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease, background-color 0.3s ease;
}

@media (min-width: 769px) {
  .l_navigation_sub_link:hover {
    color: #f8c862;
  }
  .l_navigation_sub_link:hover::before {
    border-color: #f8c862;
  }
  .l_navigation_sub_link:hover::after {
    background-color: #f8c862;
    width: 100%;
  }
}

.l_navigation_corporate_btn {
  margin-top: 50px;
  max-width: 340px;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}

.l_pagetop {
  text-align: center;
  font-size: 0;
  margin-top: 150px;
  margin-bottom: 150px;
}

@media (max-width: 640px) {
  .l_pagetop {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

.l_pagetop_btn {
  padding-top: 45px;
  display: inline-block;
  font-size: 15px;
  position: relative;
  line-height: 1;
  overflow: hidden;
  cursor: pointer;
}

@media (max-width: 640px) {
  .l_pagetop_btn {
    padding-top: 38px;
    font-size: 13px;
  }
}

.l_pagetop_btn::before {
  content: '';
  display: block;
  width: 48px;
  height: 48px;
  border-top: solid 1px #333;
  border-left: solid 1px #333;
  transform-origin: left top;
  transform: rotate(45deg);
  position: absolute;
  top: 5px;
  left: 50%;
}

@media (min-width: 769px) {
  .l_pagetop_btn::before {
    transition: top 0.3s ease;
  }
}

@media (max-width: 640px) {
  .l_pagetop_btn::before {
    width: 36px;
    height: 36px;
  }
}

@media (min-width: 769px) {
  .l_pagetop_btn:hover::before {
    top: 0;
  }
}

.l_breadcrumb {
  border-top: solid 1px #ccc;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.l_breadcrumb .l_breadcrumb_inner {
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px !important;
  padding-left: 20px !important;
}

@media (max-width: 640px) {
  .l_breadcrumb .l_breadcrumb_inner {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.l_breadcrumb_list {
  font-size: 0;
  margin-right: -10px;
}

.l_breadcrumb_list .l_breadcrumb_list_item {
  display: inline;
  position: relative;
  margin-right: 8px;
  font-size: 14px;
}

@media (max-width: 480px) {
  .l_breadcrumb_list .l_breadcrumb_list_item {
    font-size: 12px;
  }
}

.l_breadcrumb_list .l_breadcrumb_list_item + .l_breadcrumb_list_item::before {
  content: '＞';
  margin-right: 8px;
  font-size: 80%;
  transform: translateY(-0.2em);
}

.l_breadcrumb_list .l_breadcrumb_list_item .l_breadcrumb_title {
  line-height: 1.8;
}

.l_breadcrumb_list .l_breadcrumb_list_item.is_link_none .l_breadcrumb_title {
  color: #8e8e8e;
}

.l_breadcrumb_link {
  display: inline;
}

.l_breadcrumb_link:hover .l_breadcrumb_title {
  border-bottom: solid 1px #333;
}

.l_sitemap {
  background: #d5d5d5;
  padding-top: 55px;
  padding-bottom: 60px;
}

.l_sitemap_inner {
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 1024px) {
  .l_sitemap_inner {
    display: block;
    max-width: 680px;
    margin-right: auto;
    margin-left: auto;
  }
}

.l_sitemap_contents {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  justify-content: space-around;
}

@media (max-width: 768px) {
  .l_sitemap_contents {
    justify-content: space-between;
  }
}

@media (max-width: 640px) {
  .l_sitemap_contents {
    display: block;
  }
}

@media (max-width: 640px) {
  .l_sitemap_contents_column {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.l_sitemap_contents_column:first-child {
  margin-left: 0;
}

@media (max-width: 640px) {
  .l_sitemap_contents_column + .l_sitemap_contents_column {
    border-top: solid 1px #6d6d6d;
    padding-top: 15px;
    margin-top: 15px;
  }
}

.l_sitemap_contents_title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

@media (max-width: 640px) {
  .l_sitemap_contents_title {
    font-size: 18px;
  }
}

@media (max-width: 375px) {
  .l_sitemap_contents_title {
    font-size: 16px;
  }
}

.l_sitemap_category_link {
  display: inline-block;
  position: relative;
  font-size: 0;
}

@media (max-width: 640px) {
  .l_sitemap_category_link {
    display: block;
  }
}

.l_sitemap_category_link > ._text {
  display: inline-block;
  padding-right: 18px;
  font-size: 16px;
}

@media (max-width: 375px) {
  .l_sitemap_category_link > ._text {
    font-size: 16px;
  }
}

.l_sitemap_category_link > ._text::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-top: solid 2px #333;
  border-right: solid 2px #333;
  transform-origin: right top;
  position: absolute;
  top: 50%;
  right: 0;
  transform: rotate(45deg);
}

@media (max-width: 640px) {
  .l_sitemap_category_link > ._text::before {
    width: 10px;
    height: 10px;
    right: 1px;
  }
}

.l_sitemap_category_link::after {
  content: '';
  display: block;
  width: 0;
  left: 0;
  bottom: 0;
  height: 1px;
  background: #333;
  z-index: 1;
}

@media (min-width: 769px) {
  .l_sitemap_category_link::after {
    transition: width 0.3s ease;
  }
}

@media (min-width: 769px) {
  .l_sitemap_category_link:hover::after {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .l_sitemap_achordion_btn {
    padding-right: 30px;
    position: relative;
    cursor: pointer;
  }
  .l_sitemap_achordion_btn::before, .l_sitemap_achordion_btn::after {
    content: '';
    display: block;
    background: #333;
    position: absolute;
    top: 50%;
  }
  .l_sitemap_achordion_btn::before {
    width: 2px;
    height: 12px;
    transform: translate3d(0, -50%, 0);
    right: 5px;
    transition: height 0.3s ease;
  }
  .l_sitemap_achordion_btn::after {
    width: 12px;
    height: 2px;
    margin-top: -1px;
    right: 0;
  }
  .l_sitemap_achordion_btn.is_active::before {
    height: 0;
  }
}

@media (max-width: 640px) {
  .l_sitemap_achordion_body {
    display: none;
  }
}

.l_sitemap_contents_list {
  padding-top: 10px;
}

@media (max-width: 640px) {
  .l_sitemap_contents_list {
    padding-top: 20px;
  }
}

.l_sitemap_contents_list_item + .l_sitemap_contents_list_item {
  margin-top: 5px;
}

@media (max-width: 640px) {
  .l_sitemap_contents_list_item + .l_sitemap_contents_list_item {
    margin-top: 10px;
  }
}

.l_sitemap_link {
  display: inline-block;
  position: relative;
  font-size: 0;
  padding-left: 11px;
}

.l_sitemap_link > ._text {
  display: inline-block;
  font-size: 13px;
}

@media (max-width: 640px) {
  .l_sitemap_link > ._text {
    font-size: 14px;
  }
}

.l_sitemap_link::before {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  border-top: solid 1px #333;
  border-right: solid 1px #333;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.l_sitemap_link::after {
  content: '';
  display: block;
  width: 0;
  left: 0;
  bottom: 0;
  height: 1px;
  background: #333;
  z-index: 1;
  position: absolute;
}

@media (min-width: 769px) {
  .l_sitemap_link::after {
    transition: width 0.3s ease;
  }
}

@media (min-width: 769px) {
  .l_sitemap_link:hover::after {
    width: 100%;
  }
}

.l_sitemap_information {
  width: 450px;
  border-left: solid 1px #6d6d6d;
  padding-left: 50px;
  margin-left: 50px;
}

@media (max-width: 1024px) {
  .l_sitemap_information {
    display: block;
    width: auto;
    border-left: none;
    padding-left: 0;
    margin-left: 0;
    border-top: solid 1px #6d6d6d;
    padding-top: 25px;
    margin-top: 25px;
  }
}

@media (max-width: 640px) {
  .l_sitemap_information {
    padding-top: 0;
    margin-top: 15px;
  }
}

.l_sitemap_information_list {
  overflow: hidden;
}

.l_sitemap_information_list .l_sitemap_information_list_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}

@media (max-width: 1024px) {
  .l_sitemap_information_list .l_sitemap_information_list_inner {
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
  }
}

@media (max-width: 640px) {
  .l_sitemap_information_list .l_sitemap_information_list_inner {
    margin-top: 0;
  }
}

.l_sitemap_information_list .l_sitemap_information_list_item {
  width: calc(50% - 10px);
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

@media (max-width: 1024px) {
  .l_sitemap_information_list .l_sitemap_information_list_item {
    vertical-align: top;
    display: inline-block;
    width: auto;
    position: relative;
    padding-right: 11px;
    margin-left: 0px;
    margin-right: 10px;
  }
}

@media (max-width: 640px) {
  .l_sitemap_information_list .l_sitemap_information_list_item {
    display: block;
    padding-right: 0;
    width: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 50%;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding-right: 0;
    border-bottom: solid 1px #6d6d6d;
  }
  .l_sitemap_information_list .l_sitemap_information_list_item:nth-child(even) {
    border-left: solid 1px #6d6d6d;
  }
  .l_sitemap_information_list .l_sitemap_information_list_item:last-child:nth-child(odd) {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .l_sitemap_information_list .l_sitemap_information_list_item::after {
    content: '';
    display: block;
    height: 14px;
    width: 1px;
    background: #333;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (max-width: 640px) {
  .l_sitemap_information_list .l_sitemap_information_list_item::after {
    display: none;
  }
}

.l_sitemap_information_list .l_sitemap_information_list_item:last-child {
  padding-right: 0;
}

.l_sitemap_information_list .l_sitemap_information_list_item:last-child::after {
  display: none;
}

.l_sitemap_information_link {
  display: inline-block;
  position: relative;
  font-size: 13px;
  padding-left: 13px;
}

@media (max-width: 1024px) {
  .l_sitemap_information_link {
    padding-left: 0;
  }
}

@media (max-width: 640px) {
  .l_sitemap_information_link {
    text-align: left;
    display: flex;
    padding-left: 10px;
    padding-right: 30px;
    position: relative;
    height: 40px;
    align-items: center;
    font-size: 15px;
  }
}

@media (max-width: 375px) {
  .l_sitemap_information_link {
    font-size: 13px;
  }
}

.l_sitemap_information_link::before {
  content: '';
  display: block;
  width: 8px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  background: #333;
}

@media (max-width: 1024px) {
  .l_sitemap_information_link::before {
    display: none;
  }
}

@media (max-width: 640px) {
  .l_sitemap_information_link::before {
    display: block;
    width: 8px;
    height: 8px;
    background: none;
    border-top: solid 1px #333;
    border-right: solid 1px #333;
    transform-origin: right top;
    transform: rotate(45deg);
    left: auto;
    right: 10px;
  }
}

.l_sitemap_information_link::after {
  content: '';
  display: block;
  height: 1px;
  position: absolute;
  background: #dddddd;
  bottom: 0;
  left: 0;
  width: 0%;
  z-index: 1;
}

@media (min-width: 769px) {
  .l_sitemap_information_link::after {
    transition: width 0.3s ease;
  }
}

@media (min-width: 769px) {
  .l_sitemap_information_link:hover::after {
    width: 100%;
  }
}

.l_sitemap_logo {
  margin-top: 25px;
  align-self: center;
  width: 220px;
}

@media (max-width: 1024px) {
  .l_sitemap_logo {
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
  }
}

.l_sitemap_logo .l_sitemap_logo_item {
  display: flex;
}

@media (max-width: 880px) {
  .l_sitemap_logo .l_sitemap_logo_item {
    justify-content: center;
  }
}

.l_sitemap_logo .l_sitemap_logo_item + .l_sitemap_logo_item {
  margin-top: 15px;
}

.l_sitemap_8ing {
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  font-weight: bold;
}

.l_sitemap_8ing > ._logo {
  width: 70px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: block;
  margin-right: 10px;
}

.l_sitemap_8ing > ._logo .m_svg_object path, .l_sitemap_8ing > ._logo .m_svg_object rect, .l_sitemap_8ing > ._logo .m_svg_object g {
  fill: #333 !important;
}

.l_sitemap_8ing > ._logo > ._svg {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.l_sitemap_8ing > ._text {
  white-space: nowrap;
  padding-bottom: 5px;
  color: #333;
}

.l_sitemap_colopl {
  display: block;
  width: 100%;
}

.l_sitemap_colopl .m_svg_object {
  fill: #f00 !important;
}

.l_sitemap_colopl .m_svg_object path, .l_sitemap_colopl .m_svg_object rect, .l_sitemap_colopl .m_svg_object g {
  fill: #333 !important;
}

.l_copyright {
  font-size: 12px;
  text-align: center;
  padding: 20px 10px 30px;
}

@media (max-width: 640px) {
  .l_copyright {
    font-size: 11px;
  }
}

.l_sp_conversion {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: #fff;
}

@media (max-width: 640px) {
  .l_sp_conversion {
    display: block;
  }
}

.l_sp_conversion .l_sp_conversion_inner {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.l_sp_conversion .l_sp_conversion_item {
  width: calc(50% - 5px);
}

/*
CMSのページ毎のCSS記述ファイル
*/
.this__index .m_footer_front_only {
  display: block;
}

.this__8ing_project__index {
  background: #f2f2f2;
}
