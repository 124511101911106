// ================================================================================
//
// ▼ title.scss
//
//================================================================================
.m_title_h2 {
  color: #000;
  font-family: $font_family_en;
  font-weight: bold;
  font-size: 52px;
  line-height: 52px + 10px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-right: -0.05em;
  @media (max-width: 640px) {
    font-size: 46px;
    line-height: 46px + 10px;
  }
  @media (max-width: 480px) {
    font-size: 40px;
    line-height: 40px + 10px;
  }
  @media (max-width: 375px) {
    font-size: 36px;
    line-height: 36px + 10px;
  }
  &.is_half_up {
    position: relative;
    top: -30px;
    margin-bottom: -30px;
    @media (max-width: 640px) {
      top: -26px;
      margin-bottom: -26px;
    }
    @media (max-width: 480px) {
      top: -22px;
      margin-bottom: -22px;
    }
  }
}

.m_title_h2_sub {
  font-size: 20px;
  color: $color_orange;
  text-align: center;
  letter-spacing: 0.2em;
  margin-right: -0.2em;
  @media (max-width: 640px) {
    font-size: 18px;
  }
  @media (max-width: 480px) {
    font-size: 16px;
  }
}




.m_label_balloon {
  text-align: center;
  font-family: $font_family_en;
  color: #000;
  .m_label_balloon_inner {
    display: inline-block;
    background: $color_orange;
    padding-top: 3px;
    padding-bottom: 3px;
    position: relative;
    font-size: 24px;
    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 8px 0 8px;
      border-color: $color_orange transparent transparent transparent;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    > ._text {
      margin-right: -5px;
      margin-left: -5px;
      font-weight: bold;
    }
  }
}


.m_title_8ing {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: #000;
  @media (max-width: 640px) {
    font-size: 36px;
  }
  @media (max-width: 480px) {
    font-size: 32px;
  }
  @media (max-width: 375px) {
    font-size: 28px;
  }

  .m_title_8ing_en {
    font-family: $font_family_en;
  }

  ._large {
    font-size: 125%;
  }

}



.m_title_slash {
  text-align: center;
  font-size: 20px;
  @media (max-width: 640px) {
    font-size: 18px;
  }
  > ._text {
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    color: #80868b;
    &::before,
    &::after {
      content: '/';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
}




.m_about_contents {
  display: flex;
  @media (max-width: 640px) {
    display: block;
  }
  .m_about_contents_item {
    @include flex();
    + .m_about_contents_item {
      margin-left: 20px;
      @media (max-width: 640px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}









.m_title_band {
  width: 100%;
  ._inner {
    display: block;
    width: calc(((100% - 1000px) / 2) + 900px);
    padding-left: calc(((100% - 1000px) / 2));
    background: #383838;
    color: #fff;
    font-size: 36px;
    padding-top: 5px;
    padding-bottom: 5px;
    letter-spacing: 0.1em;
    @media (max-width: 1040px) {
      padding-left: 20px;
    }
    @media (max-width: 880px) {
      width: calc(100% - 40px);
    }
    @media (max-width: 640px) {
      padding-left: 15px;
      font-size: 32px;
      font-weight: normal;
    }
    @media (max-width: 480px) {
      font-size: 28px;
    }
    @media (max-width: 375px) {
      font-size: vw(24px, 375);
    }
  }
  &::first-letter {
    color: $color_orange;
  }

  &.is_font_small {
    > ._inner {
      font-size: 34px;
      @media (max-width: 640px) {
        font-size: 30px;
      }
      @media (max-width: 480px) {
        font-size: 26px;
      }
      @media (max-width: 375px) {
        font-size: vw(22px, 375);
      }
    }
  }
}



.m_title_step {
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    display: block;
  }
  .m_title_step_label {
    background: #feac29;
    display: block;
    color: #fff;
    font-weight: bold;
    border-radius: 20px;
    padding: 8px 20px;
    font-family: $font_family_en_open_sans;
    line-height: 1;
    margin-right: 10px;
    overflow: hidden;
    width: 140px;
    text-align: center;
    @media (max-width: 640px) {
      width: 120px;
      padding: 5px 20px;
    }
    @media (max-width: 480px) {
      margin-left: -10px;
    }
    > ._inner {
      display: block;
      font-size: 20px;
      letter-spacing: 0.1em;
      margin-right: -0.1em;
      @media (max-width: 640px) {
        font-size: 18px;
      }
    }
  }
  .m_title_step_title {
    @include flex();
    letter-spacing: 0.1em;
    font-size: 34px;
    @media (max-width: 640px) {
      font-size: 28px;
    }
    @media (max-width: 480px) {
      margin-top: 5px;
      font-size: 24px;
      display: block;
    }
  }
}


//
