// ------------------------------------------------------------
// - ▼ メインナビゲーション
// ------------------------------------------------------------
.l_navigation {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.6);
  position: fixed;
  z-index: 5000;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  // padding-bottom: 5%;
  display: none;
  body.VIEW_NAVIGATION & {
    display: block;
  }
  body.VIEW_NAVIGATION & {
    display: block;
    animation: fade_in 0.3s ease both;
  }

  body.NAVIGATION_CLOSE_ANIMATION & {
    animation: navigation_close 0.3s ease both;
  }
}

.l_navigation_scroller {
  height: 100%;
  overflow: hidden;
  position: relative;

  // perfect-scrollbarの設定
  .ps__rail-y {
    margin-top: 90px;
    background: #eee;
    opacity: 1 !important;
    width: 6px !important;
    @media (max-width: 640px) {
      margin-top: 70px;
    }
    @media (max-width: 375px) {
      margin-top: 60px;
    }
  }
  .ps__thumb-y {
    background: #999 !important;
    width: 6px !important;
    right: 0;
    opacity: 1 !important;
  }
}
.l_navigation_container {
  padding-top: 90px;
  padding-bottom: 60px;
  background: rgba(#222, 0.9);
  overflow: hidden;
}

.l_navigation_inner {
  max-width: 1000px;
  box-sizing: content-box;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 640px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

// ------------------------------------------------------------
// - ▼ ナビゲーション内のタイトル
// ------------------------------------------------------------
.l_navigation_title {
  font-size: 0;
  font-size: 42px;
  line-height: 1;
  @media (max-width: 1024px) {
    font-size: 36px;
  }
  @media (max-width: 880px) {
    font-size: 30px;
  }
  @media (max-width: 640px) {
    font-size: 26px;
  }
  @media (max-width: 440px) {
    font-size: vw(26px, 440);
  }
  > ._en {
    color: #fff;
    font-weight: bold;
    font-family: $font_family_en;
    letter-spacing: 0.05em;
    transition: color 0.3s ease;
  }
  > ._jp {
    color: $color_orange;
    font-size: 35.71%;
    font-weight: normal;
    margin-left: 10px;
    letter-spacing: 0.1em;
    transition: color 0.3s ease;
    @media (max-width: 440px) {
      margin-left: vw(20px, 440);
      font-size: 53.85%;
    }
  }
}


// ------------------------------------------------------------
// - ▼ メインコンテンツのナビゲーション
// ------------------------------------------------------------
.l_navigation_sp_btn {
  display: none;
  @media (max-width: 640px) {
    display: flex;
    justify-content: space-between;
  }
  .l_navigation_sp_btn_item {
    width: calc(50% - 5px);
  }
}


// ------------------------------------------------------------
// - ▼ メインコンテンツのナビゲーション
// ------------------------------------------------------------
.l_navigation_main {
  // background: orange;
  border-bottom: solid 1px #fff;
  margin-top: 40px;
}

// ------------------------------------------------------------
// - ▼ 2カラム部分のナビ
// ------------------------------------------------------------
%navigation_padding {
  padding: 30px 25px;
  @media (max-width: 480px) {
    padding: 20px 10px;
  }

}

.l_navigation_main_column_2 {
  border-top: solid 1px #fff;
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }

  .l_navigation_main_column_2_item {
    @extend %navigation_padding;
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
    &:nth-child(even) {
      border-left: solid 1px #fff;
      @media (max-width: 768px) {
        border-left: none;
        border-top: solid 1px #fff;
      }
    }
  }
}

.l_navigation_main_item {
  @extend %navigation_padding;
  border-top: solid 1px #fff;
}



// ------------------------------------------------------------
// - ▼ サブメニュー
// ------------------------------------------------------------
.l_navigation_sub_menu {
  overflow: hidden;
  margin-top: 20px;
  .l_navigation_sub_menu_inner {
    font-size: 0;
    margin-right: -55px;
    margin-top: -10px;
    @media (max-width: 480px) {
      margin-right: 0;
    }
  }
  .l_navigation_sub_menu_item {
    display: inline-block;
    font-size: 16px;
    margin-right: 55px;
    margin-top: 10px;
    @media (max-width: 480px) {
      margin-right: 0;
      display: block;
    }
  }
}


// ------------------------------------------------------------
// - ▼ サブメニューのリンク
// ------------------------------------------------------------
.l_navigation_main_link {
  padding-right: 20px;
  position: relative;
  position: relative;
  display: block;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    transform-origin: right top;
    position: absolute;
    top: 50%;
    right: 0;
    transform: rotate(45deg);
    transition: border-color 0.3s ease;
  }

  &:hover {
    @media (min-width: 769px) {
      background: rgba(lighten(#333, 5%), 0.9);
      box-shadow: 0 0 0 10px rgba(lighten(#333, 5%), 0.9);
      &::before {
        border-color: lighten($color_orange, 15%);
      }

      // タイトルも
      .l_navigation_title {
        > ._en {
          color: lighten($color_orange, 15%);
        }
        > ._jp {
          color: lighten($color_orange, 15%);
        }
      }
    }
  }
}

// ------------------------------------------------------------
// - ▼ サブメニューのリンク
// ------------------------------------------------------------
.l_navigation_sub_link {
  color: #fff;
  display: inline-block;
  padding-left: 16px;
  position: relative;
  letter-spacing: 0.2em;
  transition: color 0.3s ease;
  // font-family: $font_family_open_sans;
  // background: pink;
  @media (max-width: 480px) {
    font-size: 14px;
  }

  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    transform-origin: right top;
    position: absolute;
    top: 50%;
    left: 0;
    transform: rotate(45deg);
    transition: border-color 0.3s ease;
  }

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.3s ease, background-color 0.3s ease;
  }
  &:hover {
    @media (min-width: 769px) {
      color: lighten($color_orange, 15%);
      &::before {
        border-color: lighten($color_orange, 15%);
      }
      &::after {
        background-color: lighten($color_orange, 15%);
        width: 100%;
      }
    }
  }
}



.l_navigation_corporate_btn {
  margin-top: 50px;
  max-width: 340px;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}
